<template>
  <div class="box box-default" ref="box">
    <div class="box-header with-border">
      <h3 class="box-title" @click.self="toggleBox">
        {{ $t(labels.box_title) }}
        <Tooltip :title="$parent.$t(labels.box_tooltip)" />
      </h3>
      <div class="box-tools pull-right">
        <button
          type="button"
          class="btn btn-box-tool"
          id="data-order-minus-trigger"
          data-widget="collapse"
        >
          <i class="fa fa-minus"></i>
        </button>
      </div>
    </div>

    <div class="box-body">
      <div class="form-group">
        <h4 class="h4">
          {{ $t("ordernation") + ": " + currentOrder }}
        </h4>
        <p class="help-block" v-html="$t('ordination_help_text')"></p>
        <button
          class="btn btn-danger btn-sm"
          data-testid="redefine"
          :title="$parent.$t(labels.btn_redefine)"
          type="button"
          v-show="!confirming"
          :disabled="!custom_view.order && !(custom_view.excluded || []).length"
          @click="confirming = true"
        >
          {{ $t("undo_changes") }}
        </button>
        <div class="form-group" id="confirm-redefine">
          <label v-show="confirming">{{ $t("are_you_sure") }}</label>
          <button
            v-show="confirming"
            data-testid="yes"
            class="btn btn-danger btn-sm"
            @click.prevent="resetOrder"
          >
            {{ $t("yes") }}
          </button>
          <button
            v-show="confirming"
            data-testid="cancel"
            class="btn btn-sm"
            @click.prevent="confirming = false"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
      <SearchableTable
        ref="table"
        v-if="initialized"
        :searchEnabled="false"
        :items="dataList"
        :fields="dataFields"
        :commands="commands"
        :draggable="draggable"
        :persistent="false"
        @reorder="onReorder"
        @command="onCommand"
        :sortDef="custom_view.order_type == 'column' ? custom_view.order : {}"
        :sortOnMount="(custom_view || {}).order_type == 'manual' ? false : true"
      >
        <template #value="{item}">
          <DataValueDisplay
            :entry="item"
            :zeroAsDash="false"
            :title="item.current_value && item.current_value.value"
            class="column-value"
          />
        </template>
      </SearchableTable>
    </div>
    <Spin v-if="loading" />
  </div>
</template>

<script>
import DataValueDisplay from "@/components/data-value-display.vue";
import SearchableTable from "@/components/searchable-table.vue";
import Spin from "@/components/spin.vue";
import Tooltip from "@/components/tooltip.vue";
const getDataService = () => import("@/services/data");

export default {
  name: "DataOrderConfiguration",
  components: {
    SearchableTable,
    Spin,
    DataValueDisplay,
    Tooltip
  },
  props: {
    connectorId: {
      required: true,
      type: Number
    },
    value: {
      type: Object,
      required: false,
      default: () => ({})
    },
    labels: {
      type: Object,
      required: false,
      default: () => ({
        box_tooltip: "hints.custom_view",
        box_title: "customize_data_view",
        btn_redefine: "hints.redefine_custom_view"
      })
    },
    dataFilter: {
      type: Function,
      required: false,
      default: () => true
    },
    multiSelection: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      dataList: [],
      dataFields: [
        {name: "name", title: "name"},
        {name: "device_name", title: "device"},
        {
          name: "value",
          title: "value",
          parser: (item) => {
            return item?.current_value?.value ?? "";
          },
          style: {
            "max-width": "250px",
            "text-align": "right"
          }
        },
        {name: "unity_label", title: "unit"},
        {
          name: "date_time",
          title: "timestamp",
          parser: (item) => {
            return item?.current_value?.date_time
              ? new Date(item?.current_value?.date_time)
              : "";
          },
          format: (v, item) => {
            return item?.current_value?.date_time
              ? moment(item?.current_value?.date_time).format(
                  "DD/MM/YYYY HH:mm:ss"
                )
              : "-";
          }
        }
      ],
      custom_view: {
        order_type: null,
        order: null,
        excluded: [],
        checked: []
      },
      defaultList: [],
      loading: false,
      initialized: false,
      confirming: false,
      bulkState: {
        visible: null,
        checked: null
      }
    };
  },
  computed: {
    draggable() {
      return {
        options: {
          filter: ".data-hidden"
        },
        listeners: {}
      };
    },
    currentOrder() {
      let text = this.$t(this.custom_view.order_type || "not_defined");
      if (this.custom_view.order_type == "column")
        text += ` (${this.$tc(
          this.getFieldTitle(this.custom_view.order.column)
        )} | ${
          this.custom_view.order.asc
            ? this.$t("ascendent")
            : this.$t("descendent")
        })`;
      return text;
    },
    resetText() {
      return this.confirming ? "are_you_sure" : "redefine";
    },
    commands() {
      let items = [
        {
          name: "hide_show",
          title: "visible",
          icon(item) {
            return (
              "glyphicon glyphicon-eye-" +
              (item.class == "data-hidden" ? "close" : "open")
            );
          },
          bulk: true
        }
      ];
      if (this.multiSelection) {
        items.push({
          name: "check",
          title: "selected",
          icon(item) {
            return (
              "glyphicon glyphicon-" + (item.checked ? "check" : "unchecked")
            );
          },
          enabled(item) {
            return item.class != "data-hidden";
          },
          bulk: true
        });
      }
      return items;
    },
    equipmentDataList() {
      return (this.$store.getters["dashboard/dataList"] || []).filter(
        ({clp_id}) => parseInt(clp_id) == parseInt(this.connectorId)
      );
    }
  },
  watch: {
    equipmentDataList: {
      handler(n) {
        if (n && n.length && !this.initialized) {
          this.initialize();
        }
      },
      immediate: true
    },
    custom_view: {
      deep: true,
      handler(n) {
        if (
          n &&
          n?.order &&
          this.equipmentDataList?.length &&
          !this.initialized
        ) {
          this.initialize();
        }
        this.$emit("input", n);
      },
      immediate: true
    }
  },
  methods: {
    dataPath(data) {
      // return (
      //   data.reference_id ||
      //   ((data.device &&
      //     (data.device.data_collector_device_id
      //       ? data.device.reference_id
      //       : data.device.name)) ||
      //     "") +
      //     "." +
      //     data.name
      // );
      let prefix = data?.device?.data_collector_device_id
        ? `${data.device.reference_id}.`
        : "";
      return `${prefix}${data.reference_id}`;
    },
    onReorder(sortDef) {
      if (sortDef.column) {
        this.$set(this.custom_view, "order_type", "column");
        this.$set(this.custom_view, "order", sortDef);
      } else {
        this.$set(this.custom_view, "order_type", "manual");
        if (Array.isArray(sortDef)) {
          this.$set(
            this.custom_view,
            "order",
            sortDef.map((data) => this.dataPath(data))
          );
        }
      }
    },
    onCommand(command) {
      if (command.name == "hide_show") {
        this.toggleDataHide(command);
      } else if (command.name == "check") {
        this.checkItem(command);
      }
    },
    updChecked() {
      var seen = {};
      this.custom_view.checked = this.dataList
        .filter((data) => data.checked)
        .map((data) => this.dataPath(data))
        .filter((name) => {
          if (seen.hasOwnProperty(name)) {
            return false;
          } else {
            seen[name] = true;
            return true;
          }
        });
    },
    checkItem(command) {
      let dataList = this.dataList;
      if (command.bulk && this.bulkState.checked == null) {
        if (dataList.some((data) => data.checked)) {
          this.bulkState.checked = false;
        } else {
          this.bulkState.checked = true;
        }
      }
      for (var i in dataList) {
        if (command.bulk) {
          if (dataList[i].class != "data-hidden")
            dataList[i].checked = this.bulkState.checked;
        } else if (
          this.dataPath(dataList[i]) == this.dataPath(command.target)
        ) {
          dataList[i].checked = !dataList[i].checked;
        }
      }
      if (command.bulk) {
        this.bulkState.checked = !this.bulkState.checked;
      }
      this.updChecked();
    },
    toggleDataHide(command) {
      let dataList = this.dataList;
      if (command.bulk && this.bulkState.visible == null) {
        if (dataList.some((data) => data.class == "data-hidden")) {
          this.bulkState.visible = "";
        } else {
          this.bulkState.visible = "data-hidden";
        }
      }
      for (var i in dataList) {
        if (command.bulk) {
          dataList[i].class = this.bulkState.visible;
        } else if (
          this.dataPath(dataList[i]) == this.dataPath(command.target)
        ) {
          dataList[i].class =
            dataList[i].class == "data-hidden" ? "" : "data-hidden";
        }
        if (dataList[i].class == "data-hidden") {
          dataList[i].checked = false;
        }
      }
      if (command.bulk) {
        this.bulkState.visible = this.bulkState.visible ? "" : "data-hidden";
      }
      var seen = {};
      this.custom_view.excluded = dataList
        .filter((data) => data.class == "data-hidden")
        .map((data) => this.dataPath(data))
        .filter((name) => {
          if (seen.hasOwnProperty(name)) {
            return false;
          } else {
            seen[name] = true;
            return true;
          }
        });
      this.updChecked();
    },
    getFieldTitle(fname) {
      // get field title property
      const f = fname && this.dataFields.find(({name}) => name == fname);
      return (f && f.title) || fname;
    },
    toggleBox() {
      $(this.$refs.box).boxWidget("toggle");
    },
    initialize() {
      if (this.initialized) return;
      let result = JSON.parse(JSON.stringify(this.equipmentDataList));
      if (this.dataFilter) {
        result = result.filter(this.dataFilter);
      }

      // begin: make it compatible with previous version (devicename+dataname)
      let setReferenceId = (list) => {
        for (var i in list || []) {
          let data = result.find((item) => {
            return item.device.name + "." + item.name == list[i];
          });
          if (data) {
            list[i] = data.reference_id;
          }
        }
      };
      if (this.custom_view.order_type == "manual")
        setReferenceId(this.custom_view.order || []);
      setReferenceId(this.custom_view.excluded || []);
      setReferenceId(this.custom_view.checked || []);
      // end

      result = result.map((data) => {
        if (data.current_value) {
          data.value = data.current_value.value;
          data.timestamp = this.$dt.format(data.current_value.date_time);
        } else {
          data.value = "-";
          data.timestamp = "-";
        }
        data.device_name = data.device.name; // move device name property up in the object
        data.class = "";
        if ((this.custom_view.excluded || []).includes(this.dataPath(data))) {
          data.class = "data-hidden";
        }
        data.checked = (this.custom_view.checked || []).includes(
          this.dataPath(data)
        );
        return data;
      });
      this.defaultList = [].concat(result);

      if (this.custom_view.order_type == "manual")
        result = result.sort(
          (a, b) =>
            this.custom_view.order.indexOf(this.dataPath(a)) -
            this.custom_view.order.indexOf(this.dataPath(b))
        );
      this.$set(this, "dataList", result);
      this.loading = false;
      this.initialized = true;
    },
    resetOrder() {
      this.custom_view.order = null;
      this.custom_view.order_type = null;
      (this.custom_view.excluded || []).forEach(
        (dataName) =>
          (this.dataList.find((data) => this.dataPath(data) == dataName).class =
            "")
      );
      this.custom_view.excluded = [];
      this.confirming = false;
    }
  },
  mounted() {
    if (this.value) this.custom_view = this.value;
    $(this.$refs.box)
      .boxWidget({
        animationSpeed: 300,
        collapseIcon: "fa-minus",
        expandIcon: "fa-plus"
      })
      .boxWidget("collapse");
  }
};
</script>

<style scoped>
.box-header {
  cursor: pointer;
}

#confirm-redefine *:not(:last-child) {
  margin-right: 1rem;
}
.searchable-table::v-deep {
  overflow-x: auto;
}
.column-value {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<style>
tr.data-hidden {
  color: #777;
  text-decoration: line-through;
}

tr.data-hidden .drag-handle {
  cursor: not-allowed;
}

tr.data-hidden .drag-handle:active {
  cursor: not-allowed;
}
</style>
