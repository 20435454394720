<template>
  <section>
    <TogglePanel
      :title="`${$tc('titles.data_serie', 1)}`"
      :hint="`${$tc('hints.data_serie', 1)}`"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
      :collapsed="true"
    >
      <slot name="before"></slot>
      <DatasetSourceSelector
        ref="dss"
        @apply="applyNamedQuery"
        v-if="showDataSetSourceSelection"
      />
      <div
        class="title data-selection-box"
        v-if="!($refs.dss && $refs.dss.namedQueryEnabled)"
      >
        <div v-if="dataSelector">
          <ControlDataSelector
            v-model="dataId"
            :parser="dataListParser"
            :connectorId="connectorId"
            @connectorChanged="connectorId = $event"
            :allowedTypes="['bool', 'float', 'int', 'string']"
            label="data_selector"
          >
            <template #extra_label>
              <div
                v-if="dataSelector"
                class="clicable data-toggle"
                @click.stop.prevent="dataSelector = !dataSelector"
              >
                <i class="fa fa-caret-square-o-up" v-if="dataSelector"></i>
                <i class="fa fa-caret-square-o-down" v-else></i>
              </div>
            </template>
          </ControlDataSelector>
          <div class="text-center" style="margin-bottom: 10px">
            <span
              class="btn btn-xs btn-primary"
              :class="dataId ? '' : 'disabled'"
              @click.stop.prevent="onAddData"
              style="margin: 0 10px 0 0"
            >
              {{ $tc("add_serie", 1) }}
            </span>
            <span class="btn btn-xs btn-default" @click.stop.prevent="onImport">
              {{ $t("titles.add_all_data") }}
            </span>
          </div>
        </div>
        <div class="text-center" v-else>
          <div
            class="btn btn-xs btn-primary"
            @click.stop.prevent="dataSelector = true"
          >
            {{ $t("add_serie") }}
          </div>
        </div>
      </div>
      <div v-if="nDataList > 0" class="small" style="margin-top: 15px">
        <div style="display:flex;justify-content:space-between">
          <div class="text-left">{{ $t("dataset") }}</div>
          <div class="text-right">
            <span
              v-if="!hasOpenedSeries && !namedQuery"
              class="btn btn-xs"
              style="margin-right: 4px"
              @click.stop.prevent="removeAllData"
              :title="$tc('hints.remove_all', 2)"
            >
              <i class="fa fa-trash"></i>
            </span>
            <span
              v-if="!namedQuery"
              class="btn btn-xs"
              @click.stop.prevent="nextOrder"
              :title="$t(order.list[order.selected].title)"
            >
              <i :class="order.list[order.selected].icon"></i>
            </span>
          </div>
        </div>
      </div>
      <draggable
        :key="cid"
        v-model="dataList"
        class="item"
        handle=".handle"
        :disabled="hasOpenedSeries || namedQuery"
      >
        <template>
          <template v-for="(item, ix) in dataList">
            <ChartSerieForm
              ref="chartSerieForm"
              :value="item"
              :index="ix"
              :key="ix"
              :equipmentData="getEquipmentDataAt(ix)"
              :changeType="changeType"
              :checkable="checkable"
              :types="types"
              :symbol="symbol"
              :showApplyExpresson="showApplyExpresson"
              :showInterpolation="showInterpolation"
              :namedQuery="namedQuery"
              :yAxis="yAxisList"
              @removeData="onRemoveData"
              @changeType="onChangeType(ix, $event)"
              @replaceData="onReplaceData(ix, $event)"
              @cloneData="onCloneData"
              @check="onItemCheck(ix, $event)"
              @enable="onItemEnable(ix, $event)"
              @open="setSerieAsOpen(ix, true)"
              @close="setSerieAsOpen(ix, false)"
              @input="updateDataAt(ix, $event)"
              class="handle"
            />
          </template>
        </template>
      </draggable>
      <slot name="after"></slot>
      <div
        style="margin: 0px 0 10px 0; border-top: 1px solid lightgray"
        v-if="nDataList > 0"
      ></div>
    </TogglePanel>
    <!-- begin axis configuration -->
    <div v-if="!requireDataList || (nDataList && !pieOnly)">
      <slot name="before_axis"></slot>
      <TogglePanel
        :title="`${$tc('titles.x_axis', 1)}`"
        :hint="`${$tc('hints.x_axis', 1)}`"
        :icon="{
          collapse: 'fa-caret-square-o-up',
          expand: 'fa-caret-square-o-down'
        }"
        :collapsed="true"
      >
        <ChartAxisXForm
          v-model="xAxisConfig"
          propName="xAxle"
          :namedQuery="namedQuery"
          :series="dataList"
          :allowTypeSelection="showXAxisTypeSelection"
        ></ChartAxisXForm>
      </TogglePanel>
      <TogglePanel
        :title="$tc('titles.y_axis', 1)"
        :hint="$tc('hints.y_axis', 1)"
        :icon="{
          collapse: 'fa-caret-square-o-up',
          expand: 'fa-caret-square-o-down'
        }"
        :collapsed="true"
        style="margin-bottom: 12px"
      >
        <ChartAxisYListForm v-model="yAxisConfig" />
      </TogglePanel>
      <slot name="after_axis"></slot>
    </div>
    <!-- end axis configuration -->
  </section>
</template>

<script>
import isEqual from "lodash/isEqual";
import draggable from "vuedraggable";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import ChartSerieForm from "@/components/control-sidebar/property-editors/chart-serie-form.vue";
import Chart from "@/assets/dashboard/chart.json";
import DatasetSourceSelector from "@/components/control-sidebar/property-editors/dataset-source-selector.vue";
import ChartAxisXForm from "@/components/control-sidebar/property-editors/chart-axis-x-form.vue";
import ChartAxisYListForm from "@/components/control-sidebar/property-editors/chart-axis-y-list-form.vue";
import {resetScale} from "@/components/control-sidebar/property-editors/chart-axis-scale-form.vue";
import messages from "@/i18n/chart.js";
const defSerie = (type) => {
  return JSON.parse(JSON.stringify(Chart.seriesOptions[type || "line"]));
};

export {defSerie};

const dftCustomLabelFont = () => ({
  fontFamily: "Source Sans Pro",
  fontSize: "84%",
  fontStyle: "normal",
  fontWeight: "normal"
});

const dftCustomXAxisLabel = (namedQuery) => ({
  customFont: false,
  show: true,
  color: "#333",
  formatter: namedQuery ? "$value" : 'moment($value).format("DD/MM/YY HH:mm")',
  inside: false,
  rotation: 0,
  margin: 8,
  ...dftCustomLabelFont()
});

export default {
  name: "ChartSeriesForm",
  i18n: {messages},
  components: {
    TogglePanel,
    ControlDataSelector,
    ChartSerieForm,
    draggable,
    DatasetSourceSelector,
    ChartAxisXForm,
    ChartAxisYListForm
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    checkable: {
      type: Boolean,
      required: false,
      default: true
    },
    addAll: {
      type: Boolean,
      required: false,
      default: true
    },
    dataListParser: {
      type: Function,
      required: false,
      default: (lst) => lst
    },
    types: {
      type: Array,
      required: false,
      default: () => ["line", "bar", "pie", "scatter"]
    },
    listName: {
      type: String,
      default: "data",
      required: false
    },
    symbol: {
      type: Boolean,
      required: false,
      default: true
    },
    changeType: {
      type: Boolean,
      required: false,
      default: true
    },
    showApplyExpresson: {
      type: Boolean,
      requried: false
    },
    showInterpolation: {
      type: Boolean,
      required: false,
      default: true
    },
    showDataSetSourceSelection: {
      type: Boolean,
      required: false,
      default: true
    },
    showXAxisTypeSelection: {
      type: Boolean,
      required: false,
      default: true
    },
    requireDataList: {
      type: Boolean,
      required: false,
      default: true
    },
    omit: {
      type: Array,
      required: false,
      default: () => [
        "grid",
        "toolbox",
        "dataZoom",
        "legend",
        "title",
        "tooltip"
      ]
    }
  },
  data() {
    return {
      cid: 0,
      curConnectorId: undefined,
      curDataId: undefined,
      panelOptions: null,
      dataSelector: false,
      opened: {},
      collapseExpression: false,
      released: true,
      order: {
        selected: 0,
        original: [],
        list: [
          {id: 0, title: "default", icon: "fa fa-sort"},
          {id: 1, title: "descendent", icon: "fa fa-sort-alpha-desc"},
          {id: 2, title: "ascendent", icon: "fa fa-sort-alpha-asc"}
        ]
      }
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    template() {
      return this?.draft?.template || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    dataList: {
      set(value) {
        this.$set(this.panelOptions, this.listName, structuredClone(value));
        this.cid++;
      },
      get() {
        return (this?.panelOptions || {})[this.listName] || [];
      }
    },
    dataIds() {
      return this.dataList.map(({data_id}) => data_id);
    },
    chartOptions() {
      return this.panelOptions?.chartOptions || null;
    },
    dataId: {
      set(vlr) {
        let digits = (vlr + "").match(/\d+/g);
        if (digits) {
          vlr = parseInt(digits.join(""));
        } else {
          vlr = "";
        }
        this.curDataId = vlr || "";
      },
      get() {
        return this.curDataId || "";
      }
    },
    connectorId: {
      set(value) {
        this.curConnectorId = value;
      },
      get() {
        return this.curConnectorId || this.referenceConnectorId || undefined;
      }
    },
    referenceConnectorId() {
      return (
        (this.$store.getters["dashboard/screenRefMap"](this.screenId) || {})
          ?.conn1 || 0
      );
    },
    errors() {
      let entry = {
        cellStyle: "" // TODO: implement error parser
      };
      return entry;
    },
    equipmentDataList() {
      return (this.$store.getters["dashboard/dataList"] || []).filter(
        (data) => data?.clp_id == this.connectorId
      );
    },
    hasOpenedSeries() {
      for (var i in this.opened) {
        if (this.opened[i]) return true;
      }
      return false;
    },
    namedQuery() {
      return this?.panelOptions?.namedQuery || "";
    },
    namedQueryEnabled() {
      return !this.$refs.dss || this.$refs.dss.namedQueryEnabled;
    },
    nDataList() {
      return this?.dataList?.length || 0;
    },
    pieOnly() {
      return (
        this.nDataList &&
        (this.dataList || []).every(
          ({chartOptions}) => chartOptions.type == "pie"
        )
      );
    },
    xAxisConfig: {
      set(value) {
        if (!value) return;
        this.$set(this.panelOptions.chartOptions, "customXAxis", value.custom);
        if (
          !value?.data ||
          isEqual(this?.panelOptions?.chartOptions?.xAxis, value.data)
        )
          return;
        this.$set(
          this.panelOptions.chartOptions,
          "xAxis",
          structuredClone(value.data)
        );
      },
      get() {
        return {
          propName: "xAxis",
          custom: this?.panelOptions?.chartOptions?.customXAxis,
          data: this?.panelOptions?.chartOptions?.xAxis || null
        };
      }
    },
    yAxisConfig: {
      set(value) {
        if (!value) return;
        this.$set(
          this.panelOptions.chartOptions,
          "customYAxis",
          value.customYAxis
        );

        if (
          !value?.yAxis ||
          isEqual(this?.panelOptions?.chartOptions?.yAxis, value.yAxis)
        )
          return;
        this.$set(
          this.panelOptions.chartOptions,
          "yAxis",
          structuredClone(value.yAxis)
        );
      },
      get() {
        return {
          customYAxis: this?.panelOptions?.chartOptions?.customYAxis ?? false,
          yAxis: this?.panelOptions?.chartOptions?.yAxis || null
        };
      }
    },
    yAxisList() {
      const axis = this?.chartOptions?.yAxis || [];
      return Array.isArray(axis) ? axis : [axis];
    }
  },
  watch: {
    panelOptions: {
      deep: true,
      handler(n, o) {
        if (!n || !o) return;
        this.$emit("input", n);
      }
    }
  },
  methods: {
    addData(dataId) {
      if (!dataId) return;
      let dataList = [...this.dataList];
      // TODO: it seems that data selector is not adding to the global data list after connector changes
      let data = (this.equipmentDataList || []).find((i) => i.id == dataId);
      let chartOptions = defSerie("line");
      chartOptions.name = (data && data.name) || "";
      chartOptions.identity_embedded_app =
        (data && data.identity_embedded_app) || "";
      if (data?.portal_data?.wave_form) {
        chartOptions.lineStyle.waveForm =
          data?.portal_data?.wave_form || "triangle";
      } else if (
        (data?.memory_type?.name || "").match(/(bool|coil)/gi) != null
      ) {
        chartOptions.lineStyle.waveForm = "square";
      }
      if (data?.portal_data?.color) {
        chartOptions.itemStyle.color =
          data?.portal_data?.color || Chart.colors[dataList.length || 0];
      } else {
        chartOptions.itemStyle.color = Chart.colors[dataList.length || 0];
      }
      const format = this.$root.$formatter.dataFormat(data);
      if (format.format_mask !== "text_list") {
        chartOptions.label = dftCustomXAxisLabel();
        chartOptions.label.show = false;
        chartOptions.label.formatter = this.$root.$formatter.dataFormatPattern(
          data
        );
      }
      let entry = {
        id: this.$utils.uuid(),
        data_id: dataId,
        chartOptions: chartOptions,
        checked: true,
        enabled: true
      };

      dataList.push(entry);
      this.dataList = dataList;
      return this.dataList[this.dataList.length - 1];
    },
    onAddData() {
      let dataSerie = this.addData(this.curDataId);
      this.addStandardCurve(dataSerie);
      if (this.curDataId && !this.hasSimulatedSamples(this.curDataId)) {
        this.$store.dispatch("history/simulate", this.curDataId);
      }
    },
    addStandardCurve(baseSerie) {
      let data = (this.equipmentDataList || []).find(
        ({id}) => parseInt(id) == parseInt(baseSerie.data_id)
      );
      // It adds a standard curve (just once)
      if (
        !(data?.portal_data?.standard_curve ?? []).length ||
        this.dataList.some(
          ({data_id, chartOptions}) =>
            parseInt(data_id) == parseInt(baseSerie.data_id) &&
            chartOptions?.datasetSource == "standard_curve"
        )
      )
        return;
      let dataSerie = this.addData(data.id);
      dataSerie.chartOptions.name += " *";
      dataSerie.chartOptions.datasetSource = "standard_curve";
      dataSerie.chartOptions.itemStyle = dataSerie.chartOptions.itemStyle || {};
      dataSerie.chartOptions.itemStyle.enabled = false;
      dataSerie.enabled = false;
    },
    onImport() {
      this.dataListParser(this.equipmentDataList).forEach(({id}) => {
        this.addData(id);
      });
    },
    onRemoveData(ix) {
      if (this.namedQuery) return;
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        if (this.dataList.length == 1) {
          this.$store.dispatch("history/reset");
        } else {
          this.$store.dispatch("history/resetData", this.dataList[ix].data_id);
        }
        if (
          this?.panelOptions?.chartOptions?.xAxis?.dataSerieId &&
          this.dataList[ix]?.id ==
            this?.panelOptions?.chartOptions?.xAxis?.dataSerieId
        ) {
          this.$delete(this.panelOptions.chartOptions.xAxis, "dataSerieId");
        }
        this.dataList.splice(ix, 1);
        this.cid++;
      }
    },
    onChangeType(ix, type) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        let o = this.dataList[ix].chartOptions;
        let n = defSerie(type);
        if (o.type == n.type) return;
        n.name = o.name ?? n.name;
        let nPosition = n?.label?.position;
        n.label = o.label ?? n.label;
        if (n.label) {
          n.label.position = nPosition ?? n.label.position;
        }
        n.identity_embedded_app =
          o.identity_embedded_app ?? n.identity_embedded_app;
        n.itemStyle.color = o.itemStyle.color ?? n.itemStyle.color;
        n.itemStyle.expression =
          o.itemStyle.expression ?? n.itemStyle.expression;
        let dataList = [...this.dataList];
        dataList[ix].chartOptions = n;
        if (n.type == "pie" && this.namedQuery) {
          let lst = dataList
            .filter(({chartOptions}) => chartOptions.type == "pie")
            .map(({chartOptions}) => chartOptions);
          let radius = Math.floor(
            Number(this.$utils.onlyNumbers(n.radius)) * (1 / lst.length)
          );
          let x = 100 / (lst.length + 1);
          lst.forEach((cfg) => {
            cfg.radius = `${radius}%`;
            cfg.center[0] = `${Math.floor(x)}%`;
            x = x + 100 / (lst.length + 1);
          });
        }
        this.dataList = dataList;
      }
    },
    onItemCheck(ix, value) {
      let dataList = this.dataList || [];
      if (ix >= 0 && ix <= dataList.length - 1) {
        dataList[ix].checked = value;
        this.dataList = dataList;
      }
    },
    onItemEnable(ix, value) {
      let dataList = this.dataList || [];
      if (ix >= 0 && ix <= (dataList || []).length - 1) {
        dataList[ix].chartOptions.itemStyle.enabled = value;
        dataList[ix].enabled = value;
        this.dataList = dataList;
      }
    },
    getEquipmentDataAt(ix) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        let id = this.dataList[ix].data_id;
        return (
          (this.$store.getters["dashboard/dataList"] || []).find(
            (data) => data.id == id
          ) || null
        );
      }
      return null;
    },
    setSerieAsOpen(ix, value) {
      this.$set(this.opened, ix, value);
      if (value) {
        this.activeItem = ix;
        (this.$refs.chartSerieForm || []).forEach((item) => {
          if (item.index != this.activeItem && !item.collapsed) {
            item.toggle();
          }
        });
      }
    },
    hasSimulatedSamples(dataId) {
      let entries = this.$store.getters["history/entries"] || {};
      return ((entries[dataId] || {})?.samples || []).length > 0;
    },
    resetXAxis() {
      if (this?.panelOptions?.chartOptions?.xAxis) {
        let xAxis = structuredClone(
          this?.panelOptions?.chartOptions?.xAxis || {}
        );
        resetScale(xAxis, ["min", "max", "interval", "dataSerieId", "offset"]);
        xAxis.type = this.panelOptions.namedQuery ? "category" : "time";
        this.$set(this.panelOptions.chartOptions, "xAxis", xAxis);
      }
    },
    applyNamedQuery(entry) {
      const prvName = this.panelOptions.namedQuery || "";
      this.$nextTick(() => {
        this.dataList = [];
        this.customXAxis = false;
        this.$set(this.panelOptions, "namedQuery", entry?.name || "");
        if (entry?.dataList?.length) {
          (entry.dataList || []).forEach(
            ({data_id, columnName, columnFormat}) => {
              let dataSerie = this.addData(data_id);
              if (dataSerie?.chartOptions?.name) {
                if (columnName) dataSerie.chartOptions.name = columnName;
                if (columnFormat) {
                  let label = dftCustomXAxisLabel(entry.name);
                  label.show = false;
                  label.formatter = columnFormat;
                  this.$set(dataSerie.chartOptions, "label", label);
                }
                this.addStandardCurve(dataSerie);
              }
            }
          );
        }
        if (prvName != this.panelOptions.namedQuery) this.resetXAxis();
      });
    },
    init() {
      if (!this.value) return;
      let panelOptions = structuredClone(this.value);
      // only options that are changed locally will be considered to avoid conflicts
      (this.omit || []).forEach(
        (p) => delete (panelOptions?.chartOptions || {})[p]
      );
      let missing = [];
      ((panelOptions || {})[this.listName] || []).forEach((item, ix) => {
        if (!item.id) item.id = this.$utils.uuid(); // backward compatibility
        if (!item.chartOptions && item.data_id) {
          missing.push({id: item.data_id, ix: ix});
        }
      });
      if (missing.length) {
        this.panelOptions = panelOptions;
        missing.reverse().forEach((i) => {
          this.onRemoveData(i.ix);
        });
        missing.forEach((i) => {
          this.addData(i.id);
        });
        return;
      }

      this.$set(this, "panelOptions", panelOptions);
    },
    updateDataAt(ix, value) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        this.$set(this.dataList, ix, value);
      }
    },
    onReplaceData(ix, dataId) {
      if (ix < 0 || ix > this.dataList.length - 1) return;
      // TODO: it seems that data selector is not adding to the global data list after connector changes
      let dataList = this.$store.getters["dashboard/dataList"]; // !important - do not use connector data list, since previous might have been deleted
      let data = (dataList || []).find(
        (i) => parseInt(i.id) == parseInt(dataId)
      );
      let item = this.dataList[ix];
      if (!data || !item.chartOptions) return;
      item.data_id = dataId;
      item.chartOptions.name = data.name || "";
      item.chartOptions.identity_embedded_app =
        data.identity_embedded_app || "";
      const format = this.$root.$formatter.dataFormat(data);
      if (item.chartOptions.label && format.format_mask !== "text_list") {
        item.chartOptions.label.formatter = this.$root.$formatter.dataFormatPattern(
          data
        );
      }
      this.$store.dispatch("history/reset");
      this.cid++;
    },
    onCloneData(ix) {
      if (ix < 0 || ix > this.dataList.length - 1) return;
      let entry = structuredClone(this.dataList[ix]);
      entry.chartOptions.name = `${entry.chartOptions.name} (copy)`;
      this.dataList.push(entry);
      this.dataList = this.dataList;
      this.cid++;
    },
    nextOrder() {
      if (this.order.selected == this.order.list.length - 1) {
        this.order.selected = 0;
      } else {
        this.order.selected++;
      }
      let idList = this.dataList.map(({id}) => id);
      if (
        !this.order.original.length ||
        this.order.original.length != idList.length ||
        !isEqual([...idList].sort(), [...this.order.original].sort())
      ) {
        this.order.original = idList;
      }
      if (this.order.selected > 0) {
        this.dataList.sort((a, b) => {
          return a.chartOptions.name > b.chartOptions.name
            ? this.order.selected == 1
              ? -1
              : 1
            : a.chartOptions.name < b.chartOptions.name
            ? this.order.selected == 1
              ? 1
              : -1
            : 0;
        });
      } else {
        this.dataList = (this.order.original || []).map((_id) =>
          this.dataList.find(({id}) => _id == id)
        );
      }
      this.cid++;
    },
    removeAllData() {
      this.$store.dispatch("history/reset");
      if (this?.panelOptions?.chartOptions?.xAxis?.dataSerieId) {
        this.$delete(this.panelOptions.chartOptions.xAxis, "dataSerieId");
      }
      this.dataList = [];
      this.cid++;
    }
  },
  created() {
    this.init();
  }
};
</script>

<style scoped>
label {
  margin-bottom: 0;
}

.tab-content {
  background: white;
}

.inner-panel {
  padding-top: 10px;
}

.inner-panel > * {
  margin-bottom: 10px;
}

.clicable:hover {
  background-color: transparent;
  cursor: pointer;
  opacity: 0.8;
}

.data-selection-box {
  position: relative;
  /* background-color: whitesmoke; */
  padding: 5px;
  margin: 5px -5px 5px -5px;
}

.title {
  font-size: 10pt;
}

.data-toggle-link {
  padding: 2px;
}

.data-toggle {
  float: right;
}

.content-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-field {
  position: relative;
  display: inline-block;
}

.inline-field > input {
  z-index: 0;
  background-color: transparent;
  text-align: center;
  padding: 8px 0 0 0;
}

.inline-field > label {
  position: absolute;
  top: -2px;
  left: 3px;
  z-index: 1;
  font-size: 8pt;
  font-weight: normal;
  margin: 0;
  color: #777;
}

.custom-format-form {
  margin: 0 0 15px 0;
}

.expression-box {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 2px 6px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
