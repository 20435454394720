<template>
  <div
    :class="['float-panel', {active: open}, locked ? 'locked' : '']"
    :style="[currentStyle, draggingStyle]"
  >
    <slot></slot>
    <div class="float-panel-header">
      <i
        :class="
          locked ? 'locker fa fa-lock text-primary' : 'locker fa fa-unlock'
        "
        @click.stop.prevent="lockerToggle"
      >
      </i>
      <span
        class="title"
        v-html="title ? $tc(title, 1) : ''"
        @click.stop.prevent="$emit('title:click')"
      >
      </span>
      <span @click.stop.prevent="$emit('update:open', false)" class="btn-close">
        <span>&times;</span>
      </span>
    </div>
  </div>
</template>

<script>
import {whichTransitionEvent, clampDownPosition} from "@/utils";

export default {
  name: "FloatPanel",
  props: {
    open: {
      type: Boolean,
      required: true
    },
    defaultPosition: {
      type: Object,
      required: false,
      default: () => ({})
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false
    },
    handle: {
      type: String,
      required: false,
      default: ""
    },
    handleSelf: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      dragAndDropStyle: {},
      dragging: false,
      initialAbsPos: {},
      currentPosition: null,
      locked: false
    };
  },
  computed: {
    baseStyle() {
      return {
        ...this.defaultPosition
      };
    },
    currentStyle() {
      return this.currentPosition
        ? this.dragging
          ? this.dragAndDropStyle
          : this.currentPosition
        : this.baseStyle;
    },
    draggingStyle() {
      return this.dragging
        ? {cursor: "grabbing", "user-select": "none"}
        : {transition: "100ms"};
    }
  },
  watch: {
    open(newVal, oldVal) {
      if (!oldVal && newVal) {
        this._event = "open";
      } else if (oldVal && !newVal) {
        this._event = "close";
      }
    },
    defaultPosition: {
      deep: true,
      handler(val) {
        // overrides current position (if dragged)
        // when defaultPosition updates
        if (!this.currentPosition) return;

        if (val?.top != undefined)
          this.currentPosition.top = this.dragAndDropStyle.top = val.top;
        if (val?.right != undefined)
          this.currentPosition.right = this.dragAndDropStyle.right = val.right;
        if (val?.bottom != undefined)
          this.currentPosition.bottom = this.dragAndDropStyle.bottom =
            val.bottom;
        if (val?.left != undefined)
          this.currentPosition.left = this.dragAndDropStyle.left = val.left;
      }
    }
  },
  methods: {
    setDragEvents(on = true) {
      let methodName = on ? "addEventListener" : "removeEventListener";
      this.$el[methodName]("mousedown", this.onMousedown, true);
      document.documentElement[methodName]("mouseup", this.onMouseup, true);
      document.documentElement[methodName]("mousemove", this.onMousemove, true);
    },
    emitOpenCloseEvent(e) {
      if (this._event) {
        this.$emit(this._event);
        this.$emit("toggle", this._event == "open" ? true : false);
        if (this._event == "open") {
          this.$emit(
            "update:defaultPosition",
            clampDownPosition(this.$el, this.defaultPosition)
          );
        }
        this._event = null;
      }
    },
    onMousedown(e) {
      if (
        (this.handle &&
          (e.target.matches?.(this.handle) ||
            e.target.matchesSelector?.(this.handle))) ||
        (this.handleSelf && e.target == this.$el)
      ) {
        // if (this.locked) {
        //   this.lockerToggle();
        //   e.stopPropagation();
        //   e.preventDefault();
        //   return;
        // }
        this.dragging = true;
        this.initialAbsPos = {
          x: e.clientX,
          y: e.clientY
        };
        if (!this.currentPosition) {
          this.currentPosition = {
            top: 0,
            left: 0,
            ...this.defaultPosition
          };
          this.dragAndDropStyle = {...this.defaultPosition};
        }
        this.$emit("dragstart", {...this.initialAbsPos});
      }
    },
    onMouseup(e) {
      if (this.dragging) {
        this.dragging = false;
        this.dragAndDropStyle = clampDownPosition(this.$el, {
          ...this.dragAndDropStyle
        });
        this.currentPosition = {...this.dragAndDropStyle};
        this.$emit("update:defaultPosition", {...this.currentPosition});
        this.$emit("dragend", {...this.currentPosition});
      }
    },
    onMousemove(e) {
      if (this.dragging) {
        let offsetY = e.clientY - this.initialAbsPos.y;
        let offsetX = e.clientX - this.initialAbsPos.x;

        if (this.currentPosition.bottom != undefined) {
          this.dragAndDropStyle.bottom =
            parseInt(this.currentPosition.bottom) - offsetY + "px";
        } else {
          this.dragAndDropStyle.top =
            parseInt(this.currentPosition.top) + offsetY + "px";
        }

        if (this.currentPosition.right != undefined) {
          this.dragAndDropStyle.right =
            parseInt(this.currentPosition.right) - offsetX + "px";
        } else {
          this.dragAndDropStyle.left =
            parseInt(this.currentPosition.left) + offsetX + "px";
        }
        this.$emit("drag", {...this.dragAndDropStyle});
      }
    },
    lockerToggle() {
      let r = this.$el.getBoundingClientRect();
      this.currentPosition = this.currentPosition || {};
      if (this.locked && this._prv) {
        this.currentPosition.top = `${r.top + window.scrollY}px`;
        this.currentPosition.left = `${r.left + window.scrollX}px`;
      } else {
        this.currentPosition.top = `${r.top}px`;
        this.currentPosition.left = `${r.left}px`;
        this._prv = {
          top: r.top,
          left: r.left
        };
      }
      this.dragAndDropStyle = {...this.currentPosition};
      this.locked = !this.locked;
      // if (window.scrollY && !this.locked) {
      //   window.scrollTo(0, 0);
      // }
    }
  },
  mounted() {
    if (this.draggable && (this.handle || this.handleSelf)) {
      this.setDragEvents();
    }
    this.$el.addEventListener(
      whichTransitionEvent(),
      this.emitOpenCloseEvent.bind(this)
    );
  },
  beforeDestroy() {
    this.setDragEvents(false);
  }
};
</script>

<style lang="scss" scoped>
.float-panel {
  padding: 25px 0 0 0;
  overflow: hidden;
  position: absolute;
  box-shadow: 0 0 6px 2px rgba(165, 165, 165, 0.733);
  background: white;
  z-index: 1100;
  border-radius: 5px;
  transform: scale(0);
  transform-origin: bottom right;
  &.locked {
    position: fixed;
  }

  &.active {
    transform: scale(1);
  }

  .float-panel-header {
    position: absolute;
    width: 100%;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14pt;
    font-weight: 400;
    padding: 0px 5px;
    color: #666;
    text-shadow: 0px 1px lightslategrey;
    z-index: 1101;
    pointer-events: none;
    .title {
      pointer-events: all;
      cursor: pointer;
    }
    .locker {
      pointer-events: all;
      cursor: pointer;
      width: 22px;
      margin-right: 5px;
    }

    .btn-close {
      pointer-events: all;
      cursor: pointer;
      top: 0px;
      right: 6px;
      position: absolute;
    }
  }
}
.float-panel:hover {
  cursor: move;
}

.skin-dark {
  .float-panel {
    color: #b8c7ce;
    background-color: #2c3b41;
    box-shadow: 0 0 6px 2px #171b1d;
  }
  .float-panel-header {
    color: #b8c7ce;
    text-shadow: none;
    background-color: #222d32;

    & .btn-close span:hover {
      color: #fff;
    }
  }
}
</style>
