<template>
  <div
    v-show="ready"
    class="synoptic-wrapper"
    :class="{'text-center': fitToPage}"
    ref="wrapper"
  >
    <div
      ref="synoptic"
      class="synoptic noselect screenshot"
      :style="style"
      @click.self="onCanvasClick($event, false)"
      @dblclick.self="onCanvasClick($event, true)"
      @drop="handleDrop($event)"
      @contextmenu.stop="openControlMenu"
    >
      <template v-if="show">
        <SynopticControl
          v-for="(item, ix) in currentControls"
          :key="ix"
          :control="item"
          :equipment="equipment"
          :mode="mode"
          :zoom="zoom"
          :isPanelEditing="isEditing"
          :isPanelLocked="isPanelLocked"
          :isBackgroundCtrl="item.id === backgroundCtrlId"
        />
      </template>
      <portal to="contextmenu">
        <ControlContextMenu ref="menu" :key="contextMenuKey" v-if="isEditing" />
      </portal>
    </div>
  </div>
</template>

<script>
import {ResizeObserver as Polyfill} from "@juggle/resize-observer";
import Controls from "@/assets/dashboard/controls.json";
import SynopticControl from "@/components/synoptic/synoptic-control.vue";
import SynopticPropertyEditor from "@/components/synoptic/property-editor";
import {isInputControl} from "@/components/synoptic/synoptic-control.vue";

import CommandFormManager from "@/utils/command-form-manager.js";

const ResizeObserver = window.ResizeObserver || Polyfill;
import {
  writeToClipboard,
  readFromClipboard,
  eventMatchesSelector
} from "@/utils";
import {debounce} from "lodash";
import ControlContextMenu from "@/components/editor/control-context-menu";
export default {
  name: "SynopticDisplay",
  components: {
    SynopticControl,
    ControlContextMenu
  },
  data() {
    return {
      ready: false,
      show: false,
      zoomFactor: 1
    };
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => ({})
    },
    display: {
      type: Object,
      required: true
    },
    mode: {type: String, required: true, default: "viewer"},
    panel: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isEditing: {type: Boolean, required: false, default: false},
    isPanelLocked: {type: Boolean, required: false, default: false},
    isLinked: {type: Boolean, required: false, default: false},
    headerSize: {
      type: Object,
      required: false,
      default: () => ({width: 0, height: 0})
    }
  },
  computed: {
    canvas() {
      return this?.panel?.options?.canvas || {};
    },
    canvasSize() {
      let r = {
        w: parseFloat(
          ((this.canvas?.style?.width || "") + "").replace(/px/gi, "")
        ),
        h: parseFloat(
          ((this.canvas?.style?.height || "") + "").replace(/px/gi, "")
        )
      };
      return {...r, a: r.h / r.w};
    },
    style() {
      let printout = this.$route.query.media == "print";
      let zoom = Math.round(this.zoom * 10000) / 10000;
      let width = this.canvasSize.w;
      let height = this.canvasSize.h;
      //================
      let style = {
        "max-width": "100%"
      };

      if (navigator.userAgent.indexOf("Firefox") >= 0) {
        style["transform-origin"] = "top left";
        style["transform"] = `scale(${zoom})`;
      } else {
        style["zoom"] = zoom;
      }
      // make it compatible with previous version
      let bgCanvasColor = (this?.canvas?.style || {})["background-color"];
      let bgPanelColor = (this?.panel?.style || {})["background-color"];
      if (
        bgCanvasColor != "transparent" &&
        bgPanelColor != "transparent" &&
        bgPanelColor != bgCanvasColor
      ) {
        style = {
          ...style,
          "background-color": "transparent"
        };
      }
      // style = Object.assign(
      //   style,
      //   this.canvas.style || {
      //     "background-color": "transparent"
      //   }
      // );
      style.width = width === "" || printout ? "100%" : `${width}px`;
      style.height = height === "" ? "auto" : `${height}px`;

      if (this.canvas?.src && !this.backgroundCtrl) {
        style["min-height"] = "600px";
      }
      return style;
    },
    zoom() {
      if (this.panelOptions && "zoom" in this.panelOptions) {
        if (this.panelOptions.zoom != "auto" && this.panelOptions.zoom) {
          return this.panelOptions.zoom;
        }
      }
      return this.zoomFactor;
    },
    panelOptions() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    canvasImageSrc() {
      return this?.panel?.options?.canvas?.src || "";
    },
    backgroundCtrl() {
      return (
        (this.canvasImageSrc &&
          (this.panel.options.controls || []).find(
            (item) =>
              item.synopticComponent.componentName == "SynopticImage" &&
              item.synopticComponent.src == this.panel.options.canvas.src
          )) ||
        null
      );
    },
    backgroundCtrlId() {
      return (this.backgroundCtrl && this.backgroundCtrl.id) || "";
    },
    controls() {
      let lst = (
        (this.panelOptions && this.panelOptions.controls) ||
        []
      ).filter(
        (i) => "synopticComponent" in i && i.enabled && i.synopticComponent
      );
      // old version ()
      // if (this?.panelOptions?.canvas?.src && !this.backgroundCtrl) {
      //   let control = null;
      //   Controls.forEach((item) => {
      //     if (
      //       !control &&
      //       item.template.synopticComponent.componentName == "SynopticImage"
      //     ) {
      //       control = JSON.parse(JSON.stringify(item.template));
      //       control.synopticComponent.src = this.panel.options.canvas.src;
      //       control.synopticComponent.style.border = "0px solid #afafaf";
      //       control.synopticComponent.clientRect.width = this.canvasSize.w;
      //       control.synopticComponent.clientRect.height =
      //         this.canvasSize.h < 600 ? 600 : this.canvasSize.h;
      //       lst.unshift(control);
      //     }
      //   });
      // }
      return lst;
    },
    signature() {
      // whenever component order or visibility is changed a new signature will be generated
      return this.mode == "editor"
        ? this.editingControls.map(({id}) => id).join("")
        : "";
    },
    editingControls() {
      return (this.$store.getters["synoptic/controls"] || []).filter((i) => {
        if (!i.enabled) return false;
        if (i.linked && !this.isLinked) {
          i.linked = false;
        }
        return true;
      });
    },
    currentControls() {
      if (this.isEditing && !this.isPanelLocked) {
        return this.editingControls;
      } else if (this.mode == "editor") {
        return this.controls;
      }
      // It renders input controls on top of any other type
      let lst = this.controls.map((item, ix) => {
        return {
          control: item,
          ix: isInputControl(item)
            ? -1 * (item.synopticComponent.clientRect.top + 10000)
            : -1 * (ix + 10000)
        };
      });
      return lst.sort((a, b) => b.ix - a.ix).map((i) => i.control);
    },
    largePanel() {
      return (
        this.$store.getters["dashboard/expandedPanel"] ||
        this.$store.getters["dashboard/fullscreenPanel"] ||
        ""
      );
    },
    isLarge() {
      return this.largePanel && this.panel.name == this.largePanel;
    },
    selectedControls() {
      return this.$store.getters["synoptic/selectedControls"] || null;
    },
    position() {
      let info = {
        ri: -1, // row index
        ci: -1, // column index
        pi: -1, // panel index
        nc: -1, // number of columns in row
        np: -1 // number of panels in column
      };
      const layout = this?.display?.layout || [];
      for (var ri in layout || []) {
        for (var ci in layout[ri] || []) {
          for (var pi in layout[ri][ci]?.panels || []) {
            if (layout[ri][ci].panels[pi] == this.panel.name) {
              info = {
                ri: ri,
                ci: ci,
                pi: pi,
                nc: layout[ri].length,
                np: layout[ri][ci].panels.length
              };
            }
          }
        }
      }
      return info;
    },
    canFitOnPage() {
      return this.position.nc == 1 && this.position.np == 1;
    },
    fitToPage() {
      // Important: although the panel could be saved with fitToPage enabled, it is possible
      // that user moved it to another layout that does not allow such behaviour. Therefore
      // it is necessary to revalidate its position on layout
      return (
        this.mode == "viewer" &&
        this.canFitOnPage &&
        (this?.panel?.options?.fitToPage ?? false)
      );
    },
    nEditingControls() {
      return this?.editingControls?.length || 0;
    }
  },
  watch: {
    signature(n) {
      if (n && this.ready && this.mode == "editor" && this.show) {
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
    selectedControls(n) {
      if (n && !n.length && this.canvasResizer) {
        this.canvasResizer();
      }
    },
    isLarge() {
      this.resizing = false;
    },
    canvasImageSrc(n, o) {
      if (!this.mode == "editor" || !this.show || n == o) return;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    isEditing(n, o) {
      this.$refs.menu && this.$refs.menu.close();
      if (n && !o) {
        setTimeout(() => {
          this.notififyEditor();
          this.onClearSelection();
          this.$store.dispatch("synoptic/setPropertyEditorEnabled", true);
        }, 100);
      }
    },
    nEditingControls(n, o) {
      if (o != n && this.show) {
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
          this.$forceUpdate();
        });
      }
    },
    show(n) {
      if (!n) return;
      this.$nextTick(() => {
        if (!this._formManager) {
          // since controls must be visible...
          this.setupForms();
        }
        if (this._iniTimeout) {
          clearTimeout(this._initTimeout);
          this._initTimeout = null;
          if (this._delayedSizeWatcher && this._p && this.ready) {
            let zf = this.zoomFactor;
            this.calcZoomFactor();
            // console.log(`${zf} ${this.zoomFactor} ${this.resizing}`);
            if (zf.toFixed(4) == this.zoomFactor.toFixed(4) && this.resizing) {
              this.zoomFactor = 1;
              this.resizing = false;
              this.$nextTick(() => {
                this.updateSize();
              });
            }
          }
        }
      });
    },
    isPanelLocked() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  methods: {
    notififyEditor() {
      if (!this.mode == "editor" || !this.$el) return;
      this.$nextTick(() => {
        this.trigger({
          action: "panel:size",
          details: {
            h: this._size.height,
            w: this._size.width
          }
        });
      });
    },
    calcZoomFactor() {
      let w = parseInt(this.canvasSize.w ?? this.canvas.style?.width);
      let h = parseInt(this.canvasSize.h ?? this.canvas.style?.height);
      w = w * (this.$root.editorZoom != 0 ? this.$root.editorZoom : 1);
      h = h * (this.$root.editorZoom != 0 ? this.$root.editorZoom : 1);
      if (this._size.width && w) {
        let zoom = 1;
        let zw = this._size.width / w;
        if (this.fitToPage && h * zw > this._size.height) {
          let offsetY =
            (this.$refs.wrapper &&
              this.$refs.wrapper.getBoundingClientRect().y + window.scrollY) ||
            this.headerSize.height;
          let zh = (this._size.height - offsetY) / h;
          if (w * zh > this._size.width) {
            zoom = (zw + zh) / 2;
          } else {
            zoom = zh;
          }
          // zoom = Math.floor(zoom * 100) / 100;
        } else {
          zoom = zw;
        }
        if (
          Math.abs(
            Math.ceil(this.zoomFactor * 1000) - Math.floor(zoom * 1000)
          ) >= 2
        ) {
          this.zoomFactor = zoom;
          // console.log(zoom);
        }
      }
    },
    updateSize() {
      // console.log(`updateSize ${this._firstTime}`);
      if (!this.resizing && this.$refs.wrapper) {
        let zf = this.zoomFactor;
        this.calcZoomFactor();
        if (zf != this.zoomFactor || this._firstTime) {
          this._firstTime = false;
          this.$nextTick(() => {
            this.resizing = true;
            let h = parseInt(this.canvasSize.h ?? this.canvas.style?.height);
            if (h) {
              h = h * this.zoomFactor;
              if (
                Math.ceil(h) !=
                Math.ceil(
                  this.$refs.wrapper?.getBoundingClientRect()?.height ?? 0
                )
              ) {
                this.$refs.wrapper.style.height = `${h}px`;
              }
            }
            if (!this.show && !this._iniTimeout) {
              this.$nextTick(() => {
                this.show = true;
                this.resizing = false;
              });
            }
          });
        }
      }
    },
    panelChangeMonitor() {
      let self = this;
      this._iniTimeout = null;
      this._firstTime = true;
      this._size = {
        height: 0,
        width: 0,
        ratio: window.devicePixelRatio
      }; // not reactive

      const setSize = (el) => {
        let ret = true;
        let r = (el && el?.getBoundingClientRect()) || null;
        let so =
          document.scrollingElement.scrollHeight >
          document.scrollingElement.clientHeight;
        let h = parseInt((r && r.height) || 0);
        let w = parseInt((r && r.width) || 0);
        if (h != self._size.height || w != self._size.width) {
          if (so != this.so) {
            w = w + (this.so === undefined ? 0 : so ? 6 : 1);
            this.so = so;
            ret = false;
          }
          self._size.y = r.y;
          self._size.x = r.x;
          self._size.height = h;
          self._size.width = w;
          this.notififyEditor();
        }
        return ret;
      };

      const _sw = (el) => {
        let so = setSize(el);
        // console.log(JSON.stringify(self._size))
        if (self._size.width && self._size.height) {
          self.resizing = false;
          if (!self.ready) {
            self.calcZoomFactor();
            self.ready = true;
            if (!self._iniTimeout) {
              self.show = true;
            }
            return;
          }
          // it avoid the flirckering effect on the edge of vertical scroll bar
          if (!this.skipNextUpdate) {
            self.updateSize();
            self.$root.$emit("panel:resized");
          }
          this.skipNextUpdate = !so;
        }
        self.ready = true;
      };

      const _delayedSizeWatcher = debounce((els) => {
        let el = (els && els.length ? els[0] : null)?.target || null;
        _sw(el);
      }, 10);

      this._delayedSizeWatcher = _delayedSizeWatcher;

      const sizeWatcher = new ResizeObserver((els) => {
        if (window.devicePixelRatio != self._size.ratio) {
          self._size.ratio = window.devicePixelRatio;
          _delayedSizeWatcher(els);
          return;
        }
        _delayedSizeWatcher(els);
      });

      let _p = this.$refs.wrapper;
      while (!_p.classList.contains("inner-panel")) {
        _p = _p.parentElement;
        if (!_p) break;
      }
      if (_p) {
        this._p = _p;
        sizeWatcher.observe(_p);
        if (window.location != window.parent.location) {
          _sw(_p);
        }
        self.$root.$emit("panel:resized");
        if (this.mode !== "editor") {
          this._iniTimeout = setTimeout(
            () => {
              clearTimeout(this._initTimeout);
              this._initTimeout = null;
              this.show = true;
            },
            500,
            this
          );
        }
      }
    },
    onDelete() {
      if (!this.isEditing) return;
      // this.selectedControls.forEach(({id}) => this.delControl(id));
      this.$store.dispatch("synoptic/delSelectedControls");
    },
    onCopy() {
      if (!this.isEditing) return;
      return new Promise((resolve) => {
        if (!this.selectedControls.length) return;
        let controls = JSON.parse(JSON.stringify(this.selectedControls));
        // add prop to verify if it's a control on paste action
        controls.forEach((control) => (control.isControl = true));
        let serializedControls = JSON.stringify(controls);
        // try to write into clipboard. If it fails, ask for permission and try again
        writeToClipboard(serializedControls)
          .then(resolve)
          .catch(() => navigator.permissions.query({name: "clipboard-write"}))
          .then((result) => {
            if (result.state == "granted" || result.state == "prompt") {
              return writeToClipboard(serializedControls).then(resolve);
            }
          })
          .catch(() => {
            // TODO: create fallback function
          });
      });
    },
    onCut() {
      if (!this.isEditing) return;
      this.onCopy().then(this.onDelete.bind(this));
    },
    onPaste() {
      if (!this.isEditing) return;
      readFromClipboard()
        .catch(() =>
          navigator.permissions
            .query({name: "clipboard-read"})
            .then((result) => {
              if (result.state == "granted" || result.state == "prompt") {
                return readFromClipboard();
              }
            })
        )
        .then((text) => {
          if (typeof text == "string") {
            try {
              let controls = JSON.parse(text);
              if (!(controls instanceof Array)) return;
              controls.forEach((control) => {
                if (control.isControl) {
                  delete control.isControl;
                  control.synopticComponent.clientRect.top += 10;
                  control.synopticComponent.clientRect.left += 10;
                  this.addControl(control).then(({id}) => {
                    this.$store.dispatch("synoptic/addToSelection", id);
                  });
                }
              });
            } catch (e) {}
          }
        });
    },
    onMove(option) {
      if ((this.selectedControls || []).length) {
        this.selectedControls.forEach((control) => {
          control = JSON.parse(JSON.stringify(control));
          let clientRect = control?.synopticComponent?.clientRect || null;
          if (clientRect) {
            let direction = option?.direction || "";
            let size = option?.ctrlKey || false;
            let increment = option?.shiftKey ? 1 : 10;
            switch (direction) {
              case "up":
                if (size) {
                  clientRect.height -= increment;
                } else {
                  clientRect.top -= increment;
                }
                break;
              case "down":
                if (size) {
                  clientRect.height += increment;
                } else {
                  clientRect.top += increment;
                }
                break;
              case "left":
                if (size) {
                  clientRect.width -= increment;
                } else {
                  clientRect.left -= increment;
                }
                break;
              case "right":
                if (size) {
                  clientRect.width += increment;
                } else {
                  clientRect.left += increment;
                }
                break;
            }
            this.$store.dispatch("synoptic/updateControl", {
              id: control.id,
              control: control,
              noMerge: true
            });
          }
        });
      }
    },
    onClearSelection() {
      if (
        !this.isEditing ||
        this.isLinked ||
        this.$store.getters["synoptic/isRotating"]
      ) {
        return;
      }
      this.clearSelectedControls();
      if (this.$store.getters["synoptic/propertyEditorEnabled"]) {
        this.$root.$emit("controlSidebar:setContent", SynopticPropertyEditor);
      }
    },
    clearSelectedControls() {
      this.$store.dispatch("synoptic/clearSelectedControls");
    },
    onSelectAll() {
      this.$store.dispatch("synoptic/selectAll");
    },
    delControl(id) {
      this.$store.dispatch("synoptic/delControl", id);
    },
    addControl(control) {
      return this.$store.dispatch("synoptic/addControl", control);
    },
    handleDrop(e) {
      if (e.dataTransfer.getData("text/plain")) {
        try {
          let control = JSON.parse(
            e.dataTransfer.getData("text/plain") || "null"
          );
          if (control) {
            // TODO: for better managing the acceptable content set, replace this method to the JSONDragger.onDrop
            if (control.type && control.type != "control") {
              return true; // do not stop propagation
            }
            e.target.style.opacity = "1";
            //=========================
            const zoom = parseFloat(this.zoomFactor) || 1;
            const rect = this.$refs.synoptic.getBoundingClientRect();
            const w = control.synopticComponent.clientRect.width;
            const h = control.synopticComponent.clientRect.height;
            // const x = e.pageX / zoom - rect.x - window.scrollX / zoom;
            const x = (e.pageX - (rect.x + window.scrollX)) / zoom;
            // const y = e.pageY / zoom - (rect.y - window.scrollY) / zoom;
            const y = (e.pageY - (rect.y + window.scrollY)) / zoom;
            control.synopticComponent.clientRect.left = Math.round(
              (x - w / 2 < 0 ? 0 : x - w / 2) / this.$root.editorZoom
            );
            control.synopticComponent.clientRect.top = Math.round(
              (y - h / 2 < 0 ? 0 : y - h / 2) / this.$root.editorZoom
            );
            this.addControl(control);
          }
        } catch (e) {}
      }
      e.stopPropagation();
      return false;
    },
    openControlMenu(e) {
      if (!this.isEditing || !this.$refs.menu) return;
      e.preventDefault();
      e.stopPropagation();
      let control;
      if (eventMatchesSelector(e, ".synoptic-control")) {
        control = e.target.closest(".resizable-component").__vue__.$parent
          .control;
      }

      // when contextmenu event is triggered by keyboard
      if (e.button == -1) {
        // uses coords of top-left corner of canvas
        let rect = this.$el.getBoundingClientRect();
        e = {clientX: rect.x, clientY: rect.y};
      } else if (!eventMatchesSelector(e, ".synoptic")) return;
      this.$refs.menu.open(e, control);
    },
    trigger(ev) {
      this.$root.$emit("synoptic:event", ev);
    },
    getInputControls() {
      return (this.currentControls || [])
        .map((item) => {
          if (isInputControl(item)) {
            let vitem = this.$children.find(
              (i) => i.control && i.control == item
            );
            if (vitem) {
              // if (this._skipForm && this._skipForm[item.id]) {
              //   vitem.control.synopticComponent.formName = '';
              // }
              return vitem?.$children[0]?.$children[0];
            }
          }
          return null;
        })
        .filter((i) => i != null);
    },
    setupForms() {
      this._formManager = null;
      if (this.mode == "editor") return;
      this._skipForm = null;
      let config = {};
      let formName = "",
        actionType = "";
      let lst = this.currentControls.filter((item) => {
        if (item.synopticComponent?.on?.click?.actions?.length > 0) {
          actionType = item.synopticComponent.on.click.actions[0]?.type || "";
          if (actionType == "form:submit") {
            formName =
              item.synopticComponent.on.click.actions[0]?.options?.formName
                ?.value || "";
            if (formName) config[formName] = true;
          } else if (actionType.startsWith("tag:")) {
            return true;
          }
        }
        return isInputControl(item);
      });
      lst.forEach((item) => {
        formName = item?.synopticComponent?.formName || "";
        if (formName && !config[formName]) {
          // this._skipForm = this._skipForm || {}
          // this._skipForm[item.id] = true;
          item.synopticComponent.formName = "";
        }
      });
      if (lst && lst.length) {
        this._formManager = new CommandFormManager(this);
      }
    },
    onCanvasClick($event, dblClick) {
      this.onClearSelection();
      if ($event.ctrlKey || dblClick) {
        this.$root.$emit("synoptic:tab", "synoptic-component");
      } else {
        this.$root.$emit("dashboard:editor", {
          action: "editPanel",
          data: {
            panelName: this.panel.name,
            showContentProperties: true
          }
        });
      }
    },
    hasDirtyControls() {
      return (this.getInputControls() || []).some(({isDirty}) => isDirty);
    },
    clampControls(items) {
      const sz = this.canvasSize;
      let rect = null;
      (items && items.length ? items : this.controls).forEach((item) => {
        rect = item.synopticComponent.clientRect;
        if (rect.left + rect.width > sz.w) rect.left = sz.w - rect.width;
        if (rect.top + rect.height > sz.h) rect.top = sz.h - rect.height;
        if (rect.top < 0) rect.top = 0;
        if (rect.left < 0) rect.left = 0;
      });
    }
  },
  created: function() {
    var self = this;
    self._size = {width: 0, height: 0};
    self._refreshTimer = null;
    this._timer_sb = null;
    this.contextMenuKey = this.$utils.uuid();
  },
  mounted() {
    this.$root.$on("arrows", this.onMove);
    this.$root.$on("delete", this.onDelete);
    this.$root.$on("copy", this.onCopy);
    this.$root.$on("cut", this.onCut);
    this.$root.$on("paste", this.onPaste);
    this.$root.$on("clear-selection", this.onClearSelection);
    this.$root.$on("select-all", this.onSelectAll);
    this.$root.$on("contextmenu", this.openControlMenu);
    this.$root.$on("synoptic-editor:canvas", this.updateSize);
    this.$root.$on("synoptic-editor:clamp-controls", this.clampControls);
    this.panelChangeMonitor();
  },
  beforeDestroy() {
    this._formManager =
      (this._formManager && this._formManager.destroy()) || null;
  },
  destroyed() {
    this.$root.$off("arrows", this.onMove);
    this.$root.$off("delete", this.onDelete);
    this.$root.$off("copy", this.onCopy);
    this.$root.$off("cut", this.onCut);
    this.$root.$off("paste", this.onPaste);
    this.$root.$off("clear-selection", this.onClearSelection);
    this.$root.$off("select-all", this.onSelectAll);
    this.$root.$off("contextmenu", this.openControlMenu);
    this.$root.$off("synoptic-editor:canvas", this.updateSize);
    this.$root.$off("synoptic-editor:clamp-controls", this.clampControls);
    var self = this;
    if (self._refreshTimer) {
      clearInterval(self._refreshTimer);
      self._refreshTimer = null;
    }
  }
};
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.synoptic-wrapper {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  -webkit-text-size-adjust: auto;
}

.synoptic {
  display: inline-block;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  margin: 0;
  padding: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>
