<template>
  <section v-if="panelOptions">
    <ImageSelector v-model="src" />
    <div class="row">
      <div class="col-xs-5">
        <label class="text-center">{{ $t("synoptic.text_align") }}</label>
        <AlignSelector v-model="align" style="zoom: 0.7" />
      </div>
      <div class="col-xs-5">
        <div class="form-group form-group-sm">
          <label for="size"> {{ $tc("size", 1) }} %</label>
          <input
            class="form-control text-center"
            type="number"
            name="size"
            v-model="size"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AlignSelector from "@/components/widgets/align-selector.vue";
import ImageSelector from "@/components/synoptic/property-editor/controls/image-selection.vue";
export default {
  name: "DetailFormImage",
  components: {
    AlignSelector,
    ImageSelector
  },
  data() {
    return {
      info: null,
      panelOptions: null
    };
  },
  computed: {
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    align: {
      set(vlr) {
        if (this?.panel?.options?.image) {
          this.$set(this.panel.options.image, "align", vlr);
        }
      },
      get() {
        return this?.panel?.options?.image?.align || "center";
      }
    },
    src: {
      set(vlr) {
        if (this?.panel?.options?.image) {
          this.$set(this.panel.options.image, "src", vlr);
        }
      },
      get() {
        return this?.panel?.options?.image?.src || "";
      }
    },
    size: {
      set(vlr) {
        if (this?.panel?.options?.image) {
          let perc = parseInt(vlr) + "%";
          this.$set(this.panel.options.image, "size", perc);
        }
      },
      get() {
        return (this?.panel?.options?.image?.size || "100%").replace(/%/, "");
      }
    }
  },
  watch: {
    panelOptions: {
      handler(n) {
        if (!n) return;
        this.$store.dispatch("dashboard/saveDraftPanel", {
          panel: {
            ...this.panel,
            options: {
              ...this.panel.options,
              ...n
            }
          },
          screenId: this.draft.screenId
        });
      },
      deep: true
    }
  },
  methods: {
    onImageEvent($event) {
      switch ($event.action) {
        case "image:activate": {
          this.info = $event.details;
          break;
        }
      }
    }
  },
  created() {
    this.panelOptions = structuredClone(this.panel.options);
    this.$root.$on("image:event", this.onImageEvent);
  },
  beforeDestroy() {
    this.$root.$off("image:event", this.onImageEvent);
  }
};
</script>

<style scoped></style>
