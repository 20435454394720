<template>
  <div>
    <div class="row">
      <div class="col-xs-12 text-center">
        <div class="alert alert-default">
          <p style="padding: 10px 0; font-size: 14pt">
            <i class="icon fa fa-warning"></i> {{ $t("no_item_recorded") }}
          </p>
          <template v-if="!isInstance">
            <p style="padding: 10px 0">
              <span
                class="btn btn-primary"
                @click.stop.prevent="newItem"
                v-bind="$attrs"
              >
                <Icon name="fa fa-plus" />
                {{ $t(buttonLabel) }}
              </span>
              <template v-if="!hideImport">
                &nbsp;
                <span
                  class="btn btn-success"
                  @click.stop.prevent="importItems"
                  v-bind="$attrs"
                >
                  <Icon name="fa fa-upload" />
                  {{ $t(importLabel) }}
                </span>
              </template>
            </p>
          </template>
          <template v-if="isInstance">
            <p style="padding: 10px 0; font-size: 10pt">
              <i class="icon fa fa-ban"></i>
              {{ $t("instance_creation_not_allowed") }}
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/icon.vue";

export default {
  name: "EmptyListAlert",
  inheritAttrs: false,
  components: {
    Icon
  },
  props: {
    buttonLabel: {
      type: String,
      default: "new_item",
      required: false
    },
    importLabel: {
      type: String,
      default: "import",
      required: false
    },
    hideImport: {
      type: Boolean,
      default: false,
      required: false
    },
    isInstance: {
      type: Boolean,
      default: false,
      required: false
    },
    newItemPath: {
      type: String,
      required: false,
      default: "/dashboard/edit/connector/0"
    }
  },
  methods: {
    newItem() {
      if (this.$attrs.disabled) return;
      if (!this.newItemPath) {
        this.$emit("new");
      } else {
        this.$router.push(this.newItemPath);
      }
    },
    importItems() {
      if (this.$attrs.disabled) return;
      this.$emit("import");
    }
  }
};
</script>

<style scoped>
.alert-default {
  background-color: #f4f2f2;
  color: #505050;
  border-color: #cecece;
}

.skin-dark .alert-default {
  background-color: #222d32;
  border-color: #1e282c;
  color: #b8c7ce;
  text-shadow: inherit;
}

.alert-default a {
  text-decoration: none;
}
</style>
