<template>
  <div class="navigator navigator-expanded">
    <div class="navigator-header">
      <div>{{ title }}</div>
      <div class="toolbar hidden-xs">
        <template v-for="item in toolbar">
          <div
            class="btn"
            v-if="item.visible"
            :key="item.id"
            :title="$t(item.title)"
            :disabled="item.disabled || item.pending"
            @click.stop.prevent="$emit('command', item.command)"
          >
            <i :class="item.pending ? 'fa fa-refresh fa-spin' : item.icon"></i>
          </div>
        </template>
        <div
          class="btn"
          :title="$t('close')"
          @click.stop.prevent="$emit('command', 'close')"
        >
          <i class="fa fa-close"></i>
        </div>
      </div>
    </div>
    <div class="navigator-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
const dftToolbar = () => [
  {
    id: "undo",
    icon: "fa fa-history",
    title: "reset",
    command: "undo",
    visible: true,
    enabled: true,
    pending: false
  },
  {
    id: "publish",
    icon: "fa fa-cloud-upload",
    title: "save",
    command: "publish",
    visible: true,
    enabled: true,
    pending: false
  }
];

export {dftToolbar};
export default {
  name: "FileExplorerSideBar",
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    toolbar: {
      type: Array,
      required: false,
      default: dftToolbar
    }
  }
};
</script>

<style scoped>
div.navigator {
  background-color: white;
  position: relative;
  padding-bottom: 50px;
}

.skin-dark div.navigator {
  background-color: #2c3b41;
}

div.navigator > .navigator-header {
  position: relative;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 12pt;
  font-weight: 600;
  color: #555;
  border-bottom: 1px solid #dcdcdc;
  overflow: hidden;
}

.skin-dark div.navigator > .navigator-header {
  color: #b8c7ce;
}

div.navigator > .navigator-header {
  border-bottom: 1px solid #222d32;
}

div.navigator > .navigator-header > .toolbar {
  position: absolute;
  right: 4px;
  top: 0;
  background-color: rgba(255, 255, 255, 0.824);
}

.skin-dark div.navigator > .navigator-header > .toolbar {
  background-color: #2c3b41;
}

div.navigator > .navigator-header > .toolbar .btn {
  padding: 6px;
  min-width: 29px;
}

div.navigator > .navigator-content {
  /* clear: both; */
  padding: 0 10px;
  font-size: 14px;
  min-width: 100px;
}

div.navigator > .navigator-content > div {
  margin-left: -15px;
  margin-top: -10px;
}

i.fa {
  min-width: 14px;
}
div.btn:not([disabled]) > i.fa-cloud-upload {
  color: #a94442;
}
</style>
