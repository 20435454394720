<template>
  <section>
    <PanelHeaderEquipmentList
      :title="title"
      :nav="parsedNav"
      icon="fa fa-dashboard"
      :connector_id="connectorId"
      @tab="tab = $event"
      ref="header"
    />
    <!-- active  -->
    <div
      class="tab-pane"
      role="tabpanel"
      aria-labelledby=""
      id="edit"
      :class="tab == '#edit' ? 'active' : ''"
    >
      <FormConnector
        v-if="contract && processAreas.length"
        :key="formConnectorKey"
        :equipment_id="connector_id"
        :formActionRequest="formActionRequest"
        @titleChanged="onTitleChanged"
        @navChanged="onNavChanged"
        @noData="$emit('noData')"
        @loading="(state) => $emit('loading', state)"
        @refreshUI="onRefreshUI"
        @resetFormActionRequest="formActionRequest = ''"
        @change="connector = $event"
        @refreshDevices="onRefreshDevices"
      />
    </div>
    <div
      class="tab-pane tab-pane-list"
      role="tabpanel"
      aria-labelledby=""
      id="device_list"
      :class="tab == '#device_list' ? 'active' : ''"
      v-if="connector && showDeviceTab"
    >
      <DashboardEditDevicePicker
        v-if="connectorId"
        :connector_id="connectorId"
        :header="false"
        :formPicker="true"
        @devices="onDeviceListUpdate($event)"
        @resource_imported="resourceImported"
        @resource_deleted="resourceDeleted"
      />
      <div
        v-if="baseModelId"
        :style="{'margin-top': connectorId ? '-180px' : '0'}"
      >
        <DeviceSelector
          v-if="tab == '#device_list' && connector"
          :key="resourceChangedCounter.device"
          @update:preserveReferencyId="preserveReferencyId = $event"
          @update:forecastData="forecastData = $event"
          v-model="selectedDeviceIds"
          :disabled="true"
          :forceDataListRequest="false"
          :connector="connector"
        />
      </div>
    </div>
    <div
      class="tab-pane tab-pane-list"
      role="tabpanel"
      aria-labelledby=""
      v-if="isSaved"
      id="data_list"
      :class="tab == '#data_list' ? 'active' : ''"
    >
      <template>
        <!-- :device_id="defaultDeviceId" -->
        <DashboardEditDataPicker
          :key="resourceChangedCounter.data"
          :connector_id="connectorId"
          :header="false"
          :showEmptyPanel="true"
          :deviceList="deviceList"
          :editable="isSavedModel || isSavedConnector"
          @data="
            dataList = $event;
            resourceChangedCounter.alarm++;
          "
          @resource_imported="resourceImported"
          @resource_deleted="resourceDeleted"
        />
      </template>
    </div>
    <div
      id="alarm_list"
      class="tab-pane tab-pane-list"
      role="tabpanel"
      aria-labelledby=""
      :class="tab == '#alarm_list' ? 'active' : ''"
      v-if="isSaved"
    >
      <template>
        <DashboardEditAlarmPicker
          :key="resourceChangedCounter.alarm"
          :connector_id="connectorId"
          :device_id="defaultDeviceId"
          :data_id="defaultDataId"
          :header="false"
          :showEmptyPanel="true"
          :dataList="dataList"
          @resource_imported="resourceImported"
          @resource_deleted="resourceDeleted"
        />
      </template>
    </div>
    <div
      id="tab_instances"
      class="tab-pane tab-pane-list"
      role="tabpanel"
      aria-labelledby=""
      :class="tab == '#tab_instances' ? 'active' : ''"
      v-if="tab == '#tab_instances' && isSavedModel"
    >
      <div>
        <div class="table-title">
          <div class="label">{{ $tc("connector_instances", 1) }}</div>
        </div>
        <DashboardEditConnectorPicker
          :header="false"
          :showEmptyPanel="true"
          :connectorModelId="connectorId"
        />
      </div>
    </div>
    <div
      id="tab_machine"
      class="tab-pane tab-pane-list"
      role="tabpanel"
      aria-labelledby=""
      :class="tab == '#tab_machine' ? 'active' : ''"
      v-if="tab == '#tab_machine'"
    >
      <FormMachine
        :connector_id="connector_id"
        :machine_id="machine_id"
        :reload="resourceChangedCounter.machine"
        @setMachineId="machine_id = $event"
      />
    </div>
  </section>
</template>

<script>
import PanelHeaderEquipmentList from "@/components/panel-header-equipment-list.vue";
import FormConnector from "@/components/registration/form-connector.vue";
import FormMachine from "@/components/registration/form-machine.vue";
import DashboardEditDevicePicker from "@/components/registration/dashboard-edit-device-picker.vue";
import DashboardEditDataPicker from "@/components/registration/dashboard-edit-data-picker.vue";
import DashboardEditAlarmPicker from "@/components/registration/dashboard-edit-alarm-picker.vue";
import DashboardEditConnectorPicker from "@/components/registration/dashboard-edit-connector-picker.vue";
import DeviceSelector from "@/components/registration/device-selector.vue";
import DashboardEditFormBase from "@/components/registration/dashboard-edit-form-base.vue";
import MachineService from "@/services/machine.js";
import {isMQTT} from "@/services/connector.js";
import {contractPlanConst} from "@/assets/constants.js";

export default {
  name: "DashboardEditConnectorForm",
  extends: DashboardEditFormBase,
  components: {
    FormConnector,
    DashboardEditDevicePicker,
    DashboardEditDataPicker,
    DashboardEditAlarmPicker,
    PanelHeaderEquipmentList,
    DashboardEditConnectorPicker,
    DeviceSelector,
    FormMachine
  },
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      title: "loading",
      tab: "",
      nav: null,
      formConnectorKey: this.connector_id,
      formActionRequest: "",
      connector: null,
      machine_id: null,
      deviceList: null,
      dataList: null,
      selectedDeviceIds: [],
      preserveReferencyId: false,
      forecastData: 0,
      cloneEnabled: false,
      showDeviceTab: true,
      resourceChangedCounter: {
        device: 0,
        data: 0,
        alarm: 0,
        machine: 0
      }
    };
  },
  computed: {
    isSaved() {
      return this?.connector?.id ? true : false;
    },
    isSavedModel() {
      return this.isSaved && this?.connector?.base_model;
    },
    isSavedConnector() {
      return (
        this.isSaved &&
        !this?.connector?.base_model &&
        !this?.connector?.base_model_id
      );
    },
    isFreePlan() {
      return (
        false || this?.contract?.contract_plan?.id == contractPlanConst.FREE
      );
    },
    connectorId() {
      return parseInt(
        this?.connector?.id ||
          this.connector_id ||
          this.$route.params.connector_id ||
          0
      );
    },
    modelExtendedProperties: {
      set(value) {
        this.modelConfiguration.extendedProperties = value;
      },
      get() {
        return this.modelConfiguration.extendedProperties;
      }
    },
    defaultDeviceId() {
      return this?.deviceList?.length == 1 ? this.deviceList[0].id : 0;
    },
    defaultDataId() {
      return this?.dataList?.length == 1 ? this.dataList[0].id : 0;
    },
    parsedNav() {
      if (this?.nav?.tabs?.items) {
        return JSON.parse(JSON.stringify(this?.nav));
      }
      return this.nav;
    },
    baseModelId() {
      return this?.connector?.base_model_id || "";
    },
    baseModel() {
      return this?.connector?.base_model || false;
    }
  },
  watch: {
    connectorId: {
      handler(n, o) {
        if (n && n != o) {
          this.cloneEnabled = this.$utils.gup("a") == "c";
        }
      },
      immediate: true
    },
    baseModelId(n, o) {
      if (this.connector && n != o) {
        this.updateNav();
      }
    },
    baseModel(newValue, oldValue) {
      if (this.connector && newValue != oldValue) {
        this.updateNav();
      }
    },
    connector: {
      handler(n, o) {
        if (n && !o) {
          if (n) this.updateNav();
        }
      },
      deep: true
    }
  },
  methods: {
    updateNav() {
      this.onNavChanged({
        previous: "/dashboard/edit/connector",
        items: [
          {
            name: "connector_plural",
            url: "/dashboard/edit/connector"
          },
          {
            name: this?.connector?.name,
            url: "/dashboard/edit/connector"
          }
        ],
        isMQTT: isMQTT(this?.connector)
      });
    },
    activateTab(hash) {
      if (
        !hash ||
        !this.$refs.header ||
        !this?.nav?.tabs?.items?.length ||
        !(this.nav.tabs.items || [])
          .map(({id}) => `#${id}`)
          .some((item) => item == hash)
      )
        return;
      this.$refs.header.activate(hash);
    },
    onDeviceListUpdate(deviceList) {
      this.$set(this, "deviceList", deviceList);
      this.resourceChangedCounter.data++;
      this.resourceChangedCounter.alarm++;
    },
    onRefreshDevices() {
      this.showDeviceTab = false;
      this.$nextTick(() => {
        this.showDeviceTab = true;
      });
    },
    onTitleChanged(title) {
      //this.$emit("titleChanged", );
      this.title = this.$tc("connector") + ": " + title;
    },
    onRefreshUI(id) {
      if (!id || parseInt(id) != parseInt(this.formConnectorKey)) {
        this.formConnectorKey = this.$utils.uuid();
      }
      for (var p in this.resourceChangedCounter)
        this.resourceChangedCounter[p]++;
    },
    onNavChanged(nav) {
      // if (this.connectorId) {
      nav = nav || {};
      nav.tabs = {
        items: []
      };
      nav.showTabLabels = true;
      if (this.connectorId) {
        if (!this.isFreePlan)
          nav.tabs.items.push({
            id: "alarm_list",
            icon: "fa fa-bell",
            title: "alarm_list",
            label: this.$tc("tab_label.alarm", 2)
          });

        nav.tabs.items.push({
          id: "data_list",
          icon: "glyphicon glyphicon-stats",
          title: "data_list",
          label: this.$tc("tab_label.data", 2)
        });
      }
      if (
        // !nav.isMQTT &&
        this.connector &&
        (this.connectorId || this.connector.base_model_id)
      ) {
        nav.tabs.items.push({
          id: "device_list",
          icon: "portal-icon device[solid]",
          title: "device_list",
          label: this.$tc("tab_label.device", 2)
        });
      }

      nav.formTabs = [];
      if (this.isSavedModel) {
        nav.formTabs.push({
          id: "tab_instances",
          icon: "glyphicon glyphicon-pushpin",
          title: "connector_instances"
        });
      }
      (nav?.formTabs || []).forEach((tab) => {
        nav.tabs.items.push({...tab, formTab: true});
      });

      if (
        !this.baseModel &&
        this.connectorId &&
        this?.contract?.allowed_onlinee
      ) {
        nav.tabs.items.push({
          id: "tab_machine",
          icon: "fa fa-industry",
          title: "machine",
          label: "machine"
        });
      }

      nav.tabs.items.push({
        id: "edit",
        icon: "fa fa-plug",
        title: "connector",
        label: "connector",
        options: nav?.formTabs || []
      });
      // }

      this.$set(this, "nav", nav);
    },
    resourceImported(entity) {
      this.resourceChangedCounter[entity] += 1;
    },
    resourceDeleted(entity) {
      this.resourceChangedCounter[entity] += 1;
    },
    fetchMachine() {
      if (
        !this.connectorId ||
        this.connector ||
        this.baseModel ||
        !this.contract?.allowed_onlinee
      ) {
        return;
      }

      this.machine_id = (this.$store.getters["onlinee/machines"] || []).find(
        (item) => item.connector_id == this.connectorId
      )?.id;

      if (this.machine_id) return;

      let srv = new MachineService();
      let query = {
        contract_id: this.contract.id,
        connector_id: this.connectorId
      };
      srv.fetch(query).then((machine) => {
        if (!machine || !machine[0]?.id) return;
        this.machine_id = machine[0]?.id;
        this.$store.dispatch("onlinee/setMachine", machine[0]);
        this.resourceChangedCounter.machine++;
      });
    }
  },
  beforeCreate() {
    this.$emit("rule", "EquipamentoCadastro");
  },
  created() {
    this.fetchMachine();
  }
};
</script>

<style scoped>
section > .tab-pane {
  /* margin: 30px 0 30px 0;
  padding: 10px 10px 50px 10px; */
  margin: 0;
  padding: 0 10px 50px 10px;
}

section > .tab-pane-list {
  padding: 0;
}

.bottom-panel {
  margin-top: 30px;
  padding: 10px;
}
.bottom-panel > .box-header {
  font-size: 16pt;
  margin-left: -10px;
}

.scrollable-iframe {
  border: 0;
  min-height: 800px;
  overflow: auto;
  width: 100%;
}

.extended-properties-panel {
  padding: 0;
  margin: -10px 10px 100px 10px;
}

div.table-title {
  padding: 0 0 10px 0;
}

div.table-title .label {
  font-size: 14pt;
  color: #777;
  text-align: left;
}

.skin-dark div.table-title .label {
  color: #b8c7ce;
}

#tab_machine {
  margin: 0 0 30px 0;
  padding: 0 15px 50px 15px;
  overflow-x: auto;
}
</style>
