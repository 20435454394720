import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import ConnectorService from "@/services/connector.js";
import {parseMessage} from "@/utils";
import Utils from "@/plugins/utils.js";

Vue.use(VueResource);
Vue.use(Utils);
const _vue = new Vue();
/*
const simulator = (lst) => {
  window.dev_flag = window.dev_flag ? false : true;
  lst.forEach((item) => {
    item.connected = window.dev_flag;
    item.has_active_alarms = window.dev_flag;
    item.enabled = window.dev_flag;
  });
};
*/

/*
public methods:
  fetch - request a list of items
  get - request a single item
  save - request to save or update a single item
  remove - request to remove a single item
  duplicate - request an item copy
*/

const baseurl = ""; //v1  set to "" after conversion

const extendedPropertiesSetter = (item, value) => {
  if (!item) return;
  if (value !== undefined && value !== null && Array.isArray(value)) {
    item.portal_data = item.portal_data || {};
    item.portal_data.extended_properties = structuredClone(value);
    // user_data update
    let user_data = item.user_data || {};
    user_data.extended_properties = user_data.extended_properties || {};
    let entry = {};
    let previous = "";
    (item.portal_data.extended_properties || []).forEach((item) => {
      previous = user_data.extended_properties[item.name];
      if (
        previous === undefined ||
        previous.toString() === "" ||
        previous.toString() !== item.value.toString()
      ) {
        entry[item.name] = item.value;
      } else {
        entry[item.name] = previous;
      }
    });
    user_data.extended_properties = entry;
    item.user_data = user_data;
  }
};

const extendedPropertiesDeviceAdapter = (item, lst) => {
  if (item?.connector?.base_model_id) {
    // connector instance
    let devModel = (lst || []).find(
      ({connector, reference_id}) =>
        parseInt(connector.id) === parseInt(item.connector.base_model_id) &&
        item.reference_id == reference_id
    );
    if (!devModel) return;
    if (devModel.reference_device_id) {
      let devRef = lst.find(
        ({id}) => parseInt(id) === parseInt(devModel.reference_device_id)
      );
      if (!devRef) return;
      if (devRef) devModel = devRef;
    }
    let user_data = structuredClone(item.user_data || {}); // original
    extendedPropertiesSetter(
      item,
      devModel?.portal_data?.extended_properties ?? null
    );
    item.user_data = user_data;
    item.user_data.extended_properties = user_data.extended_properties || {};
    (item?.portal_data?.extended_properties || []).forEach(({name, value}) => {
      let vlr = item.user_data.extended_properties[name];
      if (vlr === undefined) item.user_data.extended_properties[name] = value;
    });
  } else if (item?.connector?.base_model) {
    // connector model
  } else {
    // regular connector
  }
};

const deviceAdapter = (device, lst) => {
  device.connector_id = parseInt(device.connector.id); // would not be necessary
  const isMQTT =
    (device && device?.connector?.protocol?.is_mqtt_protocol) || false;
  if (isMQTT) {
    device.is_connected = device.connector.is_connected;
    if (!device.data_collector_device_id) {
      device.data_collector_device_id = parseInt(device.id);
    }
    if (device.connector.mqtt_topic_prefix) {
      if (
        device.data_collector_device_id &&
        device.data_collector_device_id != device.id
      ) {
        // virtual
        device.mqtt_topic_prefix = `${device.connector.mqtt_topic_prefix}/${device.reference_id}`;
      } else {
        // physical
        device.mqtt_topic_prefix = device.connector.mqtt_topic_prefix;
      }
    }
  } else {
    if (device.data_collector_device_id) {
      device.is_connected =
        (lst || []).find(
          ({id}) => parseInt(id) == parseInt(device.data_collector_device_id)
        )?.is_connected || device.is_connected;
    } else {
      device.data_collector_device_id = parseInt(device.id);
      // TODO: VIRTUAL DEVICE SIMULATION
      // BEGIN
      // var mapId = {
      //   "6122": { deviceId: 6120, screenId: 793 },
      //   "6262": { deviceId: 6120, screenId: 793 },
      //   "6264": { deviceId: 6120, screenId: 793 },
      //   "6121": { deviceId: 6119, screenId: 793 },
      //   "6261": { deviceId: 6119, screenId: 793 },
      //   "6263": { deviceId: 6119, screenId: 793 }
      // };
      // if (device.id in mapId) {
      //   device.data_collector_device_id = mapId[device.id].deviceId;
      //   device.screen_id = mapId[device.id].screenId;
      // }
      // END
    }
  }
  extendedPropertiesDeviceAdapter(device, lst);
  return device;
};

const deviceListAdapter = (payload) => {
  let lst = payload.id ? [payload] : payload.length ? payload : [];
  let srv = new ConnectorService();
  return (lst || []).map((item) => {
    deviceAdapter(item, lst);
    item.connector = srv.adapter(item.connector);
    return item;
  });
};

export {
  deviceListAdapter,
  deviceAdapter,
  extendedPropertiesDeviceAdapter,
  extendedPropertiesSetter
};

export default class DeviceService {
  deviceListAdapter = deviceListAdapter;

  async fetch(query, URL) {
    return new Promise((resolve) => {
      let url = URL || `${baseurl}devices/?format=json`;
      let auth = new Auth();
      if (query) {
        if (query.filters && typeof query.filters == "object") {
          query = {...query, ...query.filters};
          delete query.filters;
        }
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          resolve(response?.body?.length ? response.body : []);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async save(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}devices/`; //v1
      let request = null;
      let auth = new Auth();
      if ("id" in payload && payload.id) {
        request = Vue.http.patch(
          url + payload.id + "/",
          payload,
          auth.requestOptions(payload.etag ? {"If-Match": payload.etag} : null)
        );
      } else {
        request = Vue.http.post(url, payload, auth.requestOptions());
      }
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async duplicate(id, payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}devices/${id}/duplicate/`; //v1
      let auth = new Auth();
      let request = Vue.http.post(
        url,
        payload,
        auth.requestOptions(payload.etag ? {"If-Match": payload.etag} : null)
      );
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async get(id, contract_id) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}devices/${id}/?format=json`; //v1
      if (contract_id) {
        url += "&contract_id=" + contract_id;
      }
      let auth = new Auth();
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            let data = response.body;
            let srv = new ConnectorService();
            data.connector = srv.adapter(data.connector);
            resolve(data);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async export(params, fname) {
    let self = this;
    fname = fname || "devices.csv";
    return new Promise((resolve) => {
      let url = `${baseurl}devices/export/?${params}`; //v1
      let auth = new Auth();
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            _vue.$utils.download(response.bodyText, "text/csv", fname);
            return;
          }
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async import(payload) {
    let self = this;
    return new Promise((resolve, reject) => {
      let url = `${baseurl}devices/import/`; //v1
      let request = null;
      let auth = new Auth();
      request = Vue.http.post(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          reject(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          reject(msg);
        }
      );
    });
  }

  async remove(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}devices/${payload.id}/?format=json`; //v1
      let auth = new Auth();
      Vue.http
        .delete(
          url,
          auth.requestOptions(payload.etag ? {"If-Match": payload.etag} : null)
        )
        .then(
          (response) => {
            resolve({});
          },
          (error) => {
            resolve(
              error.body?.detail ||
                parseMessage(error.body ?? {}) ||
                "Failed to delete"
            );
          }
        );
    });
  }

  async removeMultiple(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}devices/remove/`; //v1
      let auth = new Auth();
      Vue.http.post(url, payload, auth.requestOptions()).then(
        (response) => {
          resolve({});
        },
        (error) => {
          resolve(
            error.body?.detail ||
              parseMessage(error.body ?? {}) ||
              "Failed to delete"
          );
        }
      );
    });
  }
}
