<template>
  <div v-if="form">
    <!-- begin download -->
    <div class="checkbox">
      <label>
        <input type="checkbox" v-model="downloadEnabled" />
        {{ $t("download") }}
      </label>
      <div class="checkbox" style="margin-top: 5px">
        <label>
          <input type="checkbox" v-model="downloadCSV" />
          {{ $t("titles.download_as_CSV") }}
        </label>
      </div>
      <div class="checkbox" style="margin-bottom: 15px">
        <label>
          <input type="checkbox" v-model="downloadXLS" />
          {{ $t("titles.download_as_XLS") }}
        </label>
      </div>
      <!-- end download -->
    </div>
  </div>
</template>

<script>
export default {
  name: "DatasetDownloadForm",
  props: {
    value: {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      form: null
    };
  },
  computed: {
    downloadEnabled: {
      set(value) {
        this.form.downloadCSV = value;
        this.form.downloadXLS = value;
        this.$emit("input", this.form);
      },
      get() {
        return this.form.downloadCSV || this.form.downloadXLS;
      }
    },
    downloadCSV: {
      set(value) {
        this.form.downloadCSV = value;
        this.$emit("input", this.form);
      },
      get() {
        return this.form.downloadCSV;
      }
    },
    downloadXLS: {
      set(value) {
        this.form.downloadXLS = value;
        this.$emit("input", this.form);
      },
      get() {
        return this.form.downloadXLS;
      }
    }
  },
  created() {
    this.form = structuredClone(this.value);
  }
};
</script>

<style scoped>
div.checkbox > div.checkbox > label {
  margin-left: 20px;
}
</style>
