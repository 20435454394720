<template>
  <section v-if="panel && panelTemplateName">
    <div>
      <PanelContractBinder
        :homePanelName="homePanelName"
        v-if="this.screenPath"
      />
      <TogglePanel title="titles.item_list" persistent="toggle_equipment_list">
        <div class="">
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="showAllDevices" />
              {{ $tc("show_all", 1) }}
            </label>
          </div>
        </div>
        <div class="">
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="searchEnabled" />
              {{ $t("search_by_text") }}
            </label>
          </div>
        </div>
        <div class="" v-if="!groups.length">
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="showPagination" />
              {{ $t("pagination") }}
            </label>
          </div>
        </div>
      </TogglePanel>
    </div>
    <TogglePanel title="columns" persistent="toggle_device_panel_columns">
      <draggable
        v-model="columns"
        v-if="columns.length"
        class="column-items"
        handle=".handle"
      >
        <div
          class="column-item"
          :class="hidden.indexOf(item.id) == -1 ? 'open' : ''"
          v-for="(item, ix) in columns"
          :key="ix"
          style=""
        >
          <div>
            <div class="column-toolbar handle">
              <span
                class="clicable"
                :title="$t('aggregate')"
                @click.stop.prevent="toggleAggregation(ix)"
              >
                <i
                  :class="
                    item.aggregation.enabled
                      ? 'fa fa-folder text-primary'
                      : 'fa fa-folder-o'
                  "
                ></i>
              </span>
              <span
                class="clicable"
                @click.stop.prevent="item.enabled = !item.enabled"
              >
                <i :class="item.enabled ? 'fa fa-eye' : 'fa fa-eye-slash'"> </i>
              </span>
              <span class="clicable" @click.stop.prevent="delColumn(item.id)">
                <i class="fa fa-trash"></i>
              </span>
            </div>
            <div v-if="hidden.indexOf(item.id) == -1">
              <div class="form-group form-group-sm" style="margin-bottom:0">
                <label
                  @click.stop.prevent="toggleColumn(item.id)"
                  class="clicable open"
                >
                  <i class="fa fa-angle-down"></i>
                  <span class="text handle">
                    {{ `${$tc(item.title || "column_title", 1)}` }}
                  </span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="item.title"
                    :placeholder="$t('title')"
                    :ref="`inpTitle${item.id}`"
                  />
                  <div
                    class="input-group-addon btn"
                    @click.stop.prevent="resetTitle(item.id, ix)"
                  >
                    <i class="fa fa-close"></i>
                  </div>
                </div>
              </div>
              <div class="column-group-form">
                <ColumnGroupForm
                  v-if="groups.length"
                  :deviceSupported="deviceSupported"
                  v-model="item.aggregation"
                />
                <TogglePanel title="details">
                  <div class="form-group form-group-sm">
                    <label>{{ $t("expression") }}</label>
                    <JSONPathPicker
                      :append="false"
                      :curlyBracked="true"
                      :entry="entryTemplate"
                      entryType="item"
                      v-model="item.expression"
                    />
                  </div>
                  <div style="margin: 0 5px">
                    <TogglePanel
                      title="state_indicators"
                      :icon="{before: 'fa fa-unlink'}"
                      @open="showIcons = true"
                      @close="showIcons = false"
                    >
                      <div class="column-icon-container">
                        <div
                          class="form-group form-group-sm"
                          v-if="showIcons && iconList(item.id).length"
                          style="clear:both"
                        >
                          <div class="input-group">
                            <div class="input-group-addon">
                              {{ $tc("icon", 1) }}
                            </div>
                            <select
                              name=""
                              id=""
                              class="form-control"
                              @change="addStatusIcon(item.id, $event)"
                            >
                              <option value="" selected>{{
                                $t("select")
                              }}</option>
                              <option
                                :value="icon.name"
                                v-for="(icon, ix) in iconList(item.id)"
                                :key="ix"
                              >
                                <span :class="icon.stateList[0].class"> </span>
                                {{ iconText(icon.title) }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="column-icon-list"
                          v-if="showIcons"
                          style="clear:both;"
                        >
                          <draggable
                            :value="columnIconList(item.id)"
                            @input="columnIconList(item.id, $event)"
                            v-if="columnIconList(item.id).length"
                            handle=".handle2"
                          >
                            <div
                              v-for="(icon, ix) in columnIconList(item.id)"
                              :key="ix"
                              class="column-icon-item"
                            >
                              <div class="handle2">
                                <div>
                                  <i
                                    :class="
                                      icon.statusIcon &&
                                        icon.statusIcon.stateList[0].class
                                    "
                                  ></i>
                                </div>
                                <div
                                  style="flex-grow: 1;border-left: 1px solid light-gray;padding-left:5px;"
                                >
                                  <div
                                    style="display:flex;justify-content:space-between"
                                  >
                                    <span>
                                      {{ iconText(icon.statusIcon.title) }}
                                    </span>
                                    <span>
                                      <span
                                        class="clicable"
                                        @click.stop.prevent="
                                          delIcon(item.id, icon.name)
                                        "
                                      >
                                        <i class="fa fa-trash"></i>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="icon-toolbar">
                                    <span>{{ $t("position") }}: </span>
                                    <span
                                      class="icon-toolbar-item"
                                      v-for="align in [
                                        'left',
                                        'center',
                                        'right'
                                      ]"
                                      :key="align"
                                      :class="
                                        icon.align == align
                                          ? 'clicable active'
                                          : 'clicable'
                                      "
                                      @click.stop.prevent="icon.align = align"
                                    >
                                      <i :class="`fa fa-align-${align}`"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </div>
                    </TogglePanel>
                    <ColumnEventForm
                      :value="item"
                      @input="columns[ix] = $event"
                    />
                  </div>
                </TogglePanel>
              </div>
            </div>
            <div v-else>
              <label
                class="clicable title handle "
                @click.stop.prevent="toggleColumn(item.id)"
              >
                <i class="fa fa-angle-right"></i>
                <span class="text">
                  {{ `${$tc(item.title || "column_title", 1)}` }}
                </span>
              </label>
            </div>
          </div>
        </div>
      </draggable>
      <div>
        <div class="text-center">
          <span
            class="btn btn-xs btn-primary"
            @click.stop.prevent="addColumn"
            style="margin: -15px 0 0 10px;"
          >
            {{ $tc("rich_text.add_column", 1) }}
          </span>
        </div>
      </div>
    </TogglePanel>
    <TogglePanel
      :title="$tc('screen', 2)"
      v-if="hasOpenScreenAction"
      persistent="toggle_device_panel_screen_map"
    >
      <div style="margin: 5px 0;max-height:300px;overflow:auto">
        <GroupScreenMapForm
          v-model="groupScreenMap"
          @reset="resetScreenMap()"
        />
      </div>
    </TogglePanel>
  </section>
</template>

<script>
import SelectableObjects from "@/assets/dashboard/selectable_objects.json";
import StatusIcons from "@/assets/dashboard/status_icons.json";
import draggable from "vuedraggable";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import Panels from "@/assets/dashboard/panels.json";
import ColumnGroupForm from "@/components/control-sidebar/property-editors/column-group-form.vue";
import GroupScreenMapForm from "@/components/control-sidebar/property-editors/group-screen-map-form.vue";
import PanelContractBinder from "@/components/control-sidebar/property-editors/panel-contract-binder.vue";
import ColumnEventForm from "@/components/control-sidebar/property-editors/column-event-form.vue";
const defPanelTemplate = (tplName) => {
  let panel = (Panels || []).find(
    (panel) => panel.template.template == tplName
  );
  return (panel || {})?.template || null;
};

const defStyle = (tplName) => {
  let template = defPanelTemplate(tplName);
  return (template || {})?.options?.style || {};
};

const defColumn = (tplName) => {
  let template = defPanelTemplate(tplName);
  let column = JSON.parse(
    JSON.stringify(((template || {})?.options?.columns || [{}])[0])
  );
  column = column || {};
  column.id = "";
  column.title = "";
  column.expression = "";
  column.icons = [];
  return column;
};

const stats = () => ({
  stats: {
    connectors: 0,
    devices: 0,
    enabledConnectors: 0,
    enabledDevices: 0,
    connectedConnectors: 0,
    connectedDevices: 0,
    disconnectedConnectors: 0,
    disconnectedDevices: 0,
    alarmedConnectors: 0,
    alarmedDevices: 0
  }
});

const defConnectorItemTemplate = ($vm) => {
  // validate the reference connector
  // let properties =
  //   ($vm.$store.getters["dashboard/dashboardEquipment"] || {})?.user_data
  //     ?.extended_properties || null;
  let properties = null;
  if (properties) {
    // got from actual connector - clean them up
    for (var p in properties) {
      properties[p] = "";
    }
  } else {
    // since it does not have any property get them from contract.
    properties = {};
    ($vm.contract?.portal_data?.equipment_extended_properties || []).forEach(
      (p) => {
        properties[p.name] = "";
      }
    );
  }
  return {
    ...SelectableObjects["connector"],
    user_data: {
      extended_properties: properties
    }
  };
};

const defDeviceItemTemplate = ($vm) => {
  let device = {...SelectableObjects["device"]};
  delete device.connector;
  return {
    ...defConnectorItemTemplate($vm),
    device: device
  };
};

export {stats, defConnectorItemTemplate, defDeviceItemTemplate};

export default {
  name: "DetailFormListTable",
  props: {
    itemTemplate: {
      type: Object,
      required: false,
      default: null
    }
  },
  components: {
    draggable,
    TogglePanel,
    JSONPathPicker,
    PanelContractBinder,
    ColumnGroupForm,
    GroupScreenMapForm,
    ColumnEventForm
  },
  data() {
    return {
      panelTemplateName: "",
      showNew: true,
      hidden: [],
      statusIcon: [],
      showIcons: false,
      busy: false,
      groupList: [],
      panel: null
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    nextId() {
      return this?.columns?.length
        ? this.columns
            .map(({id}) => parseInt(id || 0))
            .reduce(function(a, b) {
              return Math.max(a, b);
            }) + 1
        : 1;
    },
    content: {
      set(value) {
        if (this?.panel?.options) {
          let panel = this.panel;
          panel.options.content = value;
          this.$store.dispatch("dashboard/saveDraftPanel", {
            screenId: this.screenId,
            panel: panel
          });
        }
      },
      get() {
        return this?.panel?.options?.content || "";
      }
    },
    contentStyle: {
      set(value) {
        let panel = this.panel;
        if (panel && panel?.options) {
          this.$set(panel.options, "style", value);
          this.$store.dispatch("dashboard/saveDraftPanel", {
            screenId: this.screenId,
            panel: panel
          });
        }
      },
      get() {
        let panel = this.panel;
        if (panel && panel?.options) {
          return (
            panel?.options?.style ||
            JSON.parse(JSON.stringify(defStyle(this.panelTemplateName)))
          );
        }
        return null;
      }
    },
    homePanelName() {
      return /^connector/i.test(this.panelTemplateName)
        ? "EquipmentSearchCard#table"
        : "EquipmentSearchTable";
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    panelOptions() {
      return this?.panel?.options || null;
    },
    columns: {
      set(values) {
        this.$set(
          this.panelOptions || {},
          "columns",
          JSON.parse(JSON.stringify(values || []))
        );
      },
      get() {
        return this?.panelOptions?.columns || [];
      }
    },
    entryTemplate() {
      return this.itemTemplate || /^connector/i.test(this.panelTemplateName)
        ? defConnectorItemTemplate(this)
        : defDeviceItemTemplate(this);
    },
    searchEnabled: {
      set(value) {
        this.$set(this.panelOptions || {}, "searchEnabled", value);
      },
      get() {
        return this?.panelOptions?.searchEnabled || false;
      }
    },
    showPagination: {
      set(value) {
        this.$set(this.panelOptions || {}, "showPagination", value);
      },
      get() {
        return this?.panelOptions?.showPagination || false;
      }
    },
    showAllDevices: {
      set(value) {
        this.$set(this.panelOptions || {}, "showAllDevices", value);
      },
      get() {
        return this?.panelOptions?.showAllDevices || false;
      }
    },
    screenPath() {
      return (
        (this.$store.getters["dashboard/screen"](this.screenId) || {})?.path ||
        null
      );
    },
    groups() {
      return (
        this.columns.filter((f) => (f.aggregation.enabled ? true : false)) || []
      );
    },
    groupScreenMap: {
      set(value) {
        this.$set(
          this.panelOptions,
          "groupScreenMap",
          JSON.parse(JSON.stringify(value || []))
        );
      },
      get() {
        let lst = (this?.groupList || []).map((id) => ({
          id: id,
          screenId:
            (
              (this?.panelOptions?.groupScreenMap || []).find(
                (item) => item.id == id
              ) || {}
            )?.screenId || ""
        }));
        return lst;
      }
    },
    hasOpenScreenAction() {
      if (!(this.groups || []).length || !(this.groupList || []).length)
        return false;
      //this.columns[2].aggregation.on.click.actions[0].type
      for (var c in this?.columns || []) {
        let actions = this.columns[c]?.aggregation?.on?.click?.actions || [];
        for (var a in actions) {
          if (actions[a].type.startsWith("screen:")) {
            return true;
          }
        }
      }
      return false;
    },
    deviceSupported() {
      return this?.entryTemplate?.device ? true : false;
    },
    enabledStatusIcons() {
      return (StatusIcons || [])
        .filter(({name}) => {
          return this.deviceSupported || !/device/i.test(name);
        })
        .map((i) => ({...i}));
    }
  },
  watch: {
    panelOptions: {
      handler() {
        const draftPanel = this.$store.getters["dashboard/currentDraftPanel"];
        this.$store.dispatch("dashboard/saveDraftPanel", {
          panel: {
            ...draftPanel,
            ...this.panel,
            options: {
              ...draftPanel.options,
              ...this.panel.options
            }
          },
          screenId: this.screenId
        });
      },
      deep: true
    },
    groups(n) {
      if (n && n?.length) {
        this.showPagination = false;
      } else {
        this.$set(this, "groupList", []);
      }
    }
  },
  methods: {
    onPanelEvent($event) {
      switch ($event.action) {
        case "group_list:updated": {
          // console.log($event?.details?.group_list);
          this.$set(this, "groupList", $event?.details?.group_list || []);
          break;
        }
      }
    },
    resetScreenMap() {
      let lst = JSON.parse(JSON.stringify(this.groupScreenMap));
      if (lst.length) {
        lst.forEach((i) => {
          i.screenId = "";
        });
        this.groupScreenMap = lst;
      }
    },
    toggleColumn(id) {
      let lst = this.hidden;
      let pos = lst.indexOf(id);
      if (pos >= 0) {
        lst.splice(pos, 1);
      } else {
        lst.push(id);
      }
      this.$set(this, "hidden", lst);
    },
    toggleAggregation(ix) {
      let column = this.columns[ix];
      column.aggregation.enabled = !column.aggregation.enabled;
      if (ix == 0 && column.aggregation.enabled) {
        column.aggregation.expression = "${item.name}";
      }
    },
    setFocus(id) {
      this.$nextTick(() => {
        let ref = `inpTitle${id}`;
        if ((this?.$refs || {})[ref].length) {
          this?.$refs[ref][0].focus();
        }
      });
    },
    addColumn() {
      let id = this.nextId;
      this.columns.push({
        ...defColumn(this.panelTemplateName),
        id: id
      });
      this.setFocus(id);
    },
    delColumn(qid) {
      this.columns = this.columns.filter(({id}) => id != qid);
      if (this.hidden.indexOf(qid) >= 0) {
        this.hidden.splice(this.hidden.indexOf(qid), 1);
      }
    },
    resetTitle(qid) {
      this.columns.find(({id}) => id == qid).title = "";
      this.setFocus(qid);
    },
    iconList(qid) {
      let icons = [];
      let column = this.columns.find(({id}) => id == qid);
      if (column) {
        icons = [...this.enabledStatusIcons].filter(({name}) => {
          return !(column?.icons || []).find((icon) => icon.name == name);
        });
      }
      return icons;
    },
    columnIconList(qid, items) {
      let column = this.columns.find(({id}) => id == qid);
      if (items) {
        column.icons = items;
      } else {
        return column.icons;
      }
    },
    addStatusIcon(qid, $event) {
      let selected = $event.target.value;
      let icon = this.iconList(qid).find(({name}) => name == selected);
      let column = this.columns.find(({id}) => id == qid);
      if (icon && column) {
        let icons = JSON.parse(JSON.stringify(column.icons));
        icons.push({
          name: icon.name,
          align: "left",
          statusIcon: JSON.parse(JSON.stringify(icon))
        });
        this.$set(column, "icons", icons);
        this.$nextTick(() => {
          $event.target.value = "";
          this.showIcons = true;
        });
      }
    },
    delIcon(qid, name) {
      let column = this.columns.find(({id}) => id == qid);
      if (column) {
        let pos = column.icons.findIndex((icon) => icon.name == name);
        if (pos >= 0) {
          let icons = JSON.parse(JSON.stringify(column.icons));
          icons.splice(pos, 1);
          this.$set(column, "icons", icons);
          if (icons.length == 0) {
          }
        }
      }
    },
    iconText(title) {
      let v = (title + "_").split("_");
      return this.$utils.proper(`${this.$tc(v[0], 1)} ${this.$tc(v[1], 1)}`);
    }
  },
  mounted() {
    if (this.columns.length) {
      this.hidden = this.columns.map(({id}) => id);
    }
  },
  created() {
    this.panel = structuredClone(
      this.$store.getters["dashboard/currentDraftPanel"] || null
    );
    this.panelTemplateName = this?.panel?.template || "";
    this.$root.$on("list_table_panel:event", this.onPanelEvent);
  },
  beforeDestroy() {
    this.$root.$off("list_table_panel:event", this.onPanelEvent);
  }
};
</script>

<style scoped>
div.title {
  color: #5e5e5e;
  font-size: 12pt;
  font-weight: 600;
  padding: 5px;
  background-color: whitesmoke;
}

label > span.text {
  padding: 0 3px;
}
.clicable:hover {
  background-color: transparent;
  cursor: pointer;
  opacity: 0.8;
}

.column-items {
  padding: 5px;
}

.column-item {
  clear: both;
  position: relative;
  background-color: #fff;
  padding: 5px 0;
  border-bottom: 1px solid #dcdcdc;
}

.column-item.open {
  /* background-color: whitesmoke; */
  padding-bottom: 50px;
}

label.open {
  color: #337ab7;
}

.column-toolbar {
  position: absolute;
  right: 2px;
  top: 4px;
  padding: 2px;
}

.column-toolbar > span {
  margin: 0 5px;
}
.column-toolbar > span.disabled {
  color: #999;
}
.column-toolbar > span.clicable:hover {
  color: #f39c12;
}

.column-icon-container {
  min-height: 30px;
  border-radius: 5px;
  /* padding: 0 5px 0 5px; */
}

.column-icon-list {
  margin-top: -10px;
  padding: 0 5px;
}

.column-icon-list::after {
  clear: both;
}

.column-icon-item {
  padding: 5px 0 10px 0;
  clear: both;
}

.icon-toolbar > span.icon-toolbar-item {
  margin: 4px;
}

.icon-toolbar > span.icon-toolbar-item.active {
  color: #3c8dbc;
}

.icon-toolbar > span.icon-toolbar-item:hover {
  color: #f39c12;
}

.default-panel-controls {
  padding: 5px 0;
  font-size: 10pt;
  white-space: nowrap;
  border-width: 1px 0;
  border-color: rgb(189, 189, 189);
  border-style: solid;
}

.group-tree-info {
  padding: 3px 10px;
  margin: 0px -15px 10px -15px;
  background-color: #e3e3e3;
}

span[disabled]:hover {
  cursor: not-allowed;
}

.column-group-form {
  padding: 0 10px;
  border: 1px solid lightgray;
  border-radius: 0 0 4px 4px;
  margin: -1px 0 -10px 0;
  position: relative;
  /* overflow: hidden; */
}

.handle2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
}

.handle2:hover {
  cursor: grab;
  background-color: whitesmoke;
}
</style>
