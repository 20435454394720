<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1">
      <ControlBaseProperties
        v-model="control"
        :hasExpression="true"
        :allowedTypes="['bool', 'float', 'int', 'string']"
      />
      <ControlSpecificPanel :control="control">
        <DataFormatInput v-model="control.format" :control="control" />
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import {isEqual} from "lodash";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import DataFormatInput from "@/components/synoptic/property-editor/controls/data-format-input.vue";
export default {
  name: "FormattedDisplayValue",
  components: {
    ControlBaseProperties,
    ControlSpecificPanel,
    DataFormatInput
  },
  extends: BaseControl,
  data() {
    return {
      control: {}
    };
  },
  computed: {
    expression: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "expression", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.expression || "";
      }
    }
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl({
          id: val.id,
          control: this.control,
          noMerge: true
        });
      }
    },
    controls: {
      deep: true,
      handler(val) {
        let n = JSON.parse(JSON.stringify(val[0]));
        let o = JSON.parse(JSON.stringify(this.control));
        if (!isEqual(n, o)) {
          this.control = JSON.parse(JSON.stringify(n));
        }
      }
    }
  },
  methods: {
    updateControl(value) {
      this.$store.dispatch("synoptic/updateControl", value);
    }
  },
  mounted() {
    this.control = JSON.parse(JSON.stringify(this.controls[0]));
  }
};
</script>
