// prettier-ignore
export default {
  pt: {
    titles: {
      general: "Geral",
      area: "Área|Áreas",
      control: "Controle|Controles",
      realtime: "Tempo Real",
      disconnection:"Desconexão",
      legend:"Legenda|Legendas",
      tooltip:"Tooltip|Tooltips",
      data_serie:"Série de dados|Séries de dados",
      y_axis:"Eixo Y|Eixos Y",
      x_axis:"Eixo X|Eixos X",
      heading:"Cabecalho|Cabecalhos",
      axis_type:"Tipo|Tipos",
      title:"Título|Títulos",
      label:"Rótulo|Rótulos",
      scale:"Escala|Escalas"
    },
    hints: {
      general: "Configurações diversas",
      area: "Define os espaçamentos do \ngráfico dentro do painel.",
      control:"Controles para auxiliar a \nvisualização do gráfico,\ncomo barra de ferramentas e zoom",
      realtime: "Configurações para gráfico \nque adiciona novos pontos a\n medida que novas amostras são carregadas",
      disconnection:"Permite visualizar no gráfico\nos períodos em que o Conector associado\naos Dados ficou desconectado",
      legend:"Configurações especificas de legenda",
      tooltip:"Exibe detalhes sobre os pontos plotados",
      data_serie:"Define os dados que serão apresentados nas séries",
      y_axis:"Configurações de apresentação dos valores \ndos Dados no Eixo Y (linha vertical) do gráfico",
      x_axis:"Configurações de apresentação dos valores \ndos Dados no Eixo X (linha horizontal) do gráfico",
      heading:"Definições do título apresentado no cabeçalho do gráfico",
      axis_type:"Definições do tipo de escala\nTime: para graficos temporais,\ncategoria: grupos de valores (mais utilizado por consultas customizadas)\nvalor: usará na escala, o valor das amostras uma das séries de dados ",
      title:"Título",
      label: "Configuraçãos de formato e apresentação dos rótulos deste elemento.",
      scale:"Configuração dos limites, intervalos e formato da escala"
    },
  },
  en: {
    titles: {
      general: "General",
      area:"Area|Areas",
      control: "Control|Controls",
      realtime: "Realtime",
      disconnection:"Disconnection",
      legend:"Legend|Legends",
      tooltip:"Tooltip|Tooltips",
      data_serie:"Data serie|Data serie",
      y_axis:"Y Axis",
      x_axis:"X Axis",
      heading:"Header|Headers",
      axis_type:"Type|Types",
      title:"Title|Titles",
      label:"Label|Labels",
      scale:"Scale|Scales"
    },
    hints: {
      general: "Various configurations",
      area: "Sets the chart spacing within the panel.",
      control:"Controls to help you view the graph, such as toolbar and zoom",
      realtime: "Chart that adds new points as new samples are loaded",
      disconnection:"Allows you to view the graph\nin the periods in which the\nConnector associated with the Data was disconnected",
      legend:"Specific subtitle settings",
      tooltip:"Displays details about the plotted points",
      data_serie:"Defines the Data that will be presented in the series",
      y_axis:"Settings for displaying data values on the Y-axis (vertical line) of the chart",
      x_axis:"Settings for displaying Data values on the X-Axis (horizontal line) of the chart",
      heading:"Definitions of the title displayed in the chart header",
      axis_type:"Scale type definitions\nTime: for time graphs,\ncategory: groups of values (most used by custom queries)\nvalue: will use in the scale, the value of the samples of one of the data series",
      title:"Title",
      label: "Format and presentation settings for this element&#39;s labels",
      scale:"Setting limits, ranges and scale format"
    },
  },
};
