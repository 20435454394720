<template>
  <div>
    <label>
      {{ $tc("experimental_feature", 2) }}
    </label>
    <Checkbox
      v-for="feature in Object.keys(featureConfig)"
      v-model="featureConfig[feature].enabled"
      :id="feature"
      :key="feature"
    >
      {{ $tc(`features.${feature}.name`) }}
      <Tooltip :title="$t(`features.${feature}.description`)" />
      <a
        v-if="featureConfig[feature].documentationURL"
        :href="featureConfig[feature].documentationURL"
        target="_blank"
        :title="$t('open_documentation')"
        ><i class="fa fa-book"></i
      ></a>
    </Checkbox>
  </div>
</template>

<script>
import Checkbox from "@/components/base/checkbox";
import Tooltip from "@/components/tooltip";
import isEqual from "lodash/isEqual";

export default {
  name: "FeatureConfig",
  components: { Checkbox, Tooltip },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      featureConfig: {}
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.featureConfig = JSON.parse(JSON.stringify(val));
      }
    },
    featureConfig: {
      deep: true,
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit("input", val);
        }
      }
    }
  }
};
</script>

<style scoped>
a {
  color: #5e5e5e;
  margin-left: 10px;
}

a:hover {
  color: #3c8dbc;
}

.skin-dark a {
  color: #b8c7ce;
}

.skin-dark a:hover {
  color: #fff;
}
</style>
