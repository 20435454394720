<template>
  <TogglePanel title="actions" :icon="{before: 'fa fa-flash'}">
    <ControlEventActionForm
      style="width:100%;clear:both;"
      v-for="(action, ixAction) in actions"
      eventName="click"
      :key="ixAction"
      :action="action"
      :index="ixAction"
      @delAction="onDelAction($event)"
      @change="onActionChange($event)"
    />
    <ControlActionSelector
      style="margin-top:10px;"
      eventName="click"
      :exclude="excludedActions"
      :control="entry"
      @addAction="addAction($event)"
    />
  </TogglePanel>
</template>

<script>
import {isEqual} from "lodash";
import Actions from "@/assets/dashboard/actions.json";
import Events from "@/assets/dashboard/events.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlActionSelector from "@/components/editor/control-action-selector.vue";
import ControlEventActionForm from "@/components/editor/control-event-action-form.vue";
export default {
  name: "ColumnEventForm",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  components: {
    TogglePanel,
    ControlActionSelector,
    ControlEventActionForm
  },
  data: () => ({
    entry: null
  }),
  computed: {
    excludedActions() {
      let actions = Actions.filter(
        ({type}) => !/^(page|screen)/.test(type)
      ).map(({type}) => type);
      (this?.entry?.on?.click?.actions || []).forEach(({type}) => {
        actions.push(type);
      });
      return actions;
    },
    actions() {
      return this?.entry?.on?.click?.actions || [];
    }
  },
  watch: {
    value: {
      handler(n) {
        if (n) {
          if (isEqual(n, this.entry)) return;
          this.entry = n;
        }
      },
      immediate: true
    },
    entry: {
      handler(n) {
        if (n) {
          if (isEqual(n, this.value)) return;
          this.$emit("input", n);
        }
      }
    }
  },
  methods: {
    addAction(action) {
      let entry = JSON.parse(JSON.stringify(this.entry));
      entry.on = entry.on || {};
      entry.on.click = entry.on.click || Events["click"];
      entry.on.click.actions.push(action);
      this.$set(this, "entry", entry);
    },
    onActionChange(ixAction) {},
    onDelAction(ix) {
      let entry = JSON.parse(JSON.stringify(this.entry));
      entry.on = entry.on || {};
      entry.on.click = entry.on.click || Events["click"];
      if (ix < entry.on.click.actions.length) {
        entry.on.click.actions.splice(ix, 1);
        this.$set(this, "entry", entry);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
