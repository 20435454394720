<template>
  <div v-if="protocol">
    <div class="row">
      <FormCopyInput
        ref="inpBroker"
        class="col-md-6"
        :label="$t('mqtt_ip')"
        :value="protocol.access_host"
        disabled
      >
        <template #label>
          <label for="">
            {{ $t("ip_network_configuration") }}
          </label>
        </template>
      </FormCopyInput>
      <div class="col-md-6">
        <MqttAccessTokenSelector :connector="connector" label="username" />
      </div>
    </div>
    <div class="row">
      <FormCopyInput
        class="col-md-6 col-sm-4"
        :label="$t('mqtt_url')"
        :value="protocol.web_socket_url"
        disabled
      />
      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-addon">
              {{ $t("identifier") }} (Client ID {{ $t("and") }}
              {{ $t(`topic`) }}
              )
              <ToolTip
                :title="
                  `${$parent.$t('hints.identifier')}.<br/>${$t(
                    'hints.client_id'
                  )}`
                "
              />
            </div>
            <input
              type="text"
              class="form-control text-center"
              disabled
              :value="connector.mqtt_topic_prefix"
            />
            <div
              class="input-group-addon btn"
              v-bind:title="$t('copy', {item: $t('topic')})"
              v-on:click.prevent.stop="
                copyToClipboard(connector.mqtt_topic_prefix)
              "
            >
              <i class="fa fa-copy"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <FormCopyInput
        class="col-md-3 col-sm-6"
        :label="`${$t('port')} MQTT`"
        :value="protocol.connector_access_port"
        :hint="val('hint_connector_access_port')"
        disabled
      />
      <FormCopyInput
        class="col-md-3 col-sm-6"
        :label="$t('secure_port')"
        :value="protocol.connector_access_secure_port"
        :hint="val('hint_connector_access_secure_port')"
        disabled
      />
      <div class="col-md-6 col-sm-12 text-center">
        <MqttConnectorTools :connector="connector" :protocol="protocol" />
      </div>
    </div>
  </div>
</template>

<script>
import FormCopyInput from "@/components/registration/form-copy-input.vue";
import MqttAccessTokenSelector from "@/components/registration/mqtt-access-token-selector.vue";
import MqttConnectorTools from "@/components/registration/mqtt-connector-tools.vue";
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "FormEquipmentMQTT",
  props: {
    protocol: {
      type: Object,
      required: false,
      default: () => null
    },
    connector: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  components: {
    FormCopyInput,
    MqttAccessTokenSelector,
    MqttConnectorTools,
    ToolTip
  },
  data() {
    return {};
  },
  methods: {
    val(attr, dft) {
      return (this?.protocol || {})[attr] || dft || "";
    },
    copyToClipboard(text) {
      this.$utils.copyToClipboard(this, text);
    }
  }
};
</script>

<style scoped>
.form-control[disabled] {
  background-color: white;
  opacity: 0.9;
  font-weight: bold;
}
.skin-dark .form-control[disabled] {
  background-color: #29373d;
}
.action-bar {
  min-height: 18px;
}
.action-bar > span.btn {
  margin: 0 5px;
  padding: 6px;
  border: 0;
}

.action-bar > span.btn:hover {
  color: rgb(68 151 217);
}
.no-wrap {
  white-space: nowrap;
}
</style>
