<template>
  <section>
    <div class="form-group form-group-sm" v-if="adding">
      <label for="namedQueryInput">
        {{ $tc("titles.named_query", 1) }} ({{ $tc("new", 2) }})
      </label>
      <div class="input-group">
        <input
          type="text"
          v-model="newName"
          :placeholder="$t('name')"
          id="namedQueryInput"
          class="form-control"
          ref="namedQueryInput"
        />
        <div
          class="input-group-addon btn"
          @click="adding = false"
          :title="$t('remove')"
        >
          <i class="fa fa-undo"></i>
        </div>
        <div
          class="input-group-addon btn"
          @click="toggleCreate"
          :disabled="!canCreate"
          :title="$t('create')"
        >
          <i class="fa fa-check text-primary"></i>
        </div>
      </div>
    </div>
    <div class="form-group form-group-sm" v-else-if="!currentSelection">
      <label for="namedQueryInput">
        {{ $tc("titles.named_query", 2) }}
      </label>
      <div class="input-group">
        <select
          id="namedQueryInput"
          v-model="currentSelection"
          class="form-control"
        >
          <option value="">{{ $tc("none", 1) }}</option>
          <option :value="item.name" v-for="(item, ix) in list" :key="ix">
            {{ item.name }}
          </option>
        </select>
        <div
          class="input-group-addon btn"
          @click="delItem"
          :title="$t('remove')"
          v-if="currentSelection"
        >
          <i class="fa fa-trash text-danger"></i>
        </div>
        <div
          class="input-group-addon btn"
          @click="toggleCreate"
          :title="$t('create')"
        >
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
    <NamedQueryForm
      v-if="currentSelection && !adding"
      :queryName="currentSelection"
      :key="currentSelection"
      @cancel="currentSelection = ''"
      @save="saveItem"
      @remove="delItem"
    />
  </section>
</template>

<script>
import NamedQueryForm from "@/components/control-sidebar/property-editors/named-query-form.vue";
export default {
  name: "NamedQueriesForm",
  components: {
    NamedQueryForm
  },
  data() {
    return {
      currentSelection: "",
      adding: false,
      newNamedQueryName: ""
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    template() {
      return this?.draft?.template;
    },
    canCreate() {
      let newName = this.$utils.trim(this.newName);
      return (
        newName &&
        !this.list.some(({name}) => this.$utils.trim(name) == newName)
      );
    },
    list() {
      return this.template?.namedQueries ?? [];
    },
    newName: {
      set(value) {
        this.newNamedQueryName = this.$utils.asPropertyName(value);
      },
      get() {
        return this.newNamedQueryName;
      }
    }
  },
  watch: {},
  methods: {
    delItem(itemName) {
      let ix = this.list.findIndex(({name}) => name == itemName);
      if (ix == -1) return;
      let template = JSON.parse(JSON.stringify(this.template));
      let list = template?.namedQueries ?? [];
      list.splice(ix, 1);
      this.saveDraft(template).then(() => {
        this.$root.$emit("namedQuery:removed", itemName);
      });
      this.$nextTick(() => {
        ix--;
        this.currentSelection = ix > -1 ? this.list[ix].name : "";
      });
    },
    toggleCreate() {
      if (this.adding && !this.canCreate) {
        if (this.$refs.namedQueryInput) this.$refs.namedQueryInput.focus();
        return;
      }
      this.adding = !this.adding;
      if (this.adding) {
        this.$nextTick(() => {
          if (this.$refs.namedQueryInput) this.$refs.namedQueryInput.focus();
        });
      } else {
        if (this.canCreate) {
          this.currentSelection = this.newName;
          this.newName = "";
        }
      }
    },
    saveDraft(template) {
      return this.$store.dispatch("dashboard/saveDraft", {
        screenId: this.draft.screenId,
        template: template
      });
    },
    saveItem(payload) {
      let template = JSON.parse(JSON.stringify(this.template));
      let list = template?.namedQueries ?? [];
      let entry = JSON.parse(JSON.stringify(payload.entry));
      let ix = this.list.findIndex(({name}) => name == payload.name);
      if (ix == -1) list.push(entry);
      else list[ix] = entry;
      template.namedQueries = list;
      this.currentSelection = entry.name;
      this.saveDraft(template).then(() => {
        if (payload.name != entry.name) {
          // it has been renamed, so unregistry the previous one
          this.$root.$emit("namedQuery:removed", payload.name);
        }
        this.$root.$emit("namedQuery:changed", entry);
      });
    }
  }
};
</script>

<style scoped>
</style>