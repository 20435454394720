export default {
  en: {
    basic_equipment_info: 'Basic equipment info',
    basic_equipment_alarm: 'Basic equipment alarm',
    maintenance: 'Maintenance',
    synoptic: 'Synoptic',
    equipment_history: 'Equipment history',
    alarms: 'Alarms',
    history_panel: "History Panel",
    blank_panel: "Blank panel",
    devices: "Devices",
    event_viewer: "Event viewer",
    notification: "Notification editor",
    equipment_runtime_data_editor: "Data editor",
    equipment_toolbar_panel: "Toolbar",
    history_chart_panel: "History chart",
    chart_panel: "Chart Panel",
    dashboard_table_panel: "Table",
    rich_text_panel: "Rich text panel",
    image_panel: "Image panel",
    equipment_status_panel: "Equipment status panel",
    custom_device_list: "Device table",
    custom_connector_list: "Connector table",
    equipment_card_panel: "Connector card list - cards",
    equipment_search_panel: "Equipment search panel",
    dashboard_launcher_panel: "Dashboard launcher",
    title: "Panels",
    custom_panel: "Custom Panel",
    category: {
      real_time: "Real time",
      history: "History",
      customization: "Customization",
      utils: "Utilities",
      report: "Report"
    }
  },
  pt: {
    basic_equipment_info: 'Status de Conector',
    basic_equipment_alarm: 'Alarme do conector',
    maintenance: 'Manutenção',
    synoptic: 'Sinóptico',
    equipment_history: 'Histórico de Dados',
    alarms: 'Alarmes',
    history_panel: "Painel histórico",
    blank_panel: "Vazio",
    devices: "Status de Conector/ Dispositivos",
    event_viewer: "Histórico de Eventos",
    notification: "Editor de notificações",
    equipment_runtime_data_editor: "Dados",
    equipment_toolbar_panel: "Barra de Ferramentas",
    history_chart_panel: "Gráfico histórico",
    chart_panel: "Gráfico",
    dashboard_table_panel: "Tabela",
    rich_text_panel: "Texto",
    image_panel: "Imagem",
    equipment_status_panel: "Status do conector",
    custom_device_list: "Tabela de Dispositivos",
    custom_connector_list: "Tabela de Conectores",
    equipment_card_panel: "Cartões de Conectores",
    equipment_search_panel: "Estatísticas Conectores/ Dispositivos",
    dashboard_launcher_panel: "Lançador de telas",
    title: "Painéis",
    custom_panel: "Customizado via IFrame",
    category: {
      real_time: "Tempo real",
      history: "Histórico",
      customization: "Customização",
      utils: "Utilitários",
      report: "Relatório"
    }
  }
}