<template>
  <div>
    <div class="data-range-container">
      <input
        id="data-range"
        class="slider"
        type="range"
        :min="dataRange.min"
        :max="dataRange.max"
        step="1"
        v-model="selected"
        data-testid="data-range"
      />
      <ul id="data-range-list" class="data-range-list ">
        <template v-for="(item, ix) in items">
          <li
            v-if="ix >= dataRange.min && ix <= dataRange.max"
            class="clicable"
            :class="{
              active: ix == selected,
              shift: ix == shifting,
              toRight: ix == dataRange.min && ix == shifting,
              toLeft: ix == dataRange.max && ix == shifting
            }"
            :key="ix"
            @click="onClick(ix)"
          >
            <template v-if="ix == dataRange.min">
              <i class="fa fa-angle-double-left" style="margin-left:-15px"></i>
              <span>
                {{ label(item) }}
              </span>
            </template>
            <template v-else-if="ix == dataRange.max">
              <span>
                {{ label(item) }}
              </span>
              <i
                class="fa fa-angle-double-right"
                style="margin-right:-15px"
              ></i>
            </template>
            <template v-else>
              <span class="shift">
                {{ label(item) }}
              </span>
            </template>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserPlanDataSlider",
  components: {
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => ({
    dataRangeIni: 0,
    shifting: -1
  }),
  computed: {
    nOptions() {
      return (this?.items || []).length;
    },
    nSteps() {
      return this.nOptions > 5 ? 5 : this.nOptions;
    },
    dataRange() {
      return {
        min: this.dataRangeIni,
        max: this.dataRangeIni + this.nSteps - 1
      };
    },
    scaleFontSize() {
      let pos = Math.floor(this.dataRange.min + this.nSteps / 2);
      return this?.items[pos] > 500 ? "85%" : "90%";
    },
    selected: {
      set(value) {
        this.$emit("input", parseInt(value));
      },
      get() {
        return this.value;
      }
    }
  },
  watch: {
    value(n) {
      if (n < this.dataRangeIni) {
        this.dataRangeIni = n;
      } else if (n > this.dataRangeIni + this.nSteps - 1) {
        this.dataRangeIni = n - this.nSteps + 1;
      }
    }
  },
  methods: {
    label(value) {
      // let fmt =
      //   value % 1000
      //     ? this.$utils.sprintf("%.1fK", value / 1000)
      //     : this.$utils.sprintf("%dK", Math.floor(value / 1000));
      // return value >= 1000 ? fmt : value;
      return value;
    },
    onClick(ix) {
      if (ix != this.selected) {
        if (ix == this.dataRange.min) {
          this.shifting = ix;
        } else if (ix == this.dataRange.max) {
          this.shifting = ix;
        }
        this.selected = ix;
      }
      setTimeout(
        () => {
          this.shifting = -1;
          if (ix == this.dataRange.min) {
            this.step(-1);
          } else if (ix == this.dataRange.max) {
            this.step(1);
          }
        },
        400,
        this
      );
    },
    step(n) {
      n = parseInt(n);
      let ini = this.dataRangeIni + n;
      if (ini < 0) {
        ini = 0;
      } else if (ini + this.nSteps > this.nOptions) {
        ini = this.nOptions - this.nSteps;
      }
      this.dataRangeIni = ini;
    }
  }
};
</script>

<style scoped>
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.data-range-container {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: rgb(189 189 189 / 69%);
  border-radius: 5px;
  border: 1px solid #d6d6d685;
}

input.slider:focus {
  outline: none;
}

.data-range {
  appearance: none;
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: rgba(247, 247, 247, 0.69);
  border-radius: 5px;
  border: 1px solid #d6d6d685;
}

.data-range-list {
  width: 110%;
  margin: 0 -5%;
  text-align: center;
  padding: 0;
  list-style: none;
  display: inline-flex;
  justify-content: space-between;
  /* font-size: 1.1rem; */
}

.data-range-list > li.active {
  color: #4a75e3;
  font-weight: bold;
}

.skin-dark .data-range-list > li {
  color: #b8c7ce;
  opacity: 0.5;
}

.skin-dark .data-range-list > li.active {
  opacity: 1;
}

.data-range-list > li {
  display: inline-block;
  margin: 0;
  color: #6f6f6f;
  flex: 1;
  text-align: center;
  white-space: nowrap;
}

.shift {
  transform: translateX(0);
  transition: transform 600ms cubic-bezier(0.075, 0.82, 0.125, 1);
}

.toRight {
  transform: translateX(30px);
}

.toLeft {
  transform: translateX(-30px);
}

li.shift > i {
  opacity: 1;
  transition: opacity 100ms ease-out;
}

li.toRight > i {
  opacity: 0;
}

li.toLeft > i {
  opacity: 0;
}
</style>
