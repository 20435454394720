<template>
  <a
    :class="{'has-portal-icon': isPortalIcon}"
    :href="option.href"
    :target="hrefTarget"
    @click="onClick($event)"
    @mouseenter.stop.prevent="onMouseEnter"
    @mouseleave.stop.prevent="onMouseLeave"
  >
    <Icon :name="option.icon" />
    <span>{{ $t(option.label) }}</span>
    <span
      class="pull-right-container"
      :class="{rotated: showOptions}"
      v-if="multilevel && (hoverMenu || menuExpanded || subtree)"
      @click.prevent.stop="toggleOptions"
    >
      <i class="fa fa-angle-right pull-right"></i>
    </span>
  </a>
</template>

<script>
// TODO after aceptance tests, move import to a shared place and load functions dynamicaly

import {ExtFunctions} from "@/assets/ext-functions.js";
import Icon from "@/components/icons/icon";

export default {
  name: "MenuItemLink",
  components: {
    Icon
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    multilevel: {
      // controls if it will handle hide/show of other level
      type: Boolean,
      required: false,
      default: false
    },
    subtree: {
      // controls if it's inside another level to decide if arrow icon is shown
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      menuExpanded: false,
      showOptions: false
    };
  },
  methods: {
    onMouseEnter() {
      if (!this.hoverMenu) return;
      this.$emit('mouseenter');
      this.showOptions = true;
    },
    onMouseLeave() {
      if (!this.hoverMenu) return;
      this.$emit('mouseleave');
      this.showOptions = false;
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
      this.$emit("toggleTreeview");
    },
    onClick($event) {
      if (this.isExtFunction) {
        $event.stopPropagation();
        $event.preventDefault();
        let fn = ExtFunctions[this.option.name];
        if (fn) {
          (async (f) => {
            try {
              let params = this.option.href.split(":");
              params.splice(0, 1);
              f(this, params.join(":"));
            } catch (e) {
              console.log(e);
            }
          })(fn);
        }
      }
    }
  },
  computed: {
    hrefTarget() {
      return (
        this.option.hrefTarget ||
        (this.isScript || this.isExtFunction ? "_self" : "_blank")
      );
    },
    isExtFunction() {
      return this.option.href.indexOf("function") === 0;
    },
    isScript() {
      return this.option.href.indexOf("javascript:") === 0;
    },
    isPortalIcon() {
      return this.option.icon.startsWith("portal-icon");
    },
    user() {
      return this.$store.getters["user/loggedUser"];
    },
    hoverMenu() {
      return this.user?.user_profile?.portal_data?.menuBehavior == "hover";
    }
  },
  mounted() {
    if (!this.subtree)
      $(document)
        .on("expanded.pushMenu", () => (this.menuExpanded = true))
        .on("collapsed.pushMenu", () => (this.menuExpanded = false));
  }
};
</script>
<style scoped>
/* .pull-right-container {
  transition: transform 500ms;
  transform-origin: 42% center;
}

.pull-right-container.rotated {
  transform: rotate(-90deg);
}

.has-portal-icon {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  width: 100%;
} */

.pull-right-container {
  display: inline-block;
  padding: 0 5px 0 10px;
}
.pull-right-container > i {
  transition: transform 300ms;
  transform-origin: 42% center;
  font-weight: 600;
}

.pull-right-container:hover {
  color: black;
}

.pull-right-container.rotated > i {
  transform: rotate(90deg);
}

.has-portal-icon {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}
</style>
