<template>
  <TogglePanel :title="$tc('group', 1)">
    <div>
      <div class="form-group form-group-sm">
        <label>{{ $t("expression") }}</label>
        <JSONPathPicker
          :append="true"
          :curlyBracked="true"
          :entry="expressionTemplate"
          entryType="item"
          v-model="expression"
        />
      </div>
      <template>
        <div style="padding: 0 5px">
          <ControlStyleProperties v-model="aggregationStyle" />
          <TogglePanel
            :title="$tc('icon', 2)"
            :icon="{before: 'fa fa-info-circle'}"
            v-if="aggregation.enabled"
            style="margin-top:10px;"
          >
            <div class="form-group form-group-sm" style="margin-bottom:0;">
              <div class="input-group">
                <div class="form-control text-center text-bold">
                  {{ $t("closed") }}
                </div>
                <IconLibrary
                  v-model="iconCloseClass"
                  :buttonAttrs="{title: iconCloseClass}"
                  :panelOpen.sync="iconPicker.close"
                  class="input-group-addon no-padding icons"
                >
                  <template #button>
                    <div class="btn" @click.prevent="iconPicker.close = true">
                      <i :class="iconCloseClass"></i>
                    </div>
                  </template>
                </IconLibrary>
                <div class="input-group-addon no-padding">
                  <ColorPicker
                    v-model="iconCloseColor"
                    :pickerStyle="{left: '-200px'}"
                    icon="background"
                  />
                </div>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <div class="input-group">
                <div class="form-control  text-center text-bold">
                  {{ $t("openned") }}
                </div>
                <IconLibrary
                  v-model="iconOpenClass"
                  :buttonAttrs="{title: iconOpenClass}"
                  :panelOpen.sync="iconPicker.open"
                  class="input-group-addon no-padding icons"
                >
                  <template #button>
                    <div class="btn" @click.prevent="iconPicker.open = true">
                      <i :class="iconOpenClass"></i>
                    </div>
                  </template>
                </IconLibrary>
                <div class="input-group-addon no-padding">
                  <ColorPicker
                    v-model="iconOpenColor"
                    :pickerStyle="{left: '-200px'}"
                    icon="background"
                  />
                </div>
              </div>
            </div>
          </TogglePanel>
        </div>
      </template>
      <div style="padding: 0 5px">
        <ColumnEventForm v-model="aggregation" v-if="!aggregation.enabled" />
      </div>
    </div>
  </TogglePanel>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlStyleProperties from "@/components/synoptic/property-editor/controls/control-style-properties.vue";
import IconLibrary from "@/components/editor/icon-library.vue";
import ColorPicker from "@/components/editor/color-picker.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import ColumnEventForm from "@/components/control-sidebar/property-editors/column-event-form.vue";
import {isEqual} from "lodash";
import {stats} from "@/components/control-sidebar/property-editors/detail-form-list-table.vue";
export default {
  name: "ColumnGroupForm",
  components: {
    TogglePanel,
    ControlStyleProperties,
    IconLibrary,
    ColorPicker,
    JSONPathPicker,
    ColumnEventForm
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    deviceSupported: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    aggregation: null,
    iconPicker: {
      open: false,
      close: false
    }
  }),
  computed: {
    aggregationStyle: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation, "style", vlr);
        }
      },
      get() {
        return this?.aggregation?.style;
      }
    },
    iconOpenClass: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation.icons.open, "class", vlr);
        }
      },
      get() {
        return this?.aggregation?.icons?.open?.class;
      }
    },
    iconCloseClass: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation.icons.close, "class", vlr);
        }
      },
      get() {
        return this?.aggregation?.icons?.close?.class;
      }
    },
    iconOpenColor: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation.icons.open, "color", vlr);
        }
      },
      get() {
        return this?.aggregation?.icons?.open?.color;
      }
    },
    iconCloseColor: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation.icons.close, "color", vlr);
        }
      },
      get() {
        return this?.aggregation?.icons?.close?.color;
      }
    },
    expression: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation, "expression", vlr);
        }
      },
      get() {
        return this.aggregation.expression;
      }
    },
    expressionTemplate() {
      let ret = {
        ...stats(),
        name: "",
        icons: {
          connected: true,
          disconnected: true,
          alarm_on: true,
          alarm_off: true,
          enable: true,
          connector: true,
          device: true,
          data: true,
          alarm: true,
          connectorConnection: true,
          deviceConnection: true
        }
      };
      if (!this.deviceSupported) {
        var p;
        for (p in ret.stats) {
          if (/device/i.test(p)) {
            delete ret.stats[p];
          }
        }
        for (p in ret.icons) {
          if (/device/i.test(p)) {
            delete ret.icons[p];
          }
        }
      }
      return ret;
    }
  },
  watch: {
    value: {
      handler(n) {
        if (n) {
          if (isEqual(n, this.aggregation)) return;
          this.aggregation = n;
        }
      },
      immediate: true
    },
    aggregation: {
      handler(n) {
        if (n) {
          if (isEqual(n, this.value)) return;
          this.$emit("input", n);
        }
      }
    }
  },
  methods: {},
  mounted() {
    if (this.$refs.iconOpen) {
      this.$refs.iconOpen.$on("update:panelOpen", (value) => {
        this.iconPicker.open = value;
      });
    }
    if (this.$refs.iconClose) {
      this.$refs.iconClose.$on("update:panelOpen", (value) => {
        this.iconPicker.close = value;
      });
    }
  }
};
</script>

<style scoped>
.input-group-addon.icons > .btn {
  padding: 0;
  min-width: 32px;
}
</style>
