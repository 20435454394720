<template>
  <section>
    <div>
      <!-- /Search -->
      <EquipmentSearchPanel :source="source" />
      <!-- \Search -->
      <ProcessAreaSelectionCardPanel
        :equipmentList="equipmentList"
        :source="source"
        :useGlobalQueryFilters="true"
        v-if="equipmentList && equipmentList.length"
      >
      </ProcessAreaSelectionCardPanel>
    </div>
    <!-- -->
  </section>
</template>

<script>
import EquipmentSearchBase from "@/views/private/EquipmentSearchBase.vue";
import ProcessAreaSelectionCardPanel from "@/components/processarea-selection-card-panel.vue";
export default {
  name: "EquipmentSearchProcessArea",
  extends: EquipmentSearchBase,
  components: {
    ProcessAreaSelectionCardPanel
  },
  data() {
    return {
      fixedEquipmentProperties: [
        "nome",
        "numero_serie",
        "localizacao",
        "has_active_alarms",
        "is_connected"
      ],
      activeProcessArea: null
    };
  },
  computed: {
    allEquipmentFields() {
      let self = this;
      return (
        (self.$root.config.equipment_selection &&
          self.$root.config.equipment_selection.cols) ||
        []
      );
    },
    equipmentCustomFields() {
      let self = this;
      return this.allEquipmentFields.filter(function(i) {
        return self.fixedEquipmentProperties.indexOf(i.name) == -1;
      });
    },
    showBanner() {
      let self = this;
      return self.$root.config.equipment_selection &&
        "show_banner" in self.$root.config.equipment_selection
        ? self.$root.config.equipment_selection.show_banner
        : false;
    },
    showProcessArea() {
      let self = this;
      return self.$root.config.equipment_selection &&
        "tabs" in self.$root.config.equipment_selection
        ? self.$root.config.equipment_selection.tabs.process_area
        : false;
    },
    showStation() {
      let self = this;
      return self.$root.config.equipment_selection &&
        "tabs" in self.$root.config.equipment_selection
        ? self.$root.config.equipment_selection.tabs.station
        : false;
    }
  },
  methods: {
    showField(name) {
      return (
        this.allEquipmentFields.filter(function(i) {
          return i.name == name;
        }).length > 0
      );
    }
    // onProcessAreaClick(item) {
    //   if (
    //     !this.activeProcessArea ||
    //     (item && this.activeProcessArea.id == item.id)
    //   ) {
    //     this.$set(this, "activeProcessArea", item);
    //     let query = JSON.parse(window.localStorage.getItem("query") || null);
    //     if (query) {
    //       query.query_process_area = {
    //         id: item.id,
    //         ids: item.ids
    //       };
    //       window.localStorage.setItem("query", JSON.stringify(query));
    //     }
    //     this.$router.push("/dashboard/search/table");
    //   }
    // }
  },
  created() {},
  mounted() {
    this.refreshEnabled = true;
  }
};
</script>
<style scoped>
.tab-search {
  position: relative;
}
</style>
