<template>
  <div class="CustomerPageBanner">
    <img v-bind:src="imageSrc" v-bind:alt="title" class="img-fluid" />
  </div>
</template>

<script>
export default {
  name: "CustomerPageBanner",
  props: {
    src: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    isReady: function() {
      return this.$store.getters["dashboard/isReady"];
    },
    imageSrc: function() {
      const localTheme = window.localStorage.getItem("css_theme");
      const src = this.src || this?.$root?.config?.company_banner || ""; 
      return src.replace("hitecnologia_banner.png", "hitecnologia_banner_white.png");
      if (
        localTheme == "skin-dark" &&
        src.indexOf("hitecnologia_banner.png") >= 0
      ) {
        return src.replace("hitecnologia_banner.png", "hitecnologia_banner_white.png");
      } else {
        return src;
      }
    },
    title: function() {
      return (
        this.$store.getters["dashboard/property"]("company_name") ||
        "HI Tecnologia"
      );
    }
  }
};
</script>

<style>
.CustomerPageBanner {
  margin: 0px 0px;
  text-align: center;
}

.CustomerPageBanner > img {
  width: 36%;
}
</style>
