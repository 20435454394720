<template>
  <section class="section">
    <template v-if="isConnector">
      <div class="row">
        <div class="col-lg-5 col-sm-12">
          <label class="noselect">
            <input
              type="checkbox"
              v-model="isModel"
              data-testid="is-model"
              v-bind:disabled="disabled || busy"
            />
            <span class="checkbox-label-right clicable-label"
              >{{ $t("define_this_connector_as_model") }}
              <Tooltip :title="$t('hints.enables_connector_models')" />
            </span>
            <div class="model-only-info" v-if="disabled">
              {{ $t("new_instances_limit_exceeded") }}
            </div>
          </label>
        </div>
        <div class="col-lg-6 col-sm-6" v-if="isModel">
          <label class="noselect" v-if="connector_id">
            <input type="checkbox" v-model="propagate" v-bind:disabled="busy" />
            <span class="checkbox-label-right clicable-label"
              >{{ $t("apply_changes_to_instances") }}
            </span>
            <Tooltip :title="$t('hints.apply_changes_to_instances')" />
          </label>
        </div>
        <div class="col-lg-3 col-sm-6" v-else>
          <label class="noselect">
            <input
              type="checkbox"
              v-model="selectionEnabled"
              v-if="!isModel"
              v-bind:disabled="busy"
            />
            <input
              type="checkbox"
              v-bind:disabled="true"
              v-bind:value="false"
              v-else
            />
            <span
              class="checkbox-label-right"
              v-bind:class="isModel ? '' : 'clicable-label'"
              >{{ $t("inherit_template_settings") }}</span
            >
            <Tooltip :title="$t('hints.inherit_template_settings')" />
          </label>
        </div>
        <div class="col-lg-4 col-sm-6" v-if="selectionEnabled">
          <div class="form-control" v-if="busy">
            <spin />
          </div>
          <div v-else>
            <div class="form-group" :class="{'has-error': selected === null}">
              <div class="input-group">
                <select class="form-control" v-model="selected">
                  <option
                    :value="null"
                    v-if="!selected"
                    selected
                    style="color: #999"
                  >
                    {{ $t("not_selected") }}
                  </option>
                  <template>
                    <option
                      v-bind:value="item"
                      v-for="item in items"
                      v-bind:key="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </template>
                </select>
                <div
                  class="input-group-addon btn"
                  v-if="selected"
                  :title="$t('edit')"
                  @click.stop.prevent="
                    $router.push(
                      `/dashboard/edit/connector/${selected.id}#edit`
                    )
                  "
                >
                  <i class="fa fa-pencil" :title="$t('model')"></i>
                </div>
                <div v-else class="input-group-addon btn">
                  <i class="fa fa-pencil" :title="$t('model')"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row" v-if="isModel || !selected">
        <div class="form-group col-md-12">
          <label class="no-select">
            <input type="checkbox" v-model="keep_data_reference_id" />
            <span class="checkbox-label-right clicable-label"
              >{{ $t("keep_screen_references_among_devices") }}
              <Tooltip
                :title="$t('hints.keep_screen_references_among_devices')"
              />
            </span>
          </label>
        </div>
      </div> -->
    </template>
    <div class="row" v-else>
      <div class="col-lg-6 col-sm-6">
        <label class="noselect">
          <input type="checkbox" v-model="propagate" v-bind:disabled="busy" />
          <span class="checkbox-label-right clicable-label">{{
            $t("apply_changes_to_instances")
          }}</span>
          <Tooltip :title="$t('hints.apply_changes_to_instances')" />
        </label>
      </div>
    </div>
    <div
      class="row"
      v-if="
        !isModel &&
          connector_id &&
          selected &&
          ((original && original.id != selected.id) || !original)
      "
    >
      <div class="col-lg-12">
        <label class="noselect">
          <input type="checkbox" v-model="propagate" v-bind:disabled="busy" />
          <span class="checkbox-label-right clicable-label">{{
            $t("apply_changes_to_instances")
          }}</span>
          <Tooltip :title="$t('hints.apply_changes_to_instances')" /> -
        </label>
      </div>
    </div>
    <div class="row" v-if="propagate">
      <div class="col-lg-8 col-lg-offset-2">
        <div class="alert bg-alert alert-dismissible h4" role="alert">
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            v-on:click="propagate = false"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <strong
            ><i class="fa fa-exclamation-triangle"></i>
            {{ $t("attention") }}!</strong
          >
          {{ $t("apply_changes_to_instances_alert") }}.
          <div class="text-right">
            <button
              class="btn btn-sm btn-primary"
              style="margin-right: 10px"
              v-on:click.stop.prevent="propagate = false"
            >
              {{ $t("do_not_apply_changes_to_instances") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Spin from "@/components/spin.vue";
import Tooltip from "@/components/tooltip.vue";
import messages from "@/i18n/connector";
export default {
  name: "FormInlineModel",
  components: {
    Spin,
    Tooltip
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    connector_id: {
      type: Number,
      required: false,
      default: () => 0
    },
    isConnector: {
      type: Boolean,
      required: false,
      default: () => true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      isModel: false,
      propagate: false,
      selectionEnabled: false,
      selected: null,
      busy: false,
      original: null
      // keep_data_reference_id: false
    };
  },
  computed: {
    equipmentList() {
      return (this.$store.getters["dashboard/connectorList"] || []).filter(
        ({base_model, deleted_at}) => base_model && !deleted_at
      );
    },
    items() {
      let self = this;
      return self.equipmentList.filter(
        (i) => (!self.connector_id || self.connector_id != i.id) && i.enabled
      );
    },
    payload() {
      let payload = null;
      if (this.isConnector) {
        payload = {
          is_model: this.isModel,
          propagate: this.propagate,
          model:
            (!this.isModel &&
              this.selectionEnabled &&
              this.selected &&
              this.selected) ||
            null
          // keep_data_reference_id: this.keep_data_reference_id
        };
      } else {
        payload = {
          propagate: this.propagate
        };
      }
      return payload;
    }
  },
  watch: {
    isModel: {
      handler(n) {
        if (n) {
          this.selected = null;
        }
        this.selectionEnabled = false;
        this.propagate = false;
      },
      immediate: true
    },
    selected: {
      handler(n) {
        if (n) {
          if (this.original && this.original.id == n.id) {
            this.propagate = false;
          }
        }
      },
      immediate: true
    },
    selectionEnabled(n, o) {
      if (n) {
        if (this.items.length) {
          if (!this.selected && this.original) {
            this.selected = this.original;
          }
        } else {
          this.initSelection();
        }
      } else {
        if (o) {
          this.selected = null;
          this.propagate = false;
        }
      }
    },
    payload(n, o) {
      this.$emit("input", JSON.parse(JSON.stringify(n)));
    },
    equipmentList: {
      handler() {
        if (
          !this.value.is_model &&
          this?.equipmentList?.length &&
          this.selected === null
        ) {
          this.isModel = this.value.is_model;
          if (!this.isModel) this.initSelection();
        }
      },
      immediate: true
    }
  },
  methods: {
    initSelection() {
      let selected = null;
      if (this.items.length) {
        if (this?.value?.model?.id) {
          selected = this.items.find((i) => i.id == this.value.model.id);
        } else {
          let modelId = this.$utils.gup("model");
          if (modelId) {
            selected = this.items.find((i) => i.id == modelId);
          }
        }
        this.original = selected || null;
        this.selected = selected || null;
      }
      if (this.selected) {
        this.selectionEnabled = true;
      }
    }
  },
  i18n: {
    messages
  },
  created() {
    this.isModel = this.value.is_model;
    if (
      !this.value.is_model &&
      this?.equipmentList?.length &&
      this.selected === null
    ) {
      this.initSelection();
    }
  }
};
</script>

<style scoped>
label {
  margin: 5px 0;
}

span.checkbox-label-right {
  vertical-align: top;
  margin-left: 5px;
}

.clicable-label:hover {
  cursor: pointer;
  color: #72afd2;
}
.skin-dark .clicable-label:hover {
  color: #fff;
}
.section {
  background-color: whitesmoke;
  padding: 20px 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  min-height: 74px;
}
.skin-dark .section {
  background-color: #171b1d;
  color: #b8c7ce;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.bg-alert {
  background-color: #fddedc;
  line-height: normal;
}
.skin-dark .bg-alert {
  background-color: #2c3b41;
  color: #b8c7ce;
}
.model-only-info {
  margin: -5px 0 0 15px;
  font-size: 10pt;
  font-weight: normal;
  color: #a94442;
}
</style>
