<template>
  <router-link
    class="text-info"
    :class="{screen_column: !isModel}"
    :title="$t(isModel ? 'edit' : 'titles.access_dashboard')"
    :to="link"
  >
    <slot name="before" :item="item"></slot>
    <slot name="default" :item="item">
      <span>
        {{ (screen && screen.name) || item.name }}
      </span>
      <i class="fa fa-dashboard not-revealed" v-if="!isModel" />
    </slot>
    <slot name="after" :item="item"></slot>
  </router-link>
</template>

<script>
import ScreenFinder from "@/utils/screen-finder.js";
export default {
  name: "ScreenSelectionCell",
  props: {
    // item must be a connector or a device
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    connector() {
      return this.$proxy && this.$proxy.connector;
    },
    isModel() {
      return this.$proxy && this.$proxy.isModel;
    },
    device() {
      return this.$proxy && this.$proxy.device;
    },
    link() {
      return this.$proxy && this.$proxy.link;
    },
    screen() {
      return this.$proxy && this.$proxy.screen;
    }
  },
  created() {
    this.$proxy = new ScreenFinder(this, this.item);
  }
};
</script>

<style scoped>
.screen_column {
  display: block;
  margin: -5px;
  padding: 5px;
  background-color: inherit;
}

.screen_column:hover {
  cursor: pointer;
  background-color: rgb(225, 243, 250);
}

.skin-dark .screen_column:hover {
  background-color: inherit;
}

.screen_column > i.not-revealed {
  margin-left: 5px;
  color: transparent;
}

.screen_column:hover > i.not-revealed {
  color: #337ab7;
  opacity: 0.7;
}
</style>
