<template>
  <section class="me" :class="{embedded: embedded}">
    <div>
      <div class="box" :class="isEditing ? 'box-warning' : 'box-primary'">
        <DashboardPanelHeader
          ref="panelHeader"
          :panel="panel"
          :mode="mode"
          :isEditing="isEditing"
          :equipment="equipment"
          @title:click="$emit('panelProperties')"
        >
          <template #toolbar><slot name="toolbar"></slot></template>
        </DashboardPanelHeader>

        <template v-if="connector">
          <div class="box-body">
            <div class="overlay overlay-loading" v-if="busy">
              <i class="fa fa-refresh fa-spin"></i>
            </div>
            <form>
              <div
                class="row"
                v-for="(chunked_fields, cix) in chunkedFields"
                :key="cix"
              >
                <div v-for="(field, ix) in chunked_fields" :key="ix">
                  <template v-if="displayInline">
                    <div :class="columnsInlineClass">
                      <div
                        class="form-group"
                        :class="{'has-error': hasError(field)}"
                      >
                        <div class="row">
                          <div class="col-xs-6 col-sm-4 text-right">
                            <label
                              :title="
                                field.required || field.panel_required
                                  ? field.description +
                                    ' (' +
                                    $tc('required', 1).toLowerCase() +
                                    ')'
                                  : field.description
                              "
                              :for="`field_${field.name}`"
                              :class="
                                field.required || field.panel_required
                                  ? 'text-danger'
                                  : ''
                              "
                              style="width:100%"
                              >{{ field.title }}
                              <sup v-if="field.required || field.panel_required"
                                >*</sup
                              >
                            </label>
                          </div>
                          <div class="col-xs-6 col-sm-8">
                            <div class="input-group">
                              <input
                                class="form-control"
                                type="text"
                                v-model="field.value"
                                :placeholder="field.initialValue"
                                :id="`field_${field.name}`"
                                :required="
                                  field.required || field.panel_required
                                "
                                :title="field.description"
                                :maxlength="fieldMaxLength(field)"
                              />
                              <div
                                class="input-group-addon btn"
                                :title="$t('restore')"
                                @click.stop.prevent="
                                  setFieldValue(field, field.initialValue, true)
                                "
                              >
                                <i class="fa fa-undo"></i>
                              </div>
                              <div
                                class="input-group-addon btn"
                                :title="$t('clear')"
                                @click.stop.prevent="
                                  setFieldValue(field, '', true)
                                "
                              >
                                <i class="fa fa-close"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="row">
                      <div
                        class="col-sm-12 col-md-6 col-md-offset-3 field-container"
                      >
                        <div
                          class="form-group"
                          :class="{'has-error': hasError(field)}"
                        >
                          <label
                            :title="
                              field.required || field.panel_required
                                ? field.description +
                                  ' (' +
                                  $tc('required', 1).toLowerCase() +
                                  ')'
                                : field.description
                            "
                            :for="`field_${field.name}`"
                            :class="
                              field.required || field.panel_required
                                ? 'text-danger'
                                : ''
                            "
                            style="width:100%"
                            >{{ field.title }}
                            <sup v-if="field.required || field.panel_required"
                              >*</sup
                            >
                          </label>
                          <div class="input-group">
                            <input
                              class="form-control"
                              type="text"
                              v-model="field.value"
                              :placeholder="field.initialValue"
                              :id="`field_${field.name}`"
                              :required="field.required || field.panel_required"
                              :title="field.description"
                              :maxlength="fieldMaxLength(field)"
                            />
                            <div
                              class="input-group-addon btn"
                              :title="$t('restore')"
                              @click.stop.prevent="
                                setFieldValue(field, field.initialValue, true)
                              "
                            >
                              <i class="fa fa-undo"></i>
                            </div>
                            <div
                              class="input-group-addon btn"
                              :title="$t('clear')"
                              @click.stop.prevent="
                                setFieldValue(field, '', true)
                              "
                            >
                              <i class="fa fa-close"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </form>
          </div>
          <div class="box-footer">
            <template v-for="item in controlList">
              <button
                v-if="item.show"
                :class="item.class"
                :key="item.id"
                :title="$t(item.hint)"
                :disabled="busy || item.disabled"
                @click.stop.prevent="onCustomEvent(item, 'click')"
              >
                <div class="hidden-lg hidden-md">
                  <i :class="item.icon" v-if="item.icon"></i>
                  <span v-if="!item.icon">
                    {{ $t(item.label.getCharAt(0)) }}
                  </span>
                </div>
                <div class="hidden-sm hidden-xs">
                  <i :class="item.icon" v-if="item.icon"></i>
                  <span>
                    {{ $t(item.label) }}
                  </span>
                </div>
              </button>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="box-body text-center">
            <template v-if="mode == 'editor'">
              <InfoBox
                :title="$t('attention')"
                :text="$t('select_ref_connector')"
                :dismissible="true"
                style="padding-bottom:50px"
              >
              </InfoBox>
            </template>
            <template v-else>
              <InfoBox
                :title="$t('attention')"
                :text="
                  $tc('no_item_found', 1, {
                    item: $tc('connector', 1).toLowerCase()
                  })
                "
                :dismissible="true"
                style="padding-bottom:50px"
              >
              </InfoBox>
            </template>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import ConnectorService from "@/services/connector.js";
import InfoBox from "@/components/info-box.vue";
import DashboardPanelHeader from "@/components/dashboard-panel-header.vue";

const initialState = () => ({
  connector: {},
  fields: [],
  busy: false
});

export default {
  name: "EquipmentExtendedPropertiesPanel",
  components: {
    InfoBox,
    DashboardPanelHeader
  },
  data: initialState,
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => null
    },
    panel: {
      type: Object,
      required: false,
      default: () => null
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"];
    },
    user() {
      return this.$store.getters["user/loggedUser"];
    },
    baseModelConnector() {
      return (
        (this?.equipment?.base_model_id &&
          (this.$store.getters["dashboard/connectorList"] || []).find(
            ({id}) => parseInt(id) == parseInt(this.equipment.base_model_id)
          )) ||
        null
      );
    },
    controlList() {
      return (this?.panel?.options?.controls || []).map((i) => {
        if (
          (i?.on?.click?.actions || []).some(({type}) => type == "form:submit")
        ) {
          i.disabled = !this.isValid;
        } else {
          i.disabled = false;
        }
        return i;
      });
    },
    isValid() {
      return (
        this.isDirty &&
        !(this.fields || []).some((field) => this.hasError(field))
      );
    },
    device() {
      // TODO: get it from the panel options device_id
      return null;
    },
    initialValues() {
      // this is immutable till save it
      return this.connector?.user_data?.extended_properties || {};
    },
    payload() {
      let payload = {};
      (this.fields || []).forEach((field) => {
        payload[field.name] = field.value;
      });
      return payload;
    },
    isDirty() {
      if (!this?.fields?.length) return false;
      let reference = {};
      for (var p in this.payload) {
        reference[p] = this.initialValues[p] ?? "";
      }
      return !isEqual(this.payload, reference);
    },
    hasUserAccessTo() {
      return this.$store.getters["user/hasUserAccessTo"];
    },
    editorProperties() {
      return (
        (this.isEditing && {
          showOptionalFields: this?.panel?.options?.showOptionalFields ?? false,
          showRequiredFields: this?.panel?.options?.showRequiredFields ?? false
        }) ||
        null
      );
    },
    connectorId() {
      return this?.equipment?.id || "";
    },
    displayInline() {
      return this?.panel?.options?.displayInline ?? false;
    },
    columnsInline() {
      return this.panel &&
        this.panel.options &&
        this.panel.options.columnsInline
        ? this.panel.options.columnsInline
        : 3;
    },
    columnsInlineClass() {
      if (this.columnsInline <= 1) return "col-xs-12 col-sm-12";
      if (this.columnsInline <= 2) return "col-xs-12 col-sm-6";
      if (this.columnsInline <= 3) return "col-xs-12 col-sm-4";
      return "col-xs-12 col-sm-4";
    },
    panelOptionsFields() {
      return this.panel && this.panel.options && this.panel.options.fields;
    },
    chunkedFields() {
      if (!this.displayInline) return [this.fields];
      let result = [];
      const chunkSize = this.columnsInline;
      for (let i = 0; i < this.fields.length; i += chunkSize) {
        result.push(this.fields.slice(i, i + chunkSize));
      }
      return result;
    }
  },
  watch: {
    connectorId(n, o) {
      if (Number(n) !== Number(o)) {
        this.setInitData();
        this.setup();
        this.restoreValues();
      }
    },
    editorProperties(n, o) {
      if (!this.isEditing && n == o) return;
      this.setInitData();
      this.setup();
      this.restoreValues();
    },
    isDirty(n, o) {
      if (isEqual(n, o)) return;
      this.$store.dispatch("dashboard/setDirtyForms", {
        extendedPropertiesForm: n
      });
    },
    panelOptionsFields(n, o) {
      this.setup();
    },
    user(n) {
      const css_theme = n?.user_profile?.portal_data?.css_theme;
      this.$utils.applyCSSTheme(css_theme);
    }
  },
  methods: {
    setFieldValue(field, value, focus) {
      if (!field || value === undefined) return;
      field.value = value;
      if (focus) {
        this.$nextTick(() => {
          let el = document.getElementById(`field_${field.name}`);
          if (el) el.focus();
        });
      }
    },
    hasError(field) {
      return (field.required || field.panel_required) && field.value === ""
        ? true
        : false;
    },
    onCustomEvent(control, eventName) {
      if (this.mode != "viewer") return;
      let evt = (control?.on || {})[eventName] || null;
      if (evt?.actions?.length && this.hasUserAccessTo(evt.process_area_id)) {
        if (evt?.confirmation?.enabled) {
          this.$swal(evt.confirmation).then((confirm) => {
            if (!confirm) return;
            this.triggerEventActions(control, eventName);
          });
        } else {
          this.triggerEventActions(control, eventName);
        }
      }
    },
    triggerEventActions(control, eventName) {
      ((control.on || {})[eventName]?.actions || []).forEach((action) => {
        this.triggerAction(action);
      });
    },
    triggerAction(action) {
      // TODO: in future this all be treated by the global command action manager
      if (this.mode == "editor" || !action?.type) return;
      let command = action.type.split(":")[1];
      switch (command) {
        case "clear":
          this.onFormClear();
          break;
        case "reset":
          this.onFormReset();
          this.$emit("reset");
          break;
        case "submit":
          this.onFormSubmit();
          break;
        default:
          break;
      }
    },
    onFormClear() {
      (this.fields || []).forEach((field, ix) => {
        this.setFieldValue(field, "", ix == 0); // focus on first field
      });
    },
    onFormReset() {
      this.restoreValues();
      this.setFieldValue(this.fields[0], this.fields[0].value, true); // focus on first field
    },
    onFormSubmit() {
      if (!this.isValid || this.busy) return;
      this.busy = true;
      this.service
        .get(this.connector.id)
        .then((ret) => {
          this.service
            .save({
              id: ret.id,
              etag: ret.etag,
              user_data: {
                ...ret.user_data,
                extended_properties: this.payload
              }
            })
            .then((ret) => {
              this.busy = false;
              let connector = structuredClone(ret);
              this.$store.dispatch("dashboard/setConnectorValue", [connector]);
              this.$store.dispatch("dashboard/setConnectorPropertyValue", {
                connector_id: connector.id,
                properties: connector.user_data.extended_properties
              });
              this.connector = connector;
              this.restoreValues();
            })
            .catch((err) => {
              this.busy = false;
              console.error(err);
            });
        })
        .catch((err) => {
          this.busy = false;
          console.error(err);
        });
    },
    restoreValues() {
      (this.fields || []).forEach((field) => {
        if (field.name in this.initialValues) {
          let value = this.initialValues[field.name] || "";
          field.value =
            (field.required || field.panel_required) && value === ""
              ? field.value
              : value;
          field.initialValue = field.value;
        }
      });
    },
    fieldMaxLength(field) {
      if (field.character_limit) {
        try {
          return parseInt(field.character_limit);
        } catch {}
      }
      return "1024";
    },
    setInitData() {
      let data = initialState();
      for (var p in data) this.$set(this, p, data[p]);
    },
    setup() {
      if (!this.contract) return;
      let panel_fields = this?.panel?.options?.fields;
      this.connector = structuredClone(this.equipment);
      if (!this.connector) return;
      const _buildFields = (extended_properties) => {
        let values = (this.fields || []).reduce(
          (a, v) => ({...a, [v.name]: v.value}),
          {}
        );
        let fields = [];
        let ix = 0;
        for (var p in extended_properties || {}) {
          if (p == "notifications") continue;
          let field = {
            index: ix,
            name: p,
            description: "",
            title: p,
            required: false,
            value: "",
            panel_required: false,
            visible: true,
            character_limit: ""
          };
          var content = extended_properties[p];
          if (typeof content == "object") {
            field = {...field, ...content};
          }
          if (field.name in values) {
            field.value = values[field.name];
          } else if (field.name in this.initialValues) {
            field.value = this.initialValues[field.name];
          } else {
            field.value =
              content?.value !== undefined ? content.value : content;
          }
          field.title = field.title || field.name;
          field.description = field.description || field.title;

          // Configurações específicas feitas no Painel
          if (panel_fields) {
            const panel_field = panel_fields.find(
              ({name}) => field.name == name
            );
            if (panel_field) {
              delete panel_field.value;
              if (panel_field) {
                field = {
                  ...field,
                  ...panel_field
                };
              }
            }
          }

          if (
            this?.panel?.options?.showRequiredFields &&
            field.required &&
            (field.visible || field.visible == undefined)
          ) {
            fields.push(field);
            ix++;
          } else if (
            this?.panel?.options?.showOptionalFields &&
            !field.required &&
            (field.visible || field.visible == undefined)
          ) {
            fields.push(field);
            ix++;
          }
        }
        fields.sort((a, b) => a.index - b.index);
        // this.$set(this, "fields", fields);
        this.fields = fields;
      };
      let baseModel = this.connector.base_model
        ? this.connector
        : this.baseModelConnector;
      if (baseModel?.portal_data?.extended_properties?.length) {
        // build model fields
        _buildFields(baseModel.portal_data.extended_properties);
      } else if (
        this?.contract?.portal_data?.equipment_extended_properties?.length
      ) {
        // contract based fields
        _buildFields(this?.contract.portal_data.equipment_extended_properties);
      } else {
        // legacy config.json fields
        _buildFields(this.$root.config.equipment_extended_properties);
      }
    }
  },
  created() {
    this.setInitData();
    this.setup();
    this.restoreValues();
  },
  beforeCreate() {
    this.service = new ConnectorService();
    this.embedded = window.location != window.parent.location;
  },
  beforeDestroy() {
    this.service = null;
  }
};
</script>

<style scoped>
.me {
  width: 100%;
  margin: 0;
  min-height: inherit;
  position: relative;
}

.me > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.box {
  height: 100%;
}

.box > .box-header {
  padding: 5px;
  min-height: 30px;
}

.box > .box-body {
  position: relative;
  height: calc(100% - 90px);
  width: 100%;
  overflow: hidden auto;
  padding: 0 10px 30px 10px;
}

.box > .box-footer {
  clear: both;
  overflow-x: auto;
}

.box > .box-footer > .btn {
  margin: 0 6px;
  max-width: 100px;
  padding: 6px 0;
}

.box > .box-footer > .btn > .hidden-sm,
.box > .box-footer > .btn > .hidden-sm {
  min-width: 100px;
}
.box > .box-footer > .btn > .hidden-lg,
.box > .box-footer > .btn > .hidden-md {
  min-width: 32px;
}

.box > .box-footer > .btn > i {
  margin-right: 5px;
}

.box > .box-body > form {
  margin: 30px 10px;
}

.overlay-loading {
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 80px;
  text-align: center;
  margin-top: 2%;
  color: #607d8b;
  width: 100%;
  padding: 80px;
}
.opaque {
  opacity: 0.5;
}

.field-container {
  min-width: 250px;
}

.embedded {
  padding-bottom: 50px;
}

.embedded .box.box-primary {
  border-top-color: transparent;
}

.embedded .box > .box-header {
  border-bottom-color: transparent;
}

.embedded .box > .box-body {
  height: calc(100% - 100px);
  overflow: visible;
}

.embedded .box > .box-footer {
  overflow: visible;
  padding-bottom: 50px;
}

.info-box-section::v-deep > div.alert {
  padding-top: 0;
}
</style>
