<template>
  <section v-if="formData">
    <div class="">
      <label :for="`inpVisible${value.name}`">
        <input
          type="checkbox"
          :id="`inpVisible${value.name}`"
          v-model="formData.enabled"
        />
        {{ $tc("titles.enabled", 1) }}
        <ToolTip :title="$tc('hints.enabled', 1)" />
      </label>
    </div>
    <BootstrapFormInput
      v-model="formData.label"
      :label="$tc('titles.label', 1)"
      :hint="$tc('hints.label', 1)"
      :placeholder="$t('text')"
    />
    <div class="form-group" style="position: relative;z-index:3">
      <label>
        {{ $tc("titles.expression", 1) }}
        <ToolTip :title="$tc('hints.expression', 1)" />
      </label>
      <JSONPathPicker
        :entry="sample"
        :append="true"
        entryType="data"
        v-model="formData.expression"
      />
    </div>
    <div class="form-group-checkbox">
      <label :for="`inpShowNulls${value.name}`">
        <input
          type="checkbox"
          :id="`inpShowNulls${value.name}`"
          v-model="showNulls"
        />
        {{ $tc("titles.show_null", 2) }}
        <ToolTip :title="$t('hints.show_null')" />
      </label>
    </div>
    <div class="form-group-checkbox">
      <label :for="`inpInverted${value.name}`">
        <input
          type="checkbox"
          :id="`inpInverted${value.name}`"
          v-model="inverted"
        />
        {{ $tc("titles.inverted", 1) }}
        <ToolTip :title="$t('hints.inverted')" />
      </label>
    </div>
    <BootstrapFormInput
      v-model="formData.placeholder"
      :label="$tc('titles.placeholder', 1)"
      :hint="$tc('hints.placeholder', 1)"
      :placeholder="$t('text')"
    />
    <BootstrapFormInput
      v-model="formData.hint"
      :label="$tc('titles.hint', 1)"
      :hint="$tc('hints.hint', 1)"
      :placeholder="$t('text')"
    />
  </section>
</template>

<script>
import {isEqual} from "lodash";
import BootstrapFormInput from "@/components/widgets/bootstrap-form-input.vue";
import ToolTip from "@/components/tooltip.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import messages from "@/i18n/equipment-selectors.js";

export default {
  name: "SelectorForm",
  i18n: {messages},
  components: {
    BootstrapFormInput,
    ToolTip,
    JSONPathPicker
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: null
    },
    sample: {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      formData: null
    };
  },
  computed: {
    showNulls: {
      set(value) {
        this.$set(this.formData, "show_nulls", value);
      },
      get() {
        return this?.formData?.show_nulls ?? false;
      }
    },
    inverted: {
      set(value) {
        this.$set(this.formData, "inverted", value);
      },
      get() {
        return this?.formData?.inverted ?? false;
      }
    }
  },
  watch: {
    formData: {
      handler(n) {
        if (!n || isEqual(n, this.value)) return;
        this.$emit("input", this.formData);
      },
      deep: true
    }
  },
  created() {
    this.formData = {...this.value};
  }
};
</script>

<style scoped>
section {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

label > input[type="checkbox"] {
  vertical-align: top;
  margin-right: 5px;
  margin-bottom: 15px;
}
label:hover {
  cursor: pointer;
  opacity: 0.8;
}
.form-group-checkbox {
  z-index: 4;
  position: relative;
  padding-left: 10px;
}
</style>
