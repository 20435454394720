<template>
  <section>
    <slot name="title">
      <div class="h4">
        {{ $t("titles.available_list", { list: $tc("device", 2) }) }}
      </div>
    </slot>
    <div class="content-float">
      <div class="row">
        <div class="col-md-6 col-sm-5">
          <div class="box box-solid box-default">
            <div class="box-body">
              <div class="device-list no-select">
                <div>
                  <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      v-model="all"
                      :disabled="$attrs.disabled || !hasVirtualDevice"
                    />
                    {{ $tc("all", 1) }}
                  </label>
                  <div class="pull-right">
                    {{ $t("titles.number_of", { list: $tc("data", 2) }) }}
                  </div>
                </div>
                <div v-for="item in modelDeviceList" :key="item.id">
                  <label
                    class="checkbox-inline"
                    :title="`#${item.id} - ${item.description}`"
                  >
                    <input
                      type="checkbox"
                      v-model="selected[item.id]"
                      :disabled="$attrs.disabled || !isVirtual(item.id)"
                    />
                    {{ item.name }}
                  </label>
                  <div
                    class="pull-right"
                    :class="{ 'text-primary': selected[item.id] }"
                  >
                    {{ deviceDataCount(item.id) }}
                  </div>
                </div>
                <div>
                  <div
                    class="pull-right"
                    style="
                      text-align: right;
                      border-top: 1px solid #666;
                      width: 80px;
                    "
                  >
                    Total: {{ forecastData }}
                  </div>
                </div>
              </div>
              <div class="form-group bottom-options">
                <label class="checkbox-inline">
                  <input type="checkbox" v-model="preserveReferencyId" />
                  {{ $t("keep_screen_references_among_devices") }}
                  <ToolTip
                    :title="$t('hints.keep_screen_references_among_devices')"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-7">
          <ResourceUsageChart
            class="box box-solid box-default box-stats"
            :style="styleResourceUsageChart"
            :extraUsage="extraUsage"
            target="data"
            :customTitle="
              $t(
                forecast == 'always' ||
                  (forecast == 'if_changed' && forecastChanged)
                  ? 'titles.forecast_data_consumption'
                  : 'titles.data_consumption',
                1
              )
            "
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { isEqual } from "lodash";
import ResourceUsageChart from "@/components/statistics/resource-usage-chart";
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "DeviceSelector",
  components: {
    ToolTip,
    ResourceUsageChart
  },
  props: {
    connector: {
      type: Object,
      required: true,
      default: null
    },
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    forceDataListRequest: {
      type: Boolean,
      required: false,
      default: false
    },
    forecast: {
      type: String,
      required: false,
      default: "never" // always | never | if_changed  
    }
  },
  data: () => ({
    selected: {},
    originalForecast: undefined,
    preserveReferencyId: false
  }),
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"]
    },
    isDarkTheme() {
      return this.user?.user_profile?.portal_data?.css_theme == "skin-dark";
    },
    all: {
      set() {
        let value = !this.all;
        let entry = {};
        (this.modelDeviceList || []).forEach(
          ({ id }) => (entry[id] = this.isVirtual(id) ? value : true)
        );
        this.$set(this.$data, "selected", entry);
      },
      get() {
        return this.nSelected == this.modelDeviceList.length;
      }
    },
    hasVirtualDevice() {
      return this.modelDeviceList.some(({ id }) => this.isVirtual(id));
    },
    modelDeviceList() {
      return (this.$store.getters["dashboard/deviceList"] || []).filter(
        ({ connector_id }) => connector_id == this.modelId
      );
    },
    instanceDeviceList() {
      return (
        (this.instanceId &&
          (this.$store.getters["dashboard/deviceList"] || []).filter(
            ({ connector_id }) => connector_id == this.instanceId
          )) ||
        []
      );
    },
    forecastData() {
      var t = Object.keys(this.selected).reduce(
        (t, id) => (t += this.selected[id] ? this.deviceDataCount(id) : 0),
        0
      );
      return t;
    },
    extraUsage() {
      return this.forecast == "if_changed"
        ? this.forecastData - (this.originalForecast !== undefined ? this.originalForecast : 0)
        : this.forecast == "never"
          ? 0
          : this.forecastData;
    },
    selectedIds() {
      return Object.keys(this.selected || {})
        .filter((id) => this.selected[id] || false)
        .map((id) => parseInt(id))
        .sort();
    },
    nSelected() {
      return this.selectedIds.length;
    },
    forecastChanged() {
      return this.originalForecast !== undefined && this.originalForecast != this.forecastData;
    },
    dataList() {
      if (!this.modelId) return [];
      return this.$store.getters["dashboard/dataList"].filter(
        ({ clp_id }) => parseInt(clp_id) == parseInt(this.modelId)
      );
    },
    modelId() {
      return parseInt(this?.connector?.base_model_id || 0);
    },
    instanceId() {
      return parseInt(this?.connector?.id || 0);
    },
    allDevicesId() {
      return this.modelDeviceList.map(({ id }) => id);
    },
    styleResourceUsageChart() {
      if (this.isDarkTheme) {
        return {
          border: "1px solid #222d32",
          overflow: "hidden"
        }
      }
      return {
          border: "1px solid #d2d6de",
          overflow: "hidden"
        }
    }
  },
  watch: {
    value: {
      handler(n, o) {
        let lst = (n || []).map((id) => parseInt(id)).sort();
        if (isEqual(lst, this.selectedIds)) return;
        let selected = {};
        lst.forEach((id) => (selected[id] = true));
        this.$set(this, "selected", selected);
      },
      immediate: true,
      deep: true
    },
    selectedIds: {
      handler(n, o) {
        let lst = (this.value || []).map((id) => parseInt(id)).sort();
        if (isEqual(lst, n)) return;
        this.$emit("input", [...n]);

      },
      deep: true
    },
    preserveReferencyId: {
      handler(n) {
        this.$emit("update:preserveReferencyId", n);
      },
      immediate: true
    },
    modelId: {
      handler(n, o) {
        if (n && !o && this.forceDataListRequest) {
          this.fetchModelDataList();
        }
      },
      immediate: true
    },
    forecastData: {
      handler(n, o) {
        this.$emit("update:forecastData", n);
        if (this.originalForecast === undefined && n > 0) {
          this.originalForecast = n;
        }
      },
      immediate: true
    },
    instanceDeviceList: {
      handler() {
        let lst = this.modelDeviceList;
        let selected = {};
        if (this.instanceDeviceList.length) {
          let hasVirtualDevice = false;
          let hasMatchAny = false;
          lst = lst.filter(({ reference_id, id, data_collector_device_id }) => {
            if (
              data_collector_device_id &&
              parseInt(data_collector_device_id) != parseInt(id)
            ) {
              hasVirtualDevice = true;
            }
            let found = this.instanceDeviceList.find(
              (i) => i.reference_id == reference_id
            );
            if (found) {
              hasMatchAny = true;
            }
            return found;
          });
          if (!hasMatchAny && hasVirtualDevice) {
            // most probably, user replaced the instance model, try match it by name
            lst = this.modelDeviceList.filter(({ name }) =>
              this.instanceDeviceList.find((i) => i.name == name)
            );
          }
        }
        lst.map(({ id }) => id).forEach((id) => (selected[id] = true));
        this.$set(this, "selected", selected);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    isVirtual(deviceId) {
      const device = this.modelDeviceList.find(
        ({ id }) => parseInt(id) == parseInt(deviceId)
      );
      return (device && device.id !== device.data_collector_device_id) || false;
    },
    deviceDataCount(deviceId) {
      return this.dataList.filter(
        ({ device }) => parseInt(device.id) == parseInt(deviceId)
      ).length;
    },
    fetchModelDataList() {
      var query = {
        resource: "data",
        connectorId: this.modelId,
        forceUpdate: false,
        once: true
      };
      this.$store.dispatch("dashboard/fetchResourcesFrom", query);
    },
    fetchDeviceList(connectorId) {
      this.$store.dispatch("dashboard/fetchResourcesFrom", {
        resource: "device",
        connectorId: connectorId,
        forceUpdate: true,
        once: true
      });
    }
  },
  created() {
    this.$store.dispatch("user/fetchContractUsage");
    if (
      this.instanceId &&
      (!this.instanceDeviceList.length ||
        this.instanceDeviceList.length < this.modelDeviceList.length)
    ) {
      this.fetchDeviceList(this.instanceId);
    }
  }
};
</script>

<style scoped>
section {
  padding: 0 10px 50px 10px;
}

section > div.content-float {
  max-width: 100%;
  background-color: whitesmoke;
  padding: 20px 15px 10px 15px;
  border-radius: 5px;
  border: 1px solid #e3e2e2;
}

.skin-dark section > div.content-float {
  background-color: #171b1d;
  border: 1px solid #1e282c;
}

.device-list {
  text-align: left;
  height: 230px;
  margin-right: -10px;
  padding-right: 10px;
  overflow-y: auto;
}

.device-list > div:hover {
  background-color: whitesmoke;
}

.skindark .device-list > div:hover {
  background-color: #222d32;
}

.device-list > div:first-child,
.device-list > div:last-child {
  color: gray;
  font-weight: 600;
}

.skin-dark .device-list > div:first-child,
.skin-dark .device-list > div:last-child {
  color: #b8c7ce;
}

.box.box-primary.box-solid {
  border-top-color: #d2d6de;
}

.box {
  height: 250px;
}

.box-body > .bottom-options {
  display: none;
  position: absolute;
  bottom: 0;
}

::v-deep .box-stats .box-body {
  height: 220px;
}
</style>
