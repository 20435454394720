<template>
  <div class="form-group form-group-sm" v-if="dataTemplate">
    <label>
      {{ $t(label) }}
      <ToolTip :title="$t(hint)" v-if="hint" />
    </label>
    <JSONPathPicker
      entryType=""
      :append="true"
      :curlyBracked="true"
      :entry="dataTemplate"
      v-model="expression"
    />
  </div>
</template>

<script>
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import SelectableObjects from "@/assets/dashboard/selectable_objects.json";
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "ControlExpressionForm",
  components: {
    JSONPathPicker,
    ToolTip
  },
  props: {
    value: {
      type: String,
      required: true,
      default: ""
    },
    dataId: {
      type: [String, Number],
      required: false,
      default: ""
    },
    label: {
      type: String,
      required: false,
      default: "expression"
    },
    hint: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      iExpression: this.value || ""
    };
  },
  computed: {
    expression: {
      set(value) {
        this.iExpression = value;
        this.$emit("input", this.iExpression);
      },
      get() {
        return this.iExpression || "";
      }
    },
    equipmentData() {
      return this?.dataId
        ? (this.$store.getters["dashboard/dataList"] || []).find(
            ({id}) => id == this?.dataId
          )
        : null;
    },
    dataTemplate() {
      let entry = {system: this.$store.getters.systemProperties};
      if (this?.dataId) {
        var data = JSON.parse(JSON.stringify(SelectableObjects.data));
        if (this.equipmentData) {
          this.$utils.copyIfExists(
            data,
            JSON.parse(JSON.stringify(this.equipmentData))
          );
        }
        entry = {...entry, ...{data: data}};
      }
      return entry;
    }
  },
  methods: {
    buildExpression(source) {
      this.expression = source.replace(/\$\('[\d]+/, "$('.");
      this.$utils.notifyUser(this, this.$t("titles.expression_created"));
    }
  }
};
</script>

<style scoped></style>
