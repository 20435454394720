import ListTablePanel from "@/components/list-table-panel.vue";
export default {
  name: "ListTablePanelBase",
  components: {
    ListTablePanel
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => null
    },
    panel: {
      type: Object,
      required: false,
      default: () => null
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    },
    entrySource: {
      type: Array,
      required: false,
      default: null
    }
  },
  data() {
    return {
      cid: 0
    };
  },
  computed: {
    showAllDevices() {
      return this?.panel?.options?.showAllDevices ?? false;
    },
    isReady() {
      return this.panel && this.$store.getters.isReady;
    },
    equipmentList() {
      return (
        this.entrySource ||
        (this.$store.getters["equipmentList"] || []).filter(
          ({base_model}) => !base_model
        )
      );
    },
    connectorList() {
      return !this.showAllDevices && this?.equipment?.id
        ? this.equipmentList.filter(
            ({id}) => parseInt(id) == parseInt(this.equipment.id)
          )
        : this.equipmentList;
    }
  },
  watch: {
    showAllDevices(n, o) {
      if (n != o) this.cid++;
    }
  },
  methods: {
    onSelectItem(item) {
      if (this.mode == "editor") return;
      if (this.equipment) {
        this.$store.commit("SET_DEVICE_ID", (item && item?.device?.id) || "");
      } else {
        if (item) {
          this.$router.push("/dashboard/equipment/" + item.id);
        }
      }
    },
    onSelectDataCell($event) {
      if (this.mode == "editor" || !$event.item || !$event.field) return;
      const item = $event.item;
      const field = $event.field;
      if (this.equipment) {
        this.$store.commit("SET_DEVICE_ID", (item && item?.device?.id) || "");
      } else {
        // TODO: column should have a "url" configuration instead
        if (/\.device/.test(field?.column?.expression || "")) {
          let device = item?.device || null;
          let screenId = device?.screen_id || item?.screen_id || null;
          if (device && screenId) {
            this.$router.push(
              `/dashboard/equipment/${item.id}/${screenId}?device_id=${device.id}`
            );
            return;
          }
        }
        this.onSelectItem(item);
      }
    }
  },
  created() {},
  beforeDestroy() {
    if (this.equipment) {
      this.$store.commit("SET_DEVICE_ID", "");
    }
  }
};
