<template>
  <div class="me">
    <PanelTypeTitle
      :title="originalTitle"
      buttonLabel="specific"
      buttonIcon="fa fa-sliders"
      @edit="$emit('editDetails')"
    >
      <template #title>
        <div>
          <span> {{ $tc(originalTitle, 1) }} </span>
          <PanelPosition :panelName="panelName" class="panel-at" />
        </div>
      </template>
    </PanelTypeTitle>
    <TogglePanel
      v-if="persistent"
      :title="toggleTitle"
      :icon="{
        before: 'fa fa-gear',
        collapse: 'fa-minus',
        expand: 'fa-plus'
      }"
      :persistent="persistent"
    >
      <slot></slot>
    </TogglePanel>
    <slot v-else></slot>
  </div>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import Panels from "@/assets/dashboard/panels.json";
import PanelTypeTitle from "@/components/control-sidebar/property-editors/panel-type-title.vue";
import PanelPosition from "@/components/control-sidebar/property-editors/panel-position.vue";
import messages from "@/i18n/dashboard-panels.js";
export default {
  name: "PanelTopPropertiesContainer",
  props: {
    panel: {
      type: Object,
      required: false,
      default: null
    },
    toggleTitle: {
      type: String,
      required: false,
      default: "properties"
    },
    persistent: {
      type: String,
      required: false,
      default: "toogle_generic_panel"
    }
  },
  components: {
    TogglePanel,
    PanelTypeTitle,
    PanelPosition
  },
  computed: {
    originalTitle() {
      if (!this.panel) return "";
      const defPanel =
        Panels.find((p) => p.template.template == this?.panel?.template) ||
        null;
      return defPanel && defPanel?.title || "unknown";
    },
    panelName() {
      return this?.panel?.name || "unknown";
    }
  },
  i18n: { messages }
};
</script>

<style scoped>
.me {
  margin-bottom: 10px;
  position: relative;
}
.panel-at {
  font-weight: normal;
  color: #929292;
  margin-right: 6px;
  font-size: 80%;
  vertical-align: super;
}

.skin-dark .panel-at {
  color: #728188;
}
</style>
