<template>
  <portal to="pagezoom">
    <div class="zoom-ctrl no-select">
      <div class="">
        <div @click.stop.prevent="zoomIt(1)">
          <i class="fa fa-search-minus"></i>
        </div>
        <div class="zoom-options">
          <select v-model="scale" class="text-center">
            <option :value="v" v-for="v in options" :key="v"> {{ v }}x </option>
          </select>
        </div>
        <div @click.stop.prevent="zoomIt(-1)">
          <i class="fa fa-search-plus"></i>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: "ControlPageZoom",
  data() {
    return {
      options: [
        2,
        1.9,
        1.8,
        1.6,
        1.5,
        1.3,
        1.1,
        1.0,
        0.9,
        0.8,
        0.6,
        0.5,
        0.3,
        0.1
      ]
    };
  },
  computed: {
    scale: {
      set(value) {
        this.$root.editorZoom = value;
      },
      get() {
        return this.$root.editorZoom;
      }
    }
  },
  methods: {
    zoomIt(pos) {
      var searchedValue = this.options.reduce((p, c) =>
        Math.abs(c - this.scale) < Math.abs(p - this.scale) ? c : p
      );
      let i = this.options.findIndex((value) => value == searchedValue);
      if (i < 0 || i > this.options.length - 1) return;
      i =
        i + pos < 0
          ? 0
          : i + pos > this.options.length - 1
          ? this.options.length - 1
          : i + pos;
      this.scale = this.options[i];
    }
  }
};
</script>

<style scoped>
.zoom-ctrl {
  position: fixed;
  background-color: #f5f5f517;
  width: 120px;
  bottom: 20px;
  left: 60px;
  font-size: 17pt;
  opacity: 0.5;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px grey;
}

.skin-dark .zoom-ctrl {
  color: #728188;
  background-color: #b8c7ce17;
  box-shadow: 0px 1px 3px #171b1d;
}

.zoom-ctrl:hover {
  opacity: 1;
}

.zoom-ctrl > div {
  display: flex;
  justify-content: space-between;
}

.zoom-ctrl > div > div:hover {
  cursor: pointer;
  color: rgb(136, 95, 19);
}

.skin-dark .zoom-ctrl > div > div:hover {
  color: #fff;
}

select {
  width: 100%;
  border: 0;
  background-color: transparent;
  font-size: 80%;
}

.skin-dark select * {
  color: #2c3b41;
  background-color: #2c3b41;
}

select:hover {
  cursor: pointer;
}

select:focus {
  border: 0;
  outline: 0;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>
