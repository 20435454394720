<template>
  <div>
    <div class="toggle">
      <span
        class="toggle-title"
        :class="commitment == 'yearly' ? 'disabled' : ''"
        @click.stop.prevent="commitment = 'monthly'"
        >{{ $t("monthly") }}</span
      >
      <span @click.stop.prevent="toggleCommitment">
        <i
          class="fa fa-toggle-off toggle-left"
          v-if="commitment == 'monthly'"
        />
        <i class="fa fa-toggle-off toggle-right" v-else />
      </span>
      <span
        class="toggle-title"
        :class="commitment == 'monthly' ? 'disabled' : ''"
        @click.stop.prevent="commitment = 'yearly'"
        >{{ $t("yearly") }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "UserPlanCommitmentToggle",
  components: {
  },
  props: {
    value: {
      type: String,
      required: false,
      default: "monthly"
    }
  },
  computed: {
    commitment: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    toggleCommitment() {
      this.commitment = this.commitment == "monthly" ? "yearly" : "monthly";
    }
  }
};
</script>

<style scoped>
.toggle {
  color: #4a75e3;
  font-size: 2.1rem;
  white-space: nowrap;
}

.toggle:hover {
  cursor: pointer;
  opacity: 0.8;
}

.toggle span {
  padding: 0 2px;
}

.toggle span.toggle-title {
  font-size: 80%;
}

.toggle span.disabled {
  color: gray;
}
.skin-dark .toggle {
  color: #b8c7ce;
}
.skin-dark .toggle span.disabled {
  opacity: 0.8;
}
.toggle-left {
  transform: rotate(0deg);
}

.toggle-right {
  padding-bottom: 1px;
  transform: rotate(180deg);
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.commitment-info {
  font-size: 72%;
  text-align: center;
  margin-top: 6px;
  color: #4a75e3;
  font-family: inherit;
  white-space: nowrap;
}
</style>
