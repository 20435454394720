<template>
  <div class="me" v-if="alertInfo && icon" v-on:click.prevent.stop="onClick">{{ user.user_profile.css_theme }}
    <div v-if="inline">
      <span>{{ title }}</span>
    </div>
    <div
      v-else
      class="icon"
      v-on:click.prevent.stop="onClick"
      v-bind:title="title"
    >
      <img v-if="icon.indexOf('.') > 1" v-bind:src="icon" class="img-alert" />
      <i v-else :class="icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractAlertIcon",
  props: {
    visibility: {
      type: String,
      default: () => "profile",
      required: false
    },
    inline: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"]
    },
    isDarkTheme() {
      return this.user?.user_profile?.portal_data?.css_theme == "skin-dark";
    },
    icon() {
      /*
          as requested icons must be hardcoded when not provided
          0 = Normal - no icon
          1 = Atenção
          2 = Bloqueado
          */
      if (this.alertInfo) {
        if (this.alertInfo.icon) {
          return this.alertInfo.icon;
        } else if (this.alertInfo.state == "1") {
          if (this.isDarkTheme)
            return "/static/common/images/alert-gray.svg";
          return "/static/common/images/alert-black.svg";
        } else if (this.alertInfo.state == "2") {
          if (this.isDarkTheme)
            return "/static/common/images/alert-gray-red.svg";
          return "/static/common/images/alert-black-red.svg";
        }
      }
      return "";
    },
    title() {
      if (this.alertInfo) {
        if (this.alertInfo.title) {
          return this.$t(this.alertInfo.title);
        } else if (this.alertInfo.state == "1") {
          if (this.isRoot) {
            return this.$t("contract_state_alerts.running_out_of_resources");
          } else {
            return (
              this.$t("contract_state_alerts.contract_requires_attention") +
              " " +
              this.$t("contract_state_alerts.please_contact_admin")
            );
          }
        } else if (this.alertInfo.state == "2") {
          if (this.isRoot) {
            return this.$t("contract_state_alerts.balance_is_not_enough");
          } else {
            return this.$t("contract_state_alerts.please_contact_admin");
          }
        }
      }
      return this.$t("contract_state_alerts.please_contact_admin");
    },
    contractStates() {
      return this.$root.config.contract_status_indicator || [];
    },
    alertInfo() {
      let entry = null;
      if (this.contractStateId != -1) {
        entry =
          this.contractStates.find((i) => i.state == this.contractStateId) ||
          null;
        entry = entry && this.isVisible(entry) ? entry : null;
      }
      return entry;
    },
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    isRoot() {
      return (this.$store.getters["user/loggedUser"] || {}).is_root || false;
    },
    contractStateId() {
      return this.contract.alerts_info ? this.contract.alerts_info.number : -1;
    }
  },
  watch: {
    alertInfo: {
      handler(n) {
        this.$emit("input", n ? true : false);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    isVisible(entry) {
      /*
        "visibility": {
          "owner": {
            "profile": true,
            "connector": true
          },
          "member": {
            "profile": false,
            "connector": false
          }
        }
        */
      if ("visibility" in entry) {
        let key = this.isRoot ? "owner" : "member";
        if (
          key in entry.visibility &&
          this.visibility in entry.visibility[key]
        ) {
          return entry.visibility[key][this.visibility];
        }
      }
      return true;
    },
    onClick() {
      if (this.isRoot && this.alertInfo && this.alertInfo.url) {
        this.$router.push(this.alertInfo.url);
      }
    }
  }
};
</script>

<style scoped>
.me {
  display: inline-block;
}

.icon {
  max-width: 25px;
  max-height: 25px;
}
.img-alert {
  width: 100%;
  border-radius: 0;
  margin: -4px 10px 0 0;
  vertical-align: middle;
  width: 17px;
}
</style>
