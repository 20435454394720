<template>
    <div class="hsy-dropdown no-select" :class="cls">
        <div class="btn btn-default" @click="autoShow">
            <slot>
                <i class="fa fa-filter"></i>
            </slot>
        </div>
        <transition
            name="fadeIn"
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
        >
            <div class="list" v-show="isShow" :style="listContainerStyle">
                <div class="inner" v-if="!grouped" ref="listContainer">
                    <div
                        class="item item-all"
                        :title="$tc('all', 1)"
                        @click.stop.prevent="toggleAll"
                        v-if="showAll"
                    >
                        <input
                            type="checkbox"
                            id="dropdown_all"
                            :checked="isAllSelected"
                        />
                        <label for="dropdown_all">{{ $tc("all", 1) }}</label>
                    </div>
                    <div
                        class="item"
                        v-for="(item, index) in items"
                        v-bind:key="index"
                        :title="`${item.title || item.label}\n${$t(
                            'ctrl_click_for_more_options'
                        )}`"
                        :data-title="item.label"
                        @click="!multiple && itemClicked($event, item)"
                        :class="{ selected: item.selected }"
                    >
                        {{ appendIdx(item) }}
                        <div v-if="multiple">
                            <input
                                type="checkbox"
                                :disabled="item.disabled"
                                :checked="item.selected"
                                @change="checkboxChanged(item)"
                                :id="id(item)"
                            />
                            <label
                                :for="id(item)"
                                @click="itemClicked($event, item)"
                                >{{ item.label }}</label
                            >
                        </div>
                        <div v-else>
                            {{ item.label }}
                        </div>
                    </div>
                </div>
                <div class="inner" v-else>
                    <div v-if="grouped">
                        <div
                            v-for="(group, ix1) in items"
                            v-bind:key="ix1"
                            class="group"
                        >
                            <h3>{{ group.label }}</h3>
                            <div
                                class="item"
                                v-for="(item, ix2) in group.children"
                                v-bind:key="ix2"
                                :data-title="item.label"
                                @click="!multiple && itemClicked($event, item)"
                                :class="{ selected: item.selected }"
                            >
                                {{ appendIdx(item) }}
                                <div v-if="multiple">
                                    <input
                                        type="checkbox"
                                        :disabled="item.disabled"
                                        :checked="item.selected"
                                        @change="checkboxChanged(item)"
                                        :id="id(item)"
                                    />
                                    <label
                                        :for="id(item)"
                                        @click="itemClicked($event, item)"
                                        >{{ item.label }}</label
                                    >
                                </div>
                                <div v-else>
                                    {{ item.label }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer" v-if="applyButton">
                    <ToolTip :title="$t('hints.dropdown')" />
                    <span class="btn btn-xs btn-default" v-on:click="apply">
                        <i class="fa fa-check"></i> OK
                    </span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";

const EMPTY_FN = () => {};

let idx = 0;

export default {
    name: "Dropdown",
    components: {
        ToolTip
    },
    data() {
        return {
            selected: [],
            isShow: false,
            items: [],
            rect: null,
            pos: this.position
        };
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        grouped: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "Please choose"
        },
        width: {
            type: Number,
            default: 120
        },
        fixListWidth: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: "left"
        },
        cbChanged: {
            type: Function,
            default: EMPTY_FN
        },
        cbItemChanged: {
            type: Function,
            default: EMPTY_FN
        },
        cbCustomSelectedText: {
            type: Function,
            default: EMPTY_FN
        },
        applyButton: {
            type: Boolean,
            default: true
        },
        showAll: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cls() {
            let c = {
                grouped: this.grouped,
                multiple: this.multiple
            };
            return c;
        },
        selectedText() {
            let text = "";
            let fn = (selected) => selected.map((e) => e.label).join(", ");
            if (this.cbCustomSelectedText !== EMPTY_FN) {
                text = this.cbCustomSelectedText(this.selected, fn);
            } else {
                text = fn(this.selected);
            }
            return text === "" ? this.placeholder : text;
        },
        listContainerStyle() {
            let style = {};
            if (this.fixListWidth && this.width) {
                let width = `${this.width}`.replace(/px/gi, "");
                style.width = `${width}px`;
            } else {
                style.width = "auto";
            }
            if (this.pos == "left") {
                style["left"] = "0px";
            } else if (this.pos == "right") {
                style["right"] = "0px";
            } else if (this.pos == "auto" && this.rect) {
                let btn = this?.$el?.getBoundingClientRect() || null;
                if (btn && this.rect.width > btn.width + btn.x) {
                    style["left"] = `-${btn.x}px`;
                } else {
                    style["right"] = "0px";
                }
            }
            return style;
        },
        isAllSelected() {
            return !this.data.filter(({ selected }) => !selected).length;
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(val) {
                this.selected = this.data.filter((d) => d.selected);
                this.items = this.data.slice(0);
            }
        },
        isShow: {
            handler(val) {
                this.$emit("visible", val);
                if (val && this.items.length) {
                    if (window.innerWidth < 768) {
                        this.$nextTick(() => {
                            this.$set(
                                this,
                                "rect",
                                this?.$refs?.listContainer?.getBoundingClientRect() ||
                                    null
                            );
                            this.pos = "auto";
                        });
                    } else {
                        this.pos = this.position;
                    }
                }
                if (this._hiddenBy) {
                    this._hiddenBy.style.overflow = val ? "visible" : "hidden";
                }
            },
            deep: true
        }
    },
    methods: {
        id(item) {
            return "hsy-dropdown-item-" + item._idx;
        },
        appendIdx(item) {
            if (item._idx === undefined) {
                item._idx = ++idx;
            }
        },
        autoShow() {
            this.isShow = !this.isShow;
        },
        setupTitleIfNeeded() {
            if (!this.fixListWidth) return;
        },
        autoHide(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isShow = false;
            }
        },
        findSelected() {
            if (!this.grouped) {
                return this.data.filter((d) => d.selected === true);
            }
            let items = [];
            return this.data
                .reduce((pre, e) => {
                    pre = pre.concat(e.children);
                    return pre;
                }, items)
                .filter((d) => d.selected === true);
        },
        itemClicked($event, item) {
            if ($event.ctrlKey) {
                this.data.forEach((d) => (d.selected = false));
                this.selected = [];
                return;
            }
            if (!this.multiple) {
                if (this.selected.indexOf(item) === -1) {
                    this.findSelected().forEach((d) => (d.selected = false));
                    item.selected = true;
                    this.selected.pop();
                    this.selected.push(item);

                    //if (this.cbChanged !== EMPTY_FN) {
                    //  this.cbChanged(this.findSelected())
                    //}
                    this.$emit("itemChanged", this.findSelected());
                }
                this.$nextTick(() => {
                    this.isShow = false;
                });
            }
        },
        checkboxChanged(item) {
            if (this.selected === null) {
                this.selected = [];
            }

            let id = this.id(item);
            item.selected = document.querySelector("#" + id).checked;

            if (!item.selected) {
                this.selected = this.selected.filter((d) => d !== item);
            } else {
                this.selected.push(item);
            }

            if (this.cbItemChanged !== EMPTY_FN) {
                this.cbItemChanged(item);
            }
            //if (this.cbChanged !== EMPTY_FN) {
            //  this.cbChanged(this.selected)
            //}
            this.$emit("changed", this.selected);
        },
        apply() {
            // console.log(this.selected.map(({ selected, label }) => `${label} ${selected}`))
            this.$emit("apply", this.selected);
            this.autoShow();
        },
        toggleAll() {
            let all = this.isAllSelected;
            this.data.forEach((d) => (d.selected = !all));
            this.selected = [...this.data.filter(({ selected }) => selected)];
            this.$emit("changed", this.selected);
        }
    },
    updated() {
        this.setupTitleIfNeeded();
    },
    mounted() {
        this.setupTitleIfNeeded();
        document.addEventListener("click", this.autoHide, false);
        let _p = this.$el.parentElement;
        while (_p.style.overflow !== "hidden") {
            _p = _p.parentElement;
            if (!_p) break;
        }
        if (_p) {
            this._hiddenBy = _p;
            // console.log(_p);
        }
    },
    destroyed() {
        document.removeEventListener("click", this.autoHide, false);
    }
};
</script>

<style scoped>
.fix-left {
    left: 0px;
}
.fix-right {
    right: 0px;
}
.hsy-dropdown {
    font: 10px "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", SimSun, sans-serif;
    display: inline-block;
    position: relative;
    vertical-align: top;
}

.hsy-dropdown > .list {
    position: absolute;
    font-size: 1.2em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 3px;
    z-index: 100;
    max-width: 100vw;
}

.hsy-dropdown > .list > .inner {
    padding: 5px;
    border-bottom: 1px solid #f9f9f9;
    max-height: 300px;
    max-width: 390px;
    overflow-y: auto;
}

.hsy-dropdown > .list .item {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    cursor: pointer;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    white-space: nowrap;
    background-position: 5px center;
}

.hsy-dropdown > .list .item:hover,
.hsy-dropdown.multiple > .list .item:hover {
    background-color: #eee;
    border-radius: 3px;
}

.hsy-dropdown > .list .item.selected {
    background-color: #7d8699;
    border-radius: 3px;
    color: #fff;
}

.hsy-dropdown .animated {
    animation-duration: 0.35s;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.hsy-dropdown .fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.hsy-dropdown .fadeOut {
    animation-name: fadeOut;
}

.hsy-dropdown.multiple .item {
    color: #7d8699;
    padding: 0 5px;
}

.hsy-dropdown.multiple .item.selected {
    background: none;
    padding: 0 5px;
    color: #7d8699;
}

.hsy-dropdown.multiple .item input {
    display: none;
}

.hsy-dropdown.multiple .item input + label {
    display: block;
    padding-left: 23px;
    background: url("assets/chk.svg") no-repeat 0px center;
    margin: 0;
    cursor: pointer;
}

.hsy-dropdown.multiple .item input:checked + label {
    background: url("assets/chked.svg") no-repeat 0px center;
}

.hsy-dropdown.multiple .item input:disabled + label {
    color: #ccc;
    cursor: not-allowed;
}

.hsy-dropdown .group {
    font-size: 10px;
}

.hsy-dropdown .group h3 {
    font-size: 1.2em;
    font-weight: 500;
    padding-left: 5px;
    cursor: default;
}

.hsy-dropdown .group .item {
    font-size: 1.2em;
}

.skin-dark .hsy-dropdown {
    color: #b8c7ce;
}

.skin-dark .hsy-dropdown > .list {
  box-shadow: 0 0 5px #171b1d;
  background: #2c3b41;
}

.skin-dark .hsy-dropdown > .list > .inner {
  border-bottom: 1px solid #1e282c;
}

.skin-dark .hsy-dropdown > .list .item.selected,
.skin-dark .hsy-dropdown > .list .item:hover,
.skin-dark .hsy-dropdown.multiple > .list .item:hover {
  background-color: #222d32;
  color: #fff;
}

.skin-dark .hsy-dropdown.multiple .item {
    color: #b8c7ce;
}

.btn-xs {
    padding: 1px 10px;
}
.btn-success {
    background-color: #398439;
}
.footer {
    padding: 5px 0;
    min-width: 120px;
    text-align: center;
}
.footer > i {
    color: #666;
    margin-right: 10px;
}
.skin-dark .footer > i {
    color: #b8c7ce;
}
.item-all {
    margin: -5px -5px -2px -5px;
    border-bottom: 1px solid lightgray;
}

.skin-dark .item-all {
    border-bottom: 1px solid #1e282c;
}

.no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>
