<template>
  <div class="me html2canvas-ignore">
    <DashboardLauncher
      class="inner-component"
      v-if="panel"
      :config="panel.options"
    ></DashboardLauncher>
  </div>
</template>

<script>
import DashboardLauncher from "@/components/dashboard-launcher.vue";
export default {
  name: "DashboardLauncherPanel",
  props: {
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    DashboardLauncher
  }
};
</script>

<style scoped>
.me {
  padding: 3px 6px;
  margin: 0;
  overflow: visible !important;
}
.me > .inner-component {
  width: 100%;
}
</style>
