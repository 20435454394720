<template>
  <div class="me" v-if="show">
    <div class="event-title">
      <div>
        <div
          class="pull-left event-title-text clicable no-select"
          v-on:click.stop.prevent="collapsed = !collapsed"
        >
          <i class="fa fa-flash"></i>
          <span class="">{{ $t("synoptic.events.on") }} &nbsp;</span>
          <span class="">{{
            $tc(`synoptic.events.${eventName}`, 2).toLowerCase()
          }}</span>
        </div>
        <div class="pull-right">
          <i
            class="fa fa-rocket toolbar-icon"
            :class="{
              'text-primary': tab == 'actions',
              disabled: !tabs.actions.enabled,
              clicable: tabs.actions.enabled
            }"
            :title="tabs.actions.hint"
            v-on:click.stop.prevent="setActiveTab('actions')"
          />
          <i
            v-if="eventName == 'click'"
            class="fa fa-warning toolbar-icon"
            :class="{
              'text-primary': tab == 'confirmation',
              disabled: !tabs.confirmation.enabled,
              clicable: tabs.actions.enabled
            }"
            :title="tabs.confirmation.hint"
            v-on:click.stop.prevent="setActiveTab('confirmation')"
          />
          <i
            class="fa fa-trash toolbar-icon clicable dangerous"
            v-on:click.stop.prevent="onDelEvent"
            :title="$t('remove')"
          />
          <i
            class="fa toolbar-icon clicable"
            :class="collapsed ? 'fa-angle-right' : 'fa-angle-down'"
            v-on:click.stop.prevent="collapsed = !collapsed"
          />
        </div>
      </div>
      <div class="event-title-text-bottom"></div>
    </div>
    <ControlEventDataListForm
      v-if="requireDataSelection && !collapsed"
      :control="control"
      :eventName="eventName"
    />
    <div class="event-content" v-if="!collapsed">
      <div v-if="ctrlComponent.on[eventName].actions.length">
        {{ tabs[tab].title }}
      </div>
      <div
        class="action-panel"
        v-if="tab == 'actions' && !requireDataSelection"
      >
        <ControlEventActionForm
          v-for="(action, iAction) in ctrlComponent.on[eventName].actions"
          :key="iAction"
          :eventName="eventName"
          :action="action"
          :index="iAction"
          :open="iAction === newActionIndex"
          @delAction="onDelAction"
          @expand="onExpandAction(iAction, true)"
          @collapse="onExpandAction(iAction, false)"
          @change="onActionChange(iAction)"
        />
        <div class="action-selector" v-if="expandedActions.length == 0">
          <ControlActionSelector
            :control="ctrlComponent"
            :eventName="eventName"
            :exclude="eventActions"
            @addAction="onAddAction"
          />
        </div>
      </div>
      <div v-if="tab == 'confirmation'">
        <AlertForm v-if="eventName == 'click'" v-model="confirmation" />
      </div>
    </div>
  </div>
</template>

<script>
import Events from "@/assets/dashboard/events.json";
import ControlEventActionForm from "@/components/editor/control-event-action-form.vue";
import ControlActionSelector from "@/components/editor/control-action-selector.vue";
import ControlEventDataListForm from "@/components/editor/control-event-data-list-form.vue";
import AlertForm from "@/components/editor/alert-form.vue";

export default {
  name: "ControlEventForm",
  props: {
    control: {
      type: Object,
      required: true
    },
    eventName: {
      type: String,
      required: true
    }
  },
  components: {
    ControlEventActionForm,
    ControlActionSelector,
    ControlEventDataListForm,
    AlertForm
  },
  data() {
    return {
      tab: "actions",
      show: true,
      collapsed: false,
      showProcessArea: false,
      newActionIndex: -1,
      expandedActions: []
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    template() {
      return this?.draft?.template;
    },
    screenId() {
      return this.draft?.screenId || null;
    },
    isSynopticComponent() {
      return this.control.synopticComponent;
    },
    ctrlComponent() {
      return this.isSynopticComponent
        ? this.control.synopticComponent
        : this.control;
    },
    hasSelectedData() {
      return (this.ctrlComponent?.on[this.eventName]?.data_list || []).length;
    },
    requireDataSelection() {
      return "data_list" in (this.ctrlComponent?.on[this.eventName] || {});
    },
    processArea: {
      get() {
        return {
          id: this.ctrlComponent.on[this.eventName].process_area_id
        };
      },
      set({ id }) {
        let control = JSON.parse(JSON.stringify(this.control));
        if (this.isSynopticComponent) {
          control.synopticComponent.on[this.eventName].process_area_id = id;
          this.$store.dispatch("synoptic/updateControl", {
            id: control.id,
            control: control
          });
        } else {
          console.log("todo: processArea");
        }
      }
    },
    eventActions() {
      return (
        (this.ctrlComponent.on[this.eventName] || Events[this.eventName])
          ?.actions ?? []
      ).map(({ type }) => type);
    },
    controlId() {
      return this?.control?.id || "";
    },
    confirmation: {
      set(value) {
        if (this.isSynopticComponent) {
          let control = JSON.parse(JSON.stringify(this.control));
          control.synopticComponent.on[this.eventName].confirmation = value;
          this.$store.dispatch("synoptic/updateControl", {
            id: control.id,
            control: control
          });
        } else {
          console.log("TODO: confirmation");
        }
      },
      get() {
        return this.ctrlComponent?.on[this.eventName]?.confirmation || null;
      }
    },
    tabs() {
      return {
        actions: {
          title: this.$tc("synoptic.events.execute_action", 2),
          hint: this.$tc("synoptic.events.execute_action", 2),
          icon: "fa fa-rocket",
          enabled: true
        },
        confirmation: {
          title: this.$t("confirmation"),
          hint:
            this.eventName != "dataChange"
              ? this.$t("require_confirmation")
              : this.$t("not_available"),
          icon: "fa fa-warning",
          enabled: this.eventName != "dataChange"
        }
      };
    }
  },
  watch: {
    controlId() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    collapsed: {
      handler(n) {
        this.$emit("collapsed", n);
      },
      immediate: true
    }
  },
  methods: {
    saveDraft(template) {
      this.$store.dispatch("dashboard/saveDraft", {
        screenId: this.screenId,
        template: template
      });
    },
    onExpandAction(index, option) {
      if (option) {
        this.expandedActions.push(index);
      } else {
        let ix = this.expandedActions.indexOf(index);
        if (ix >= 0) {
          this.expandedActions.splice(ix, 1);
        }
      }
    },
    onActionChange() {
      if (!this.template || this.isSynopticComponent) return;
      let template = JSON.parse(JSON.stringify(this.template));
      this.saveDraft(template);
    },
    onAddAction(action) {
      let evt = null;
      if (this.isSynopticComponent) {
        let control = JSON.parse(JSON.stringify(this.control));
        evt =
          control.synopticComponent.on[this.eventName] ||
          Events[this.eventName];
        evt.actions.push(action);
        control.synopticComponent.on[this.eventName] = evt;
        this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
          id: control.id,
          control: control
        });
      } else {
        if (!this.template) return;
        let template = JSON.parse(JSON.stringify(this.template));
        evt = template.on[this.eventName] || Events[this.eventName];
        evt.actions.push(action);
        template.on[this.eventName] = evt;
        this.saveDraft(template);
      }
      this.newActionIndex = evt.actions.length - 1;
    },
    onDelAction(index) {
      let evt = null;
      let control = JSON.parse(JSON.stringify(this.control));
      if (this.isSynopticComponent) {
        evt =
          control.synopticComponent.on[this.eventName] ||
          Events[this.eventName];
        evt.actions.splice(index, 1);
        control.synopticComponent.on[this.eventName] = evt;
        this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
          id: control.id,
          control: control
        });
      } else {
        if (!this.template) return;
        let template = JSON.parse(JSON.stringify(this.template));
        evt = template.on[this.eventName] || Events[this.eventName];
        evt.actions.splice(index, 1);
        this.saveDraft(template);
      }
      this.newActionIndex = -1;
      if (!evt.actions.length && this.collapsed) {
        this.collapsed = false;
      }
    },
    onDelEvent() {
      this.$utils
        .confirm(this, "you_wont_be_able_to_revert_this")
        .then((response) => {
          if (response) {
            this.$emit("delEvent", this.eventName);
            this.collapsed = true;
          }
        });
    },
    setActiveTab(tab) {
      if (this.tabs[tab].enabled) {
        this.tab = tab;
        this.collapsed = false;
      }
    }
  }
};
</script>

<style scoped>
.me {
  clear: both;
  padding-bottom: 10px;
}

.event-content {
  padding: 0px 10px 50px 5px;
  clear: both;
  /* background-color: whitesmoke; */
}

.action-panel {
}

.action-selector {
  /* margin-top: 20px; */
}

.event-title {
  clear: both;
  font-size: 14pt;
  z-index: 99;
}

.event-title-text {
  color: #204d74;
  font-weight: 600;
  letter-spacing: -1px;
}

.skin-dark .event-title-text {
  color: #b8c7ce;
}

.event-title-text > i {
  margin: 0 5px;
  font-size: 12pt;
}

.event-title-text > i:hover {
  cursor: pointer;
  opacity: 0.8;
}

.event-title-text-bottom {
  clear: both;
  border-bottom: 1px solid rgb(190, 190, 190);
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

i.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.dangerous:hover {
  color: #a94442;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.toolbar-icon {
  font-size: 78%;
  margin-left: 12px;
  min-width: 16px;
}
</style>