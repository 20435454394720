import { render, staticRenderFns } from "./synoptic-control.vue?vue&type=template&id=384b9cac&scoped=true&"
import script from "./synoptic-control.vue?vue&type=script&lang=js&"
export * from "./synoptic-control.vue?vue&type=script&lang=js&"
import style0 from "./synoptic-control.vue?vue&type=style&index=0&id=384b9cac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "384b9cac",
  null
  
)

export default component.exports