<template>
  <div class="alarm-configuration-display" v-if="$can('view', 'AlarmeAcesso')">
    <div class="table-container">
      <EquipmentAlarmAConfigurationTable
        ref="table"
        :equipment="equipment"
        :alarms="parsedAlarms"
        :panelOptions="panelOptions"
        :isEquipmentDashboard="isEquipmentDashboard"
        v-on:select="select"
        v-on:editButtonClicked="setEditAction"
        v-on:notificationButtonClicked="setNotificationAction"
        v-on:widthError="$emit('widthError')"
      >
        <template #no_alarms>
          <div
            v-if="busy"
            class=""
            style="min-height: 25px; margin-top: 25px"
          ></div>
          <div
            v-else
            class="alert text-center no-alarms"
          >
            <div class="h4">
              <i class="fa fa-attention"></i>{{ $t("alarm_list") }}
            </div>
            <p v-if="canShowAlarmList">
              {{ $t("there_are_no_alarms_configuration") }}
            </p>
            <p v-else>
              {{ $t("list_can_not_be_displayed_in_editor_mode") }}
            </p>
          </div>
        </template>
      </EquipmentAlarmAConfigurationTable>
    </div>
    <EquipmentAlarmAcknowledgementForm
      v-if="
        selected && selected.length && $can('manage', 'AlarmeReconhecimento')
      "
      v-bind:alarms="selected"
      v-bind:panelOptions="panelOptions"
      v-on:hide="onCloseAck()"
      v-on:notificationButtonClicked="setNotificationAction"
    />
    <EquipmentAlarmEditorForm
      v-if="alarm && action == 'edition' && $can('manage', 'AlarmeCadastro')"
      v-bind:alarms="[alarm]"
      v-on:hide="resetAlarm"
      v-on:refresh="$emit('refresh')"
    />
    <div class="overlay" v-if="busy">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <template v-if="notificationSetupType">
      <Modal
        v-if="notificationSetupType == 'standard'"
        :large="true"
        :open="selected_alarm_for_configuration != null"
        @update:open="
          selected_alarm_for_configuration = $event
            ? selected_alarm_for_configuration
            : null
        "
        :footer="false"
        :title="
          selected_alarm_for_configuration &&
          selected_alarm_for_configuration.name
        "
      >
        <template #content>
          <FormAlarm
            v-if="selected_alarm_for_configuration"
            :connector_id="selected_alarm_for_configuration.connector_id"
            :device_id="selected_alarm_for_configuration.device_id"
            :data_id="selected_alarm_for_configuration.data_id"
            :alarm_id="selected_alarm_for_configuration.id"
            :notificationOnly="true"
            @close="selected_alarm_for_configuration = null"
          />
        </template>
        <template #footer><span></span></template>
      </Modal>
      <div v-else-if="notificationSetupType == 'group'">
        <EquipmentAlarmNotificationForm
          v-if="selected_alarm_for_configuration"
          :alarms="[selected_alarm_for_configuration]"
          :defaultOptions="defaultOptions"
          @hide="selected_alarm_for_configuration = null"
        />
      </div>
    </template>
  </div>
</template>

<script>
import EquipmentAlarmAcknowledgementForm from "@/components/equipment-alarm-acknowledgement-form";
import EquipmentAlarmAConfigurationTable from "@/components/equipment-alarm-configuration-table";
import EquipmentAlarmEditorForm from "@/components/equipment-alarm-editor-form";
import FormAlarm from "@/components/registration/form-alarm.vue";
import EquipmentAlarmNotificationForm from "@/components/equipment-alarm-notification-form.vue";
import Modal from "@/components/widgets/modal.vue";
import messages from "@/i18n/alarm";
import {defaultAlarmLevelOption} from "@/components/registration/alarm-level-form.vue";
import {alarmDefs} from "@/services/alarm.js";

export default {
  name: "EquipmentAlarmConfigurationDisplay",
  props: {
    equipment: {
      type: Object,
      required: false,
      default: null
    },
    alarmList: {
      type: Array,
      required: false,
      default: () => []
    },
    filteredAlarmIdList: {
      type: Array,
      required: false,
      default: () => []
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    panelOptions: {
      type: Object,
      required: true
    },
    busy: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    EquipmentAlarmAcknowledgementForm,
    EquipmentAlarmAConfigurationTable,
    EquipmentAlarmEditorForm,
    Modal,
    FormAlarm,
    EquipmentAlarmNotificationForm
  },
  data() {
    return {
      title: "Equipment Alerts Configuration",
      alarm: null,
      action: "",
      selected: [],
      acks: [],
      selected_alarm_for_configuration: null,
      fields: null
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    defaultAlarmLevelOptions() {
      return this.$root.config.alarm.levels || [];
    },
    alarmLevelOptions() {
      let levels = this.$utils.distinct(
        (this.alarmList || [])
          .map(({level}) => (level || "").toUpperCase())
          .filter((level) => level != "")
      );
      return levels.map((level) => {
        let option = (
          this?.contract?.portal_data?.alarm_level_options || []
        ).find((item) => level == item.label.value.toUpperCase());
        if (!option) {
          option = defaultAlarmLevelOption();
          option.label.value = level;
        }
        return option;
      });
    },
    defaultOptions() {
      let dft =
        this?.$root?.config?.equipment_extended_properties?.notifications || {};
      dft = {
        timeout: dft.timeout ?? 5,
        groups: (dft?.groups ?? []).length
          ? [...dft?.groups]
          : [...Array(5).keys()].map((i) => ({
              name: `${this.$tc("group", 1)} ${i + 1}`,
              tags: [],
              fixed: false
            })),
        unit: dft?.unit || "min"
      };
      return dft;
    },
    notificationSetupType() {
      // returns: null | standard | group
      // field notification must be visible
      if ((this.fields || []).some(({name}) => name == "notification")) {
        return this?.$root?.config?.notification_setup_type || "standard";
      }
      return null;
    },
    canShowAlarmList() {
      return this?.panelOptions?.allAlarms && this.mode == "editor"
        ? false
        : true;
    },
    filteredAlarms() {
      if (!this.canShowAlarmList) return [];
      return this.filteredAlarmIdList.length
        ? this.alarmList.filter(
            ({id}) => this.filteredAlarmIdList.indexOf(id) >= 0
          )
        : []; //this.alarmList;
    },
    parsedAlarms() {
      let lst = (this.filteredAlarms || []).map((alarm) => {
        alarm.lastTransition = this.lastTransition(alarm);
        alarm.condition = this.condition(alarm);
        // - state_identity_number: número que identifica o estado de execução do comando (0:AGUARDANDO, 1:PROCESSANDO e 2: PROCESSADO);
        if (
          ("command" in alarm &&
            alarm.command.state_identity_number == this.command_wait) ||
          ("pending_command" in alarm &&
            (alarm.alarm_current_state.pending_command || false))
        ) {
          alarm.ackStatus = "waiting";
        } else if (
          alarm.acknowledgment_enabled &&
          (alarm.alarm_current_state.alarm_state.number ==
            alarmDefs.alarm_unack ||
            alarm.alarm_current_state.alarm_state.number ==
              alarmDefs.alarm_ret_unack)
        ) {
          alarm.ackStatus = "waiting_ack";
        } else if (!alarm.acknowledgment_enabled) {
          alarm.ackStatus = "ack_disabled";
        } else if (alarm.alarm_current_state.acknowledgment_state) {
          alarm.ackStatus = "acknowledged";
        } else if (
          alarm?.alarm_current_state?.state &&
          alarm.acknowledgment_enabled
        ) {
          alarm.ackStatus = "waiting_ack";
        } else {
          alarm.ackStatus = alarm?.alarm_current_state?.state
            ? "_unknown"
            : "normal";
        }
        alarm.criteria_order = `${this.condition(alarm)} ${alarm.limit}`;

        // make api return compatible with data format
        // current_value might not be an object at reduced endpoint
        if (alarm.data) {
          if (
            alarm.data.current_value !== null &&
            typeof alarm.data.current_value != "object"
          ) {
            alarm.data.current_value = {
              date_time: alarm.data?.current_value?.date_time || null,
              value: alarm.data.current_value ?? null
            };
          }
          // missing id at reduced endpoint
          alarm.data.id = alarm.data_id;
          if (
            alarm?.data?.value_format_type?.custom_format &&
            !alarm.data.custom_format
          ) {
            // wrong return from reduced endpoint
            alarm.data.custom_format =
              alarm.data.value_format_type.custom_format;
          }
          alarm.limitData = {
            current_value: {
              date_time: alarm.data?.current_value?.date_time || null,
              value: alarm.limit
            },
            // text_list: data.text_list,
            custom_format: alarm.data.custom_format || "",
            unity_label: alarm.data.unity_label || "",
            value_format_type: alarm.data.value_format_type
          };
        }
        // data dependency
        var data = (this.dataList || []).find(({id}) => id == alarm.data_id);
        if (data) {
          if (alarm.data) {
            // update alarm.data.current_value with latest data.current_value as it might be out-of-data
            if (
              data?.current_value &&
              (!alarm?.data?.current_value ||
                !alarm?.data?.current_value?.date_time ||
                data?.current_value?.date_time >
                  alarm?.data?.current_value?.date_time)
            ) {
              this.$set(alarm.data, "current_value", {
                ...data?.current_value
              });
            }
            // validates pending commands
            if (
              (alarm?.data?.pending_commands || []).length !=
              (data?.pending_commands || []).length
            ) {
              alarm.data.pending_command = data?.pending_command || false;
              this.$set(
                alarm.data,
                "pending_commands",
                JSON.parse(JSON.stringify(data?.pending_commands || []))
              );
              this.$set(
                alarm.data,
                "pending_mapping_write",
                (data?.pending_mapping_write &&
                  JSON.parse(
                    JSON.stringify(data?.pending_mapping_write || null)
                  )) ||
                  null
              );
            }
          } else {
            // state_only or default fetch api

            this.$set(alarm, "data", {
              name: data.name,
              current_value: data?.current_value && {
                date_time: data?.current_value?.date_time || null,
                value: data?.current_value?.value ?? null
              },
              // text_list: data.text_list,
              custom_format: data?.custom_format || "",
              unity_label: data?.unity_label || "",
              value_format_type: data?.value_format_type,
              text_list: data.text_list,
              pending_command: data?.pending_command || false,
              pending_commands: JSON.parse(
                JSON.stringify(data?.pending_commands)
              ),
              pending_mapping_write: JSON.parse(
                JSON.stringify(data?.pending_mapping_write || null)
              )
            });
          }
        }

        alarm.status_order =
          alarm.alarm_current_state.alarm_state.number > 0
            ? alarm.alarm_current_state.alarm_state.number
            : 4;

        // alarm.ackStatus = "waiting_ack";
        // row class
        alarm.class =
          "alarm-item" +
          (this.is_alarm_active(alarm) ? " bg-alarm-active" : "") +
          (this.is_ack_needed(alarm) ? " bg-ack-needed" : "") +
          (!alarm.enabled ? " disabled" : "");

        // reverse tree node name
        alarm.data_name = alarm?.data?.name || "";
        alarm.device_name = this.deviceById(alarm.device_id)?.name || "";
        alarm.connector_name =
          this.connectorById(alarm.connector_id)?.name || "";
        return alarm;
      });
      return lst;
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    deviceList() {
      return this.$store.getters["dashboard/deviceList"] || [];
    },
    isEquipmentDashboard() {
      return this.parentName == "EquipmentAlarmPanel";
    },
    parentName() {
      return this?.$parent?.$options?.name || "";
    }
  },
  watch: {
    busy(n, o) {
      if (o && !n) {
        this.acks = [];
      }
    }
  },
  methods: {
    select(alarm) {
      if (this.mode == "editor") return;
      if (alarm) {
        this.selected = [alarm];
      } else {
        this.selected = [];
      }
    },
    onCloseAck() {
      this.select(null);
      this.$forceUpdate();
    },
    setEditAction(alarm) {
      if (this.mode == "editor") return;
      this.$router.push(
        `/dashboard/edit/connector/${alarm.connector_id}/device/${alarm.device_id}/data/${alarm.data_id}/alarm/${alarm.id}`
      );
    },
    setNotificationAction(alarm) {
      if (this.mode == "editor") return;
      if (!this.fields && this.$refs.table.fields) {
        this.fields = this.$refs.table.fields;
        this.$nextTick(() => {
          this.selected_alarm_for_configuration = alarm;
        });
        return;
      }
      this.selected_alarm_for_configuration = alarm;
      return;
    },
    execute(action, alarm) {
      this.action = action;
      this.alarm = alarm;
    },
    resetAlarm() {
      this.action = "";
      this.alarm = null;
    },
    connectorById(qid) {
      return this.connectorList.find(({id}) => id == qid);
    },
    deviceById(qid) {
      return this.deviceList.find(({id}) => id == qid);
    },
    condition(alarm) {
      let id =
        (alarm.alarm_trigger_condition && alarm.alarm_trigger_condition.id) ||
        0;
      let item = this.$root.config.references.alarm_trigger_conditions.find(
        (i) => i.id == id
      );
      return (item && item?.comparison_operator) || "";
    },
    lastTransition(alarm) {
      return (
        (alarm &&
          alarm.alarm_current_state &&
          alarm.alarm_current_state.datetime_last_transition) ||
        ""
      );
    },
    is_alarm_active(alarm) {
      return (
        alarm?.alarm_current_state?.state ||
        alarm.alarm_current_state.alarm_state.number == alarmDefs.alarm_unack
      );
    },
    is_ack_needed(alarm) {
      return (
        !this.is_alarm_active(alarm) &&
        alarm.alarm_current_state.alarm_state.number ==
          alarmDefs.alarm_ret_unack
      );
    }
  },
  created() {
    this.acknowledgmentRefreshTimer = null;
  },
  destroyed() {
    if (this.acknowledgmentRefreshTimer) {
      clearInterval(this.acknowledgmentRefreshTimer);
      this.acknowledgmentRefreshTimer = null;
    }
  },
  mounted() {
    this.fields = (this.canShowAlarmList && this?.$refs?.table?.fields) || null;
  },
  i18n: {messages}
};
</script>

<style scoped>
.filter-options {
  margin: 0 0.5em;
}

.filter-options-small {
  font-size: 90%;
}

.filter-options *:not(:last-child) {
  margin-right: 0.8em;
}

.filter-option {
  display: inline-block;
  max-width: 100%;
  font-weight: 400;
}

.filter-option.active {
  color: #316ea2;
}

.table-container {
  overflow-x: auto;
}
.no-overflow {
  overflow: hidden;
}
.input-search {
  position: relative;
  margin-bottom: 0;
  margin-top: -5px;
}

.input-search input {
  background-color: transparent;
  margin-right: 22px;
}

.input-search .input-reset {
  position: absolute;
  width: 20px;
  right: 0px;
  top: 5px;
  z-index: 4;
}
.dropdown-toggle {
  padding: 0 8px;
  margin: 0;
  background-color: transparent;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-menu > li > a > i {
  width: 16px;
  text-align: center;
}

.alarm-configuration-display {
  /* height: 100%;
  display: flex;
  flex-direction: column; */
  height: inherit;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.alert.no-alarms {
  background-color: whitesmoke;
  margin-top: 25px
}

.skin-dark .alert.no-alarms {
  background-color: #222d32;
  color: #b8c7ce;
}

@media (min-width: 920px) {
  ::v-deep .modal-lg {
    min-width: 940px;
  }
}

@media (min-width: 1200px) {
  ::v-deep .modal-lg {
    min-width: 1200px;
  }
}
</style>
