<template>
  <section>
    <div class="row">
      <div class="col-xs-12">
        <label for="">{{ $tc("tab_label.custom_fields", 2) }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label class="checkbox-inline" for="showRequiredFields">
            <input
              type="checkbox"
              v-model="showRequiredFields"
              id="showRequiredFields"
            />
            <span>
              {{ $t("show") }} {{ $tc("required", 2).toLowerCase() }}
            </span>
          </label>
        </div>
        <div class="form-group">
          <label class="checkbox-inline" for="showOptionalFields">
            <input
              type="checkbox"
              v-model="showOptionalFields"
              id="showOptionalFields"
            />
            <span>
              {{ $t("show") }} {{ $tc("optional", 2).toLowerCase() }}
            </span>
          </label>
        </div>
        <AlertForm v-model="confirmation" />
        <div class="form-group">
          <label class="checkbox-inline" for="displayInline">
            <input
              type="checkbox"
              v-model="displayInline"
              id="displayInline"
            />
            <span>
              {{ $t("display_inline") }}
            </span>
          </label>
          <span class="pull-right clicable" v-if="displayInline">
            <i
              :class="
                !display_inline_collapsed
                ? 'fa fa-caret-square-o-down'
                : 'fa fa-caret-square-o-up'
              "
              @click.stop.prevent="
                display_inline_collapsed = !display_inline_collapsed
              "
            ></i>
          </span>
        </div>
        <div class="form-group" v-if="displayInline && display_inline_collapsed">
          <label for="columnsInline">
            {{ $t("columns_inline") }}
          </label>
          <input
            type="number"
            id="columnsInline"
            class="form-control"
            v-model="columnsInline"
            min="1"
            max="3"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <draggable v-model="filteredFields">
          <div class="row" v-for="(item, ix) in filteredFields" :key="ix">
            <div class="col-sm-12 move-item" :title="item.description">
              <div class="move-item move-item-icon hidden-xs hidden-sm">
                <i class="glyphicon glyphicon-option-vertical"></i>
                <i
                  class="btn btn-xs btn-default fa clicable"
                  :class="classVisible(item)"
                  @click="toggleVisible(item)"
                  :disabled="disabledVisible(item)"
                  :title="$t('panel_custom_field_visible_title')"
                ></i>
                <i
                  class="btn btn-xs btn-default fa clicable"
                  :class="classRequired(item)"
                  @click="toggleRequired(item)"
                  :disabled="disabledRequired(item)"
                  :title="$t('panel_custom_field_required_title')"
                ></i>
                <span :class="classText(item)" :title="titleItem(item)">
                  {{ item.title || item.name }}
                </span>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </section>
</template>

<script>
import AlertForm from "@/components/editor/alert-form.vue";
import draggable from "vuedraggable";

export default {
  name: "DetailFormExtendedPropertiesPanel",
  components: {
    AlertForm,
    draggable
  },
  data() {
    return {
      fields: [],
      display_inline_collapsed: false
    }
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    connectorId() {
      return this.$store.getters["dashboard/dashboardEquipmentId"];
    },
    connectorCustomFields() {
      if (!this.connectorId) return null;
      // Obtém o conector de referência da Tela
      let connector = (this.$store.getters["dashboard/connectorList"] || [])
        .find(({id}) => id == this.connectorId);
      let custom_fields = this.contract && 
        this.contract.portal_data &&
        this.contract.portal_data.equipment_extended_properties ||
        [];
      let connector_custom_fields = connector &&
        connector.user_data &&
        connector.user_data.extended_properties ||
        {};
      return custom_fields.slice(0).map(
        (field) => {
          const connector_field_value = connector_custom_fields[field.name];
          if (connector_field_value) {
            field.value = connector_field_value;
          }
          return field;
        }
      );
    },
    showRequiredFields: {
      set(value) {
        if (this.panel && this.panel.options) {
          let panel = this.panel;
          panel.options.showRequiredFields = value;
          this.savePanel(panel);
        }
      },
      get() {
        return this?.panel?.options?.showRequiredFields ?? false;
      }
    },
    showOptionalFields: {
      set(value) {
        if (this.panel && this.panel.options) {
          let panel = this.panel;
          panel.options.showOptionalFields = value;
          this.savePanel(panel);
        }
      },
      get() {
        return this?.panel?.options?.showOptionalFields ?? false;
      }
    },
    displayInline: {
      set(value) {
        if (value) this.display_inline_collapsed = true;
        this.panel.options.displayInline = value;
        this.savePanel();
      },
      get() {
        return this?.panel?.options?.displayInline ?? false;
      }
    },
    columnsInline: {
      set(value) {
        this.panel.options.columnsInline = value;
        this.savePanel();
      },
      get() {
        return this?.panel?.options?.columnsInline ?? 3;
      }
    },
    confirmation: {
      set(value) {
        let panel = this.panel;
        let ix = (panel?.options?.controls || []).findIndex((item) =>
          (item?.on?.click?.actions || []).some(
            ({type}) => type == "form:submit"
          )
        );
        if (ix == -1) return;
        let ctrl = (panel?.options?.controls || [])[ix];
        ctrl.on = ctrl.on || {};
        ctrl.on.click = ctrl.on.click || {};
        ctrl.on.click.confirmation = {
          ...(ctrl?.on?.click?.confirmation || null),
          ...(value || null)
        };
        this.$set(panel.options?.controls, ix, ctrl);
        this.savePanel(panel);
      },
      get() {
        return (
          (this?.panel?.options?.controls || []).find((item) =>
            (item?.on?.click?.actions || []).some(
              ({type}) => type == "form:submit"
            )
          )?.on?.click?.confirmation ?? null
        );
      }
    },
    filteredFields: {
      set(value) {
        value = value.map((item,idx) => {
          return {
            ...item,
            index: idx
          }
        })
        this.fields = value;
        let panel = this.panel;
        panel.options.fields = value;
        this.savePanel(panel);
      },
      get() {
        return this.fields;
      }
    },
    panelFields() {
      return this.panel && this.panel.options.fields;
    },
    panelCustomFields() {
      return (
        this.connectorCustomFields ||
        this.contract && 
        this.contract.portal_data &&
        this.contract.portal_data.equipment_extended_properties ||
        []
      );
    }
  },
  watch: {
    panelFields: {
      handler(n) {
        if (!n || this.fields.length) return;
        this.setup();
      },
      immediate: true
    }
  },
  methods: {
    reset() {
      this.src = "";
      this.$nextTick(() => {
        this.$refs.inp.focus();
      });
    },
    setup() {
      let addon = {
        panel_required: false,
        visible: true
      }
      let fields = 
        this.panelCustomFields
          .map((item) => {
            const panel_field = this.panelFields
              .find(({name}) => item.name == name);
            if (panel_field) {
              return {
                ...addon,
                ...item,
                ...panel_field
              }
            } else {
              return {
                ...addon,
                ...item
              }
            }
          });
      this.fields = this.fields
        .concat(fields)
        .sort((a,b) => a.index - b.index);
    },
    savePanel(panel) {
      this.$store.dispatch("dashboard/saveDraftPanel", {
        screenId: this.screenId,
        panel: panel ?? this.panel
      });
    },
    toggleVisible(item) {
      if (
        item.required && !this.showRequiredFields ||
        !item.required && !this.showOptionalFields
      ) {
        return;
      }
      item.visible = !item.visible;
      let panel = this.panel;
      panel.options.fields = this.fields.slice();
      this.savePanel(panel);
    },
    toggleRequired(item) {
      if (item.required) {
        return;
      }
      item.panel_required = !item.panel_required;
      let panel = this.panel;
      panel.options.fields = this.fields.slice();
      this.savePanel(panel);
    },
    disabledVisible(item) {
      return (
        item.required && !this.showRequiredFields ||
        !item.required && !this.showOptionalFields
      );
    },
    disabledRequired(item) {
      return (
        item.required && !this.showRequiredFields ||
        !item.required && !this.showOptionalFields
      );
    },
    classText(item) {
      let classes = ''
      if (item.required && !item.value)
        classes += ' text-danger';
      if (
        item.required && !this.showRequiredFields ||
        !item.required && !this.showOptionalFields
      )
        classes += ' disabled-item';
      return classes;
    },
    titleItem(item) {
      if (item.required && !item.value)
        return this.$t("required_custom_field_not_filled");
      return '';
    },
    classVisible(item) {
      if (
        item.required && !this.showRequiredFields ||
        !item.required && !this.showOptionalFields
      ) return 'fa-eye-slash';
      return item.visible ? 'fa-eye' : 'fa-eye-slash';
    },
    classRequired(item) {
      return (item.required || item.panel_required)
        ? 'fa-asterisk'
        : 'fa-circle-o';
    }
  }
};
</script>

<style scoped>
.checkbox-inline > span {
  margin-left: 3px;
  font-weight: 700;
}
.clicable {
  cursor: pointer;
}
.skin-dark .clicable:hover {
  opacity: 1;
  color: #fff;
}
.move-item:hover {
  cursor: move;
  opacity: 0.8;
}
.btn.btn-xs {
  min-width: 30px;
}
.disabled-item {
  font-style: italic;
  opacity: 0.5;
}
</style>
