<template>
  <section>
    <!-- /Search -->
    <EquipmentSearchPanel :source="source" />
    <!-- -->
    <div class="text-center" v-if="state == 'busy'" style="padding:10px">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <div class="result-table" v-else-if="equipmentList && equipmentList.length">
      <div style="display:flex;justify-content:end;align-items: center;gap:4px">
        <div class="text-bold" style="padding-right:10px;">
          {{ $t("show") }}:
        </div>
        <div
          v-for="item in widgets.list"
          :key="item.id"
          class="btn btn-sm"
          :title="item.title"
          @click.stop.prevent="widgets.selected = item.id"
          :class="widgets.selected == item.id ? 'btn-primary ' : 'btn-default'"
        >
          <i :class="item.icon"></i>
        </div>
      </div>
      <component
        v-if="widget"
        :is="widget"
        :equipmentList="sortedList"
        :entrySource="sortedList"
        :panel="customPanel || selectedWidget.defaultPanel()"
        @pick="showEquipmentWithId"
      >
      </component>
    </div>
    <!-- -->
  </section>
</template>

<script>
import EquipmentSearchBase from "@/views/private/EquipmentSearchBase.vue";
import EquipmentCardPanel from "@/components/equipment-card-panel.vue";
import ConnectorListTablePanel from "@/components/connector-list-table-panel.vue";
import Panels from "@/assets/dashboard/panels.json";

const defPanelTemplate = (tplName) => {
  let panel = (Panels || []).find(
    (panel) => panel.template.template == tplName
  );
  return (panel || {})?.template || null;
};

export default {
  name: "EquipmentSearchCard",
  extends: EquipmentSearchBase,
  components: {
    EquipmentCardPanel
  },
  props: {
    filterCriteria: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fixedEquipmentProperties: [
        "nome",
        "numero_serie",
        "localizacao",
        "has_active_alarms",
        "is_connected",
        "process_area"
      ],
      widgets: {
        selected: "card",
        list: [
          {
            id: "table",
            title: this.$tc("table", 1),
            icon: "fa fa-list",
            panelTemplate: "ConnectorListTablePanel",
            searchKey: "EquipmentSearchCard#table",
            defaultPanel() {
              return defPanelTemplate("ConnectorListTablePanel");
            }
          },
          {
            id: "card",
            title: this.$tc("card", 2),
            icon: "fa fa-th",
            panelTemplate: "EquipmentCardPanel",
            searchKey: "EquipmentSearchCard",
            defaultPanel() {
              return null;
            }
          }
        ]
      },
      templateReady: false
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    allEquipmentFields() {
      let self = this;
      return (
        (self.$root.config.equipment_selection &&
          self.$root.config.equipment_selection.cols) ||
        []
      );
    },
    equipmentCustomFields() {
      let self = this;
      return this.allEquipmentFields.filter(function(i) {
        return self.fixedEquipmentProperties.indexOf(i.name) == -1;
      });
    },
    showBanner() {
      let self = this;
      return self.$root.config.equipment_selection &&
        "show_banner" in self.$root.config.equipment_selection
        ? self.$root.config.equipment_selection.show_banner
        : false;
    },
    customConfiguration() {
      const cfg = this?.contract?.portal_data?.search_page || {};
      return cfg[this.selectedWidget.searchKey] || null;
    },
    customScreenId() {
      return this?.customConfiguration?.screen_id || null;
    },
    customScreen() {
      return (
        (Number(this.customScreenId) &&
          (this.$store.getters["dashboard/screens"] || []).find(
            ({id}) => Number(id) == Number(this.customScreenId)
          )) ||
        null
      );
    },
    state() {
      if (
        !this.contract ||
        (this.customConfiguration && this.customPanel === undefined)
      )
        return "busy";
      return "ready";
    },
    selectedWidget() {
      return this.widgets.list.find(({id}) => id === this.widgets.selected);
    },
    widget() {
      return {
        ConnectorListTablePanel: ConnectorListTablePanel,
        EquipmentCardPanel: EquipmentCardPanel
      }[this.selectedWidget.panelTemplate];
    },
    sortedList() {
      return (this.equipmentList || []).sort((a, b) => {
        return (a.has_active_alarms ? 0 : 1) - (b.has_active_alarms ? 0 : 1);
      });
    }
  },
  watch: {
    customScreen: {
      handler(n, o) {
        if (n == o) return;
        this.templateReady = false;
        if (n) {
          if (this.customTemplate()) {
            this.templateReady = true;
          } else {
            this.$store.dispatch("dashboard/fetchTemplate", n.id).then(() => {
              if (this.customTemplate()) {
                this.templateReady = true;
              }
            });
          }
        }
      },
      deep: true,
      immediate: true
    },
    templateReady: {
      handler(n, o) {
        if (n != o) {
          // this.updateCustomView();
          this.setCustomPanel(n ? this.customTemplate() : null);
        }
      },
      deep: true,
      immediate: true
    },
    selectedWidget(n, o) {
      if (n && o && n.id != o.id) {
        this.setCustomPanel(this.templateReady ? this.customTemplate() : null);
        this.save();
      }
    }
  },
  methods: {
    showField(name) {
      return (
        this.allEquipmentFields.filter(function(i) {
          return i.name == name;
        }).length > 0
      );
    },
    customTemplate() {
      return (
        this.$store.getters["dashboard/template"](this.customScreenId) || null
      );
    },
    setCustomPanel(template) {
      let panel =
        ((template && template?.panels) || []).find(
          ({name}) => name == this.customConfiguration.panel_name
        ) || null; // important - default null would render the default layout
      this.$set(this, "customPanel", panel);
    },
    save() {
      let payload = this.$store.getters["equipment/query"] || {};
      let entry = payload[this.$options.name] || {};
      if (entry.selected_widget != this.widgets.selected) {
        entry.selected_widget = this.widgets.selected;
        payload[this.$options.name] = entry;
        this.$store.dispatch("equipment/setQuery", payload);
      }
    },
    restore() {
      let q = this.$store.getters["equipment/query"] || {};
      if (q[this.$options.name]) {
        if (q[this.$options.name].selected_widget) {
          this.widgets.selected = q[this.$options.name].selected_widget;
        }
      }
    }
  },
  created() {
    this.restore();
  },
  mounted() {
    this.refreshEnabled = true;
  }
};
</script>

<style scoped>
.tab-search {
  padding-bottom: 200px !important;
}
.equipment-item {
}
.equipment-item:hover {
  cursor: pointer;
}
.equipment-item .info-box {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.equipment-item .info-box:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.equipment-enabled {
  opacity: 1;
}

.result-table {
  min-height: 400px;
}
</style>
