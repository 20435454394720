<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="form-group form-group-sm">
        <label for="symbol">
          {{ $t("symbol") }}
        </label>
        <div class="input-group">
          <select
            name="symbol"
            id="symbol"
            class="form-control"
            v-model="symbol"
          >
            <option value="">{{ $tc("none", 1) }}</option>
            <option value="circle">{{ $t("circle") }}</option>
            <option value="rect">{{ $t("rectangle") }}</option>
            <option value="roundRect">{{ $t("rounded_rectangle") }}</option>
            <option value="triangle">{{ $t("triangle") }}</option>
            <option value="pin">{{ $t("pin") }}</option>
            <option value="arrow">{{ $t("arrow") }}</option>
          </select>
          <div class="input-group-addon no-padding">
            <div class="input-group-btn" style="">
              <button
                type="button"
                class="btn btn-xs no-padding dropdown-toggle"
                data-toggle="dropdown"
                style="background-color: transparent;min-width: 55px"
                :disabled="!form.symbol"
              >
                {{ form.symbolSize }}
                <span
                  class="fa fa-caret-down"
                  style="margin-left: 10px;margin-right: -10px;"
                ></span>
              </button>
              <ul class="dropdown-menu" style="min-width: auto;width: 100%;">
                <li
                  v-for="i in [0, 2, 4, 6, 8, 10, 12, 14, 16, 20, 26]"
                  :key="i"
                  @click.stop.prevent="
                    form.symbolSize = form.symbol ? i : form.symbol
                  "
                >
                  <a href="javascript:void()" class="no-padding text-center">{{
                    i
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {isEqual} from "lodash";
export default {
  name: "ChartSymbolForm",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      form: {
        symbol: "",
        symbolSize: 0
      }
    };
  },
  computed: {
    symbol: {
      set(value) {
        this.form.symbol = value;
        if (value === "") {
          this.form.symbolSize = 0;
        } else if (!this.form.symbolSize) {
          this.form.symbolSize = 8;
        }
      },
      get() {
        return this.form.symbol;
      }
    }
  },
  watch: {
    form: {
      handler(n) {
        if (isEqual(n, this.value)) return;
        this.$emit("input", this.form);
      },
      deep: true
    }
  },
  created() {
    if (this?.value?.symbol) this.form = {...this.value};
  }
};
</script>

<style scoped>
.skin-dark .btn.dropdown-toggle:not(:disabled):hover,
.skin-dark .btn.dropdown-toggle:not(:disabled):focus {
  color: #fff;
}
</style>
