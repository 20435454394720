<template>
  <vue-context
    class="has-icon no-select"
    v-slot="{data: control}"
    ref="menu"
    :subMenuOffset="36"
  >
    <div class="close-button">
      <i class="fa fa-close clicable"></i>
    </div>
    <li>
      <a class="has-shortcut" @click.stop.prevent="undo">
        <i class="fa fa-undo"></i>
        {{ $t("undo") }}
        <span class="shortcut">Ctrl+Z</span>
      </a>
    </li>
    <li>
      <a class="has-shortcut" @click.stop.prevent="redo">
        <i class="fa fa-repeat"></i>
        {{ $t("redo") }}
        <span class="shortcut">Shift+Ctrl+Z</span>
      </a>
    </li>
    <hr />
    <li>
      <a
        :class="['has-shortcut', {disabled: !hasSelected()}]"
        @click.stop.prevent="cut"
        :title="$t('select_control_to_execute_action')"
      >
        <i class="fa fa-cut"></i>
        {{ $tc("cut") }}
        <span class="shortcut"> Ctrl+X </span>
      </a>
    </li>
    <li>
      <a
        :class="['has-shortcut', {disabled: !hasSelected()}]"
        @click.stop.prevent="copy"
        :title="$t('select_control_to_execute_action')"
      >
        <i class="fa fa-copy"></i>
        {{ $tc("copy") }}
        <span class="shortcut"> Ctrl+C </span></a
      >
    </li>
    <li>
      <a class="has-shortcut" @click.stop.prevent="paste">
        <i class="fa fa-paste"></i>
        {{ $t("paste") }}
        <span class="shortcut"> Ctrl+V </span></a
      >
    </li>
    <li v-if="hasSelected()">
      <a class="has-shortcut" @click.stop.prevent="remove">
        <i class="fa fa-trash"></i>
        {{ $t("remove") }}
        <span class="shortcut"> DEL </span>
      </a>
    </li>
    <li v-if="hasSelected()">
      <a @click.stop.prevent="toggleLock">
        <i :class="isLocked ? 'fa fa-unlock' : 'fa fa-lock'"></i>
        {{ $t(`synoptic.${isLocked ? "unlock" : "lock"}`) }}
      </a>
    </li>
    <li
      class="v-context__sub"
      @mouseenter="openSubMenu"
      @mouseleave="closeSubMenu"
      v-if="hasSelected(1)"
    >
      <hr />
      <a @click="onCommand('centralize')">
        <img class="icon" src="/static/common/images/align-window-center.svg" />
        {{ $t("centralize") }}</a
      >
      <ul class="v-context has-icon delayed" data-testid="submenu">
        <li>
          <a @click="onCommand('centralizeHorizontally')">
            <img
              class="icon"
              src="/static/common/images/align-window-horizontal-center.svg"
            />
            {{ $t("centralize_horizontally") }}</a
          >
        </li>
        <li>
          <a @click="onCommand('centralizeVertically')">
            <img
              class="icon"
              src="/static/common/images/align-window-vertical-center.svg"
            />
            {{ $t("centralize_vertically") }}</a
          >
        </li>
      </ul>
    </li>
    <template v-if="hasSelected(2)">
      <li class="v-context__sub">
        <a data-testid="alignment">{{ $t("alignment") }}</a>
        <ul :class="['v-context', {'has-icon': hasSelected(2)}]">
          <li>
            <a @click="onCommand('alignBottom', control)">
              <i class="glyphicon glyphicon-object-align-bottom"></i>
              {{ $t("align_bottom") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('alignTop', control)">
              <i class="glyphicon glyphicon-object-align-top"></i>
              {{ $t("align_top") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('alignRight', control)">
              <i class="glyphicon glyphicon-object-align-right"></i>
              {{ $t("align_right") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('alignLeft', control)">
              <i class="glyphicon glyphicon-object-align-left"></i>
              {{ $t("align_left") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('alignHorizontally', control)">
              <i class="glyphicon glyphicon-object-align-horizontal"></i>
              {{ $t("align_horizontally") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('alignVertically', control)">
              <i class="glyphicon glyphicon-object-align-vertical"></i>
              {{ $t("align_vertically") }}</a
            >
          </li>
        </ul>
      </li>
      <li class="v-context__sub" v-if="hasSelected(3)">
        <a>{{ $t("horizontal_distribution") }}</a>
        <ul class="v-context has-icon">
          <li>
            <a @click="onCommand('distributeHorizontalLeft')">
              <img
                class="icon"
                src="/static/common/images/distribute-horizontal-left.svg"
                alt="Distribute horizontal left"
              />
              {{ $t("distribute_horizontal_left") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('distributeHorizontalCenter')">
              <img
                class="icon"
                src="/static/common/images/distribute-horizontal-center.svg"
                alt="Distribute horizontal center"
              />
              {{ $t("distribute_horizontal_center") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('distributeHorizontalRight')">
              <img
                class="icon"
                src="/static/common/images/distribute-horizontal-right.svg"
                alt="Distribute horizontal right"
              />
              {{ $t("distribute_horizontal_right") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('distributeHorizontalGap')">
              <img
                class="icon"
                src="/static/common/images/distribute-horizontal-gap.svg"
                alt="Distribute horizontal gap"
              />
              {{ $t("distribute_horizontal_gap") }}</a
            >
          </li>
        </ul>
      </li>
      <li class="v-context__sub" v-if="hasSelected(3)">
        <a>{{ $t("vertical_distribution") }}</a>
        <ul class="v-context has-icon">
          <li>
            <a @click="onCommand('distributeVerticalTop')">
              <img
                class="icon"
                src="/static/common/images/distribute-vertical-top.svg"
                alt="Distribute vertical top"
              />
              {{ $t("distribute_vertical_top") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('distributeVerticalCenter')">
              <img
                class="icon"
                src="/static/common/images/distribute-vertical-center.svg"
                alt="Distribute vertical center"
              />
              {{ $t("distribute_vertical_center") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('distributeVerticalBottom')">
              <img
                class="icon"
                src="/static/common/images/distribute-vertical-bottom.svg"
                alt="Distribute vertical bottom"
              />
              {{ $t("distribute_vertical_bottom") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('distributeVerticalGap')">
              <img
                class="icon"
                src="/static/common/images/distribute-vertical-gap.svg"
                alt="Distribute vertical gap"
              />
              {{ $t("distribute_vertical_gap") }}</a
            >
          </li>
        </ul>
      </li>
      <li class="v-context__sub">
        <a data-testid="format">{{ $t("format") }}</a>
        <ul class="v-context has-icon">
          <li>
            <a @click="onCommand('sameHeight', control)">
              <img
                class="icon"
                src="/static/common/images/same-height.svg"
                alt="Same height"
              />
              {{ $t("same_height") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('sameWidth', control)">
              <img
                class="icon"
                src="/static/common/images/same-width.svg"
                alt="Same width"
              />
              {{ $t("same_width") }}</a
            >
          </li>
          <li>
            <a @click="onCommand('sameSize', control)">
              <img
                class="icon"
                src="/static/common/images/same-size.svg"
                alt="Same size"
              />
              {{ $t("same_size") }}</a
            >
          </li>
        </ul>
      </li>
    </template>
    <li
      class="v-context__sub"
      @mouseenter="openSubMenu"
      @mouseleave="closeSubMenu"
      v-if="hasSelected(1) && selectedControls.length == 1"
    >
      <a @click.stop.prevent>
        <img class="icon" src="/static/common/images/order.svg" />
        {{ $t("order") }}</a
      >
      <ul class="v-context has-icon">
        <li>
          <a
            :class="['has-shortcut', {disabled: !hasSelected()}]"
            @click.stop.prevent="onCommand('bringToFront')"
            :title="$t('bring_forward')"
          >
            <img class="icon" src="/static/common/images/bring_to_front.svg" />
            {{ $tc("bring_forward") }}
          </a>
        </li>
        <li>
          <a
            :class="['has-shortcut', {disabled: !hasSelected()}]"
            @click.stop.prevent="onCommand('bringForward')"
            :title="$t('bring_to_front')"
          >
            <img class="icon" src="/static/common/images/send_forward.svg" />
            {{ $tc("bring_to_front") }}
          </a>
        </li>
        <li>
          <a
            :class="['has-shortcut', {disabled: !hasSelected()}]"
            @click.stop.prevent="onCommand('sendBackward')"
            :title="$t('send_backward')"
          >
            <img class="icon" src="/static/common/images/send_backward.svg" />
            {{ $tc("send_backward") }}
          </a>
        </li>
        <li>
          <a
            :class="['has-shortcut', {disabled: !hasSelected()}]"
            @click.stop.prevent="onCommand('sendToBack')"
            :title="$t('send_to_back')"
          >
            <img class="icon" src="/static/common/images/send_to_back.svg" />
            {{ $tc("send_to_back") }}
          </a>
        </li>
      </ul>
    </li>
  </vue-context>
</template>

<script>
import VueContext from "@/plugins/vue-context";
import messages from "@/i18n/synoptic-context-menu";

export default {
  name: "ControlContextMenu",
  i18n: {
    messages
  },
  components: {
    VueContext
  },
  props: {
    zoom: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      subMenuOpenDelay: 500
    };
  },
  computed: {
    nSelected() {
      return (this.selectedControls || []).length;
    },
    nLocked() {
      return (this.selectedControls || []).filter(({locked}) => locked).length;
    },
    isLocked() {
      return this.nSelected && this.nSelected == this.nLocked;
    },
    selectedControls() {
      return this.$store.getters["synoptic/selectedControls"];
    }
  },
  methods: {
    open(e, data) {
      this.$refs.menu &&
        this.$refs.menu.open(
          {
            clientX: e.clientX / this.zoom,
            clientY: e.clientY / this.zoom
          },
          data
        );
    },
    close() {
      this.$refs.menu && this.$refs.menu.close();
    },
    openSubMenu(e) {
      this._openSubMenuTimeout = setTimeout(() => {
        e.target.lastChild.classList.add("open");
      }, this.subMenuOpenDelay);
    },
    closeSubMenu(e) {
      clearTimeout(this._openSubMenuTimeout);
      e.target.lastChild.classList.remove("open");
    },
    hasSelected(amount = 1) {
      return this.selectedControls.length >= amount;
    },
    cut(e) {
      if (!this.hasSelected()) {
        e.stopPropagation();
        return;
      }
      this.$root.$emit("cut");
    },
    copy(e) {
      if (!this.hasSelected()) {
        e.stopPropagation();
        return;
      }
      this.$root.$emit("copy");
    },
    paste() {
      this.$root.$emit("paste");
    },
    remove(e) {
      if (!this.hasSelected()) {
        e.stopPropagation();
        return;
      }
      this.$root.$emit("delete");
    },
    undo() {
      this.$root.$emit("undo");
    },
    redo() {
      this.$root.$emit("redo");
    },
    toggleLock() {
      var state = !this.isLocked;
      let lst = (this.selectedControls || []).map(({id}) => ({
        id: id,
        state: state
      }));
      if (!lst.length) return;
      this.$store.dispatch("synoptic/setControlsLockerState", lst);
      this.close();
    },
    onCommand(name, control) {
      this.$store.dispatch(`synoptic/${name}`, control);
      this.$root.$emit("editor.keyboard:focus");
    }
  }
};
</script>

<style lang="scss" scoped>
.v-context {
  padding: 0 0 5px 0;
  border-radius: 0.4rem;
  & > div.close-button {
    background-color: whitesmoke;
    width: 100%;
    height: 15px;
    position: relative;
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
    & > i {
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 2px;
      color: #808080;
      font-size: 80%;
    }
  }
  & > li {
    cursor: pointer;

    & > a {
      text-align: left;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      font-size: 1.3rem;

      &.disabled {
        opacity: 0.6;
        cursor: initial;

        .shortcut {
          color: inherit;
        }

        &:hover,
        &:focus {
          background-color: initial;
        }
      }

      &.has-shortcut {
        position: relative;
        min-width: 16em;

        .shortcut {
          color: #808080;
          position: absolute;
          right: 1.2rem;
        }
      }
    }
  }

  hr {
    border-color: #8080804d;
    margin: 5px 0;
  }

  &.has-icon > li > a {
    padding-left: 3.8rem;

    i,
    .icon {
      width: 1em;
      height: 1em;
      font-size: 1.168em;
      margin-right: 0.8rem;
      margin-left: -2.5rem;
      position: relative;

      &.glyphicon {
        top: 0.16em;
      }

      &.fa {
        top: 0.1em;
      }
    }

    .icon {
      top: -0.05em;
    }
  }
}

.skin-dark {
  .v-context {
    & > div.close-button {
      background-color: transparent;
      border-bottom: 1px solid #1e282c;
      & > i {
        color: #b8c7ce;
        &:hover {
          color: #fff;
        }
      }
    }
    & > li {
      & > a {
        &.has-shortcut {
          .shortcut {
            color: #728188;
          }
        }
        &:hover {
          &.has-shortcut {
            .shortcut {
              color: #222d32;
            }
          }
          & > .icon {
            filter: opacity(60%);
          }
        }
        & > .icon {
          filter: invert(1) sepia(1) brightness(90%) hue-rotate(90deg);
        }
      }
    }

    hr {
      border-color: #1e282c;
    }
  }
}

.v-context__sub > .v-context.delayed {
  visibility: hidden;

  &.open {
    visibility: visible;
  }
}

.glyphicon-step-backward {
  transform: rotate(90deg);
}
.glyphicon-step-forward {
  transform: rotate(90deg);
}

.bring_forward {
  background-image: url("/static/common/images/send_forward.svg");
}
</style>
