<template>
  <section v-if="mode == 'editor'">
    <div class="row hidden-xs hidden-sm">
      <div class="col-sm-2" v-for="field in fields" :key="field.name">
        <label for="">{{ $tc(field.title, 1) }}</label>
      </div>
    </div>
    <draggable v-model="items">
      <div class="row" v-for="(item, ix) in items" :key="ix">
        <div class="move-item">
          <div
            v-for="(field, ic) in fields"
            :key="`${field.name}-${ix}`"
            class="col-sm-2 form-group form-group-sm"
            :class="{
              'has-error':
                validation && validation[ix] && validation[ix][field.name]
                  ? true
                  : false
            }"
          >
            <div
              class="move-item move-item-icon hidden-xs hidden-sm"
              v-if="ic == 0"
            >
              <i class="glyphicon glyphicon-option-vertical"></i>
            </div>
            <template v-if="field.type == 'checkbox'">
              <label class="checkbox-inline">
                <input
                  type="checkbox"
                  v-model="item[field.name]"
                  :disabled="busy || !field.enabled"
                />
                {{ $tc(field.name, 1) }}
              </label>
              <div class="pull-right">
                <span
                  class="btn no-padding"
                  @click.stop.prevent="removeItem(ix)"
                  :disabled="busy"
                  ><i class="fa fa-trash"></i
                ></span>
              </div>
            </template>
            <template v-else>
              <label for="" class="label-mobile hidden-md hidden-lg">{{
                $t(field.name)
              }}</label>
              <input
                :type="field.type"
                class="form-control field-name"
                v-model="item[field.name]"
                :disabled="busy || !field.enabled"
                :ref="`${field.name}_${ix}`"
              />
            </template>
          </div>
        </div>
        <div class="sep hidden-md hidden-lg"></div>
      </div>
    </draggable>
    <div class="alert alert-default" v-if="items.length == 0">
      {{ $t("there_are_no_items_configured") }}
    </div>
    <div class="actions">
      <button
        v-if="btnNewLine"
        class="btn btn-default"
        @click.stop.prevent="newItem"
        :disabled="busy"
      >
        <i class="fa fa-pencil"></i> {{ $tc("new_field", 1) }}
      </button>
      <button
        v-if="restoreButton"
        class="btn btn-default"
        @click.stop.prevent="$emit('restore')"
      >
        <i class="fa fa-undo"></i> {{ $tc("restore", 1) }}
      </button>
      <button
        v-if="importButton && hasMissingContractFields"
        class="btn btn-default"
        @click.stop.prevent="importFromContract()"
      >
        <i class="fa fa-plus-circle"></i>
        {{ $tc("import_from_contract", 1) }}
      </button>
      <button
        v-if="btnNewLine && resetButton"
        class="btn btn-default"
        @click.stop.prevent="removeAll()"
      >
        <i class="fa fa-trash"></i> {{ $tc("remove", 1) }} {{ $tc("all", 1) }}
      </button>
      <button
        v-if="modeButton"
        class="btn btn-default"
        @click.stop.prevent="$emit('mode', 'viewer')"
        :disabled="busy"
      >
        <i class="fa fa-sliders"></i> {{ $tc("titles.form", 1) }}
      </button>
    </div>
  </section>
  <section v-else-if="mode == 'viewer'">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-md-offset-3 field-container">
        <template v-for="(item, ix) in items">
          <div :key="ix" class="form-group" :class="{'has-error': false}">
            <label
              :title="
                item.required
                  ? item.description +
                    ' (' +
                    $tc('required', 1).toLowerCase() +
                    ')'
                  : item.description
              "
              :for="`field_${item.name}`"
              :class="item.required ? 'text-danger' : ''"
              style="width:100%"
              >{{ item.title || item.name }}
              <sup v-if="item.required">*</sup>
              <Tooltip :title="item.description" v-if="item.description" />
            </label>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                v-model="item.value"
                :placeholder="item.initialValue"
                :id="`field_${item.name}`"
                :required="item.required || item.panel_required"
                :title="item.description"
                :maxlength="item.character_limit || 1024"
              />
              <div
                class="input-group-addon btn"
                :title="$t('restore')"
                @click.stop.prevent="restoreItem(item)"
              >
                <i class="fa fa-undo"></i>
              </div>
              <div
                class="input-group-addon btn"
                :title="$t('clear')"
                @click.stop.prevent="resetItem(item)"
              >
                <i class="fa fa-close"></i>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="actions">
      <button
        v-if="restoreButton"
        class="btn btn-default"
        @click.stop.prevent="$emit('restore')"
      >
        <i class="fa fa-undo"></i> {{ $tc("restore", 1) }}
      </button>
      <button
        v-if="modeButton"
        class="btn btn-default"
        @click.stop.prevent="$emit('mode', 'editor')"
        :disabled="busy"
      >
        <i class="fa fa-sliders"></i> {{ $tc("titles.field", 2) }}
      </button>
    </div>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import draggable from "vuedraggable";
import Tooltip from "@/components/tooltip.vue";
const defaultItem = () => ({
  name: "",
  title: "",
  required: false,
  value: ""
});

const defaultSchema = () => [
  {
    name: "name",
    title: "name",
    unique: true,
    required: true,
    enabled: true,
    type: "text"
  },
  {
    name: "title",
    title: "title",
    unique: false,
    required: true,
    enabled: true,
    type: "text"
  },
  {
    name: "description",
    title: "description",
    unique: false,
    required: false,
    enabled: true,
    type: "text"
  },
  {
    name: "character_limit",
    title: "character_limit",
    unique: false,
    required: false,
    enabled: true,
    type: "number"
  },
  {
    name: "value",
    title: "value",
    unique: false,
    required: false,
    enabled: true,
    type: "text"
  },
  {
    name: "required",
    title: "options",
    unique: false,
    required: false,
    enabled: true,
    type: "checkbox"
  }
];

export {defaultItem, defaultSchema};

export default {
  name: "FormExtendedProperties",
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    resetButton: {
      type: Boolean,
      required: false,
      default: false
    },
    restoreButton: {
      type: Boolean,
      required: false,
      default: false
    },
    importButton: {
      type: Boolean,
      required: false,
      default: false
    },
    modeButton: {
      type: Boolean,
      required: false,
      default: false
    },
    fields: {
      type: Array,
      required: false,
      default: () => defaultSchema()
    },
    mode: {
      type: String,
      required: false,
      default: "editor"
    }
  },
  components: {
    draggable,
    Tooltip
  },
  data() {
    return {
      items: [],
      busy: false,
      cid: 0
    };
  },
  computed: {
    btnNewLine() {
      let items = this.items || [];
      if (!items.length || items[items.length - 1].name) return true;
      return false;
    },
    validation() {
      var entry = [];
      if (!this.cid) return entry;
      (this.fields || []).forEach((field) => {
        if (!field.enabled) return;
        let values = this.items.map((item) => item[field.name]);
        let repeated = this.$utils.distinct(
          values.filter((val, i) => val !== "" && values.includes(val, i + 1))
        );
        values.forEach(function(value, ix) {
          entry[ix] = entry[ix] || {};
          entry[ix][field.name] = ""; // no error
          if (value !== "" && field.unique && repeated.includes(value)) {
            entry[ix][field.name] = "repeated";
          } else if (field.required && value === "") {
            entry[ix][field.name] = "required";
          }
        });
      });
      return entry;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    hasMissingContractFields() {
      let source =
        this?.contract?.portal_data?.equipment_extended_properties || [];
      for (var i in source || []) {
        if (!(this.items || []).some(({name}) => name == source[i].name)) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    value: {
      handler(n) {
        let items = structuredClone(n);
        if (isEqual(items, this.items)) return;
        this.$set(this, "items", items);
        this.saveState();
      },
      immediate: true,
      deep: true
    },
    items: {
      handler(n) {
        if (isEqual(n, this.value)) return;
        this.$emit("input", structuredClone(n));
        this.cid++;
      },
      immediate: true,
      deep: true
    },
    mode() {
      this.saveState();
    }
  },
  methods: {
    newItem() {
      let items = JSON.parse(JSON.stringify(this.items));
      items.push(defaultItem());
      this.$set(this, "items", items);
      this.$nextTick(() => {
        let elKey = `${this.fields[0].name}_${items.length - 1}`;
        if (this.$refs[elKey]?.length == 1) {
          this.$refs[elKey][0].focus();
        }
      });
    },
    removeItem(ix) {
      if (ix >= 0 && ix < this.items.length) {
        let items = JSON.parse(JSON.stringify(this.items));
        items.splice(ix, 1);
        this.$set(this, "items", items);
      }
    },
    removeAll() {
      this.$set(this, "items", []);
    },
    importFromContract() {
      let source =
        this?.contract?.portal_data?.equipment_extended_properties || [];
      let lst = JSON.parse(JSON.stringify(this.items || []));
      let nFields = lst.length;
      source.forEach((prop) => {
        if (!lst.find(({name}) => name == prop.name)) {
          lst.push(JSON.parse(JSON.stringify(prop)));
        }
      });
      if (lst.length > nFields) {
        this.$set(this, "items", lst);
      }
    },
    saveState() {
      // non reactive
      this.initialState = Object.fromEntries(
        this.items.map(({name, value}) => [name, value])
      );
    },
    setFieldValue(item, value) {
      item.value = value;
    },
    itemFocus(item) {
      this.$nextTick(() => {
        const el = document.getElementById(`field_${item.name}`);
        if (el && el.focus) el.focus();
      });
    },
    restoreItem(item) {
      item.value = this.initialState
        ? this.initialState[item.name] ?? item.value
        : item.value;
      this.itemFocus(item);
    },
    resetItem(item) {
      item.value = "";
      this.itemFocus(item);
    }
  }
};
</script>

<style scoped>
.actions {
  margin: 20px 0;
  text-align: center;
}

.actions > button {
  margin: 0 7px;
}

.move-item:hover {
  cursor: move;
  opacity: 0.8;
  background-color: whitesmoke;
}

.move-item-icon {
  position: absolute;
  left: 4px;
  top: 4px;
  color: #666;
  margin-right: 7px;
}

.form-group {
  margin: 0;
}

.alert-default {
  margin-top: 10px;
  background-color: whitesmoke;
}

.skin-dark .alert-default {
  background-color: #222d32;
}

.sep {
  width: 95%;
  margin: 0 auto 10px auto;
  padding-bottom: 1px;
  border-bottom: 1px solid rgb(205, 205, 205);
  clear: both;
  padding-top: 10px;
}

.label-mobile {
  margin-top: 5px;
  margin-bottom: 0;
}
</style>
