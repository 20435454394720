import { render, staticRenderFns } from "./user-preferences-sidebar-panel.vue?vue&type=template&id=2d1f6740&scoped=true&"
import script from "./user-preferences-sidebar-panel.vue?vue&type=script&lang=js&"
export * from "./user-preferences-sidebar-panel.vue?vue&type=script&lang=js&"
import style0 from "./user-preferences-sidebar-panel.vue?vue&type=style&index=0&id=2d1f6740&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d1f6740",
  null
  
)

export default component.exports