<template>
  <div class="property-wrapper me" v-if="ready">
    <!-- canvas proportion -->
    <div style="position: relative">
      <label class="" for="control_width"
        >{{ $tc("original_panel_dimension", 1) }}:</label
      >
      <ToolTip :title="$t('hints.original_panel_dimension')" />
      <div class="row" style="position: relative">
        <div
          style="
            position: absolute;
            left: calc(50% - 8px);
            text-align: center;
            top: 21px;
            z-index: 3;
          "
        >
          <ToolTip
            class="clicable"
            :class="canvasSizeLocked ? 'text-primary' : ''"
            :icon="canvasSizeLocked ? 'fa fa-link' : 'fa fa-unlink'"
            style="transform: rotate(-45deg)"
            :title="
              $t(
                canvasSizeLocked
                  ? 'hints.canvas_size_locked'
                  : 'hints.canvas_size_unlocked'
              )
            "
            @click="canvasSizeLocked = !canvasSizeLocked"
          />
        </div>
        <div class="col-xs-6">
          <div class="form-group form-group-sm canvas-property">
            <label
              :class="canvasSizeLocked ? 'text-primary' : ''"
              for="control_width"
              style=""
              >{{ $t("width") }}:</label
            >
            <input
              class="form-control text-center"
              type="number"
              id="control_width"
              v-model="w"
              data-testid="width"
            />
          </div>
        </div>
        <div class="col-xs-6">
          <div class="form-group form-group-sm canvas-property">
            <label
              :class="canvasSizeLocked ? 'text-primary' : ''"
              for="control_height"
              style=""
              >{{ $t("height") }}:
              <i
                class="fa fa-exclamation-triangle text-danger"
                :title="`${$t('minimum_value')} ${minPanelHeight}px`"
                v-if="panel.options.canvas.style.height < minPanelHeight"
              ></i>
            </label>
            <input
              class="form-control text-center"
              type="number"
              id="control_height"
              :min="minPanelHeight"
              v-model="h"
              data-testid="height"
            />
          </div>
        </div>
        <div class="col-xs-12">
          <SizeDisplay :size="{h: h, w: w}" />
        </div>
      </div>
    </div>

    <!-- begin panel vertical-align -->
    <div style="position: relative; margin-top: 30px">
      <div class="form-group form-group-sm">
        <label for="">{{ $t("synoptic.text_align") }}</label>
        <div class="input-group">
          <div class="input-group-addon">
            {{ $t("vertical") }}
          </div>
          <select name="" class="form-control" v-model="verticalAlign">
            <option value="top">{{ $t("top") }}</option>
            <option value="middle">{{ $t("middle") }}</option>
            <option value="bottom">{{ $t("bottom") }}</option>
          </select>
        </div>
      </div>
    </div>
    <!-- end panel vertical-align -->

    <!-- background image -->
    <div style="position: relative; margin-top: 30px">
      <div class="row">
        <div class="col-xs-12">
          <ImageSelection
            id="canvas-image"
            :class="error ? 'input-url-error' : ''"
            @input="setImage"
            v-bind:value="panel.options.canvas.src"
          >
            <template #label>
              <div>
                <label for="">{{ $t("synoptic.background_image") }}</label>
                <ToolTip
                  :title="
                    $t(
                      `hints.${
                        error
                          ? 'background_image_url_error'
                          : 'background_image'
                      }`
                    )
                  "
                />
              </div>
            </template>
          </ImageSelection>
        </div>
      </div>
      <div class="row" v-if="imageControl">
        <div class="col-xs-6">
          <label for="">Dimensões</label>
        </div>
        <div class="col-xs-6 text-right">
          <ToolTip
            class="clicable"
            :class="imageSizeLocked ? '' : 'text-primary'"
            icon="fa fa-pencil"
            :title="$t('edit')"
            @click="imageSizeLocked = !imageSizeLocked"
          />
        </div>
      </div>
      <div class="row" v-if="imageControl">
        <div class="col-xs-6" style="">
          <div class="form-group form-group-sm canvas-property">
            <label>{{ $t("width") }}:</label>
            <input
              class="form-control text-center"
              type="number"
              v-model="imageWidth"
              :disabled="imageSizeLocked"
              ref="imageWidth"
            />
          </div>
        </div>
        <div class="col-xs-6" style="">
          <div class="form-group form-group-sm canvas-property">
            <label>{{ $t("height") }}:</label>
            <input
              class="form-control text-center"
              type="number"
              min="400"
              v-model="imageHeight"
              :disabled="imageSizeLocked"
            />
          </div>
        </div>
        <div class="col-xs-12">
          <SizeDisplay v-if="imageSize" :size="imageSize" />
        </div>
      </div>
    </div>

    <!-- panel size -->
    <div
      v-if="panelSize"
      style="position: relative; margin-top: 10px; z-index: 2"
    >
      <TogglePanel
        :title="$tc('layout', 1)"
        :collapsed="true"
        persistent="toggle_synoptic_panel_size"
      >
        <template #title>
          <span :style="styleTitle">
            <label>{{ $t("current_panel_dimension") }} </label>
            <ToolTip
              :title="$t('hints.current_panel_dimension')"
              style="margin-left: 4px"
            />
          </span>
        </template>
        <div class="row">
          <div class="col-xs-6">
            <div class="form-group form-group-sm canvas-property">
              <label>
                {{ $t("width") }}
                <sup
                  style="color: #777; font-size: 70%"
                  :title="`${$t('width')} ${$t('column')}`"
                >
                  ({{ layoutColumnWidth }}%)
                </sup>
              </label>
              <input
                class="form-control text-center"
                type="number"
                :value="panelSize.w"
                disabled
              />
            </div>
          </div>
          <div class="col-xs-6">
            <div class="form-group form-group-sm canvas-property">
              <label>{{ $t("height") }}:</label>
              <input
                class="form-control text-center"
                type="number"
                :min="minPanelHeight"
                :value="panelSize.h"
                disabled
              />
            </div>
          </div>
          <div class="col-xs-12">
            <SizeDisplay v-if="panelSize" :size="panelSize" />
          </div>
        </div>
      </TogglePanel>
    </div>

    <!-- color -->
    <!-- <div class="row" style="position: relative; margin-top: 30px">
      <div class="col-xs-12">
        <div class="form-group">
          <label style="margin-bottom: 0; vertical-align: middle"
            >{{ $t("synoptic.background_color") }} (Canvas)
          </label>
          <ColorPicker
            @input="setColor"
            v-bind:value="panel.options.canvas.style['background-color']"
            v-bind:pickerStyle="{ left: '0px' }"
            icon="background"
          />
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-xs-12 text-center">
        <button
          class="btn btn-sm btn-primary"
          @click.stop.prevent="onSwitchToControlsButton"
        >
          {{ $t("controls") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from "lodash";
import Controls from "@/assets/dashboard/controls.json";
import ToolTip from "@/components/tooltip.vue";
// import ColorPicker from "@/components/editor/color-picker.vue";
import ImageSelection from "./image-selection";
import SizeDisplay from "@/components/synoptic/property-editor/controls/size-display.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
export default {
  name: "Canvas",
  components: {
    // ColorPicker,
    ImageSelection,
    ToolTip,
    SizeDisplay,
    TogglePanel
  },
  data() {
    return {
      control_name: "",
      control_width: 960,
      control_height: 660,
      control_color: "#ffffff",
      control_src: "",
      canvasSizeLocked: true,
      imageSizeLocked: true,
      panelSize: null
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"]
    },
    isDarkTheme() {
      return this.user?.user_profile?.portal_data?.css_theme == "skin-dark";
    },
    error() {
      return this?.panel?.options?.canvas?.src && !this.imageControl
        ? true
        : false;
    },
    ready() {
      return this?.panel?.options?.canvas || false;
    },
    panel: {
      set(value) {
        if (this.panel && value && this.panel.name == value.name) {
          this.$store.dispatch("synoptic/save");
        }
      },
      get() {
        return this.$store.getters["synoptic/panel"] || null;
      }
    },
    imageControl() {
      return (this.panel.options.controls || []).find(
        (item) =>
          item.synopticComponent.componentName == "SynopticImage" &&
          item.synopticComponent.src == this.panel.options.canvas.src
      );
    },
    getImage() {
      return this?.imageControl?.synopticComponent?.src || "";
    },
    aspectRatio() {
      return this.w && this.h ? this.h / this.w : 1;
    },
    w: {
      set(value) {
        if (!this.ready) return;
        let style = JSON.parse(JSON.stringify(this.panel.options.canvas.style));
        const imgChange =
          this.imageSize &&
          this.imageSize.w == style.width &&
          this.imageSize.h == style.height;
        if (this.canvasSizeLocked) {
          style.height = value * this.aspectRatio;
          style.width = value;
        } else {
          style.width = value;
        }
        if (imgChange) {
          this.imageSize = {w: style.width, h: style.height};
          this.panel.options.canvas.style = style;
        } else {
          this.$set(this.panel.options.canvas, "style", style);
        }
      },
      get() {
        return parseFloat(this.panel.options.canvas.style.width);
      }
    },
    h: {
      set(value) {
        if (!this.ready) return;
        let style = JSON.parse(JSON.stringify(this.panel.options.canvas.style));
        const imgChange =
          this.imageSize &&
          this.imageSize.w == style.width &&
          this.imageSize.h == style.height;
        if (this.canvasSizeLocked) {
          style.width = value / this.aspectRatio;
          style.height = value;
        } else {
          style.height = value;
        }
        if (imgChange) {
          this.panel.options.canvas.style = style;
          this.imageSize = {w: style.width, h: style.height};
        } else {
          this.$set(this.panel.options.canvas, "style", style);
        }
      },
      get() {
        return parseFloat(this.panel.options.canvas.style.height);
      }
    },
    imageSize: {
      async set(value) {
        if (!this.ready) return;
        if (value && this.getImage) {
          let control = JSON.parse(JSON.stringify(this.imageControl));
          control.synopticComponent.clientRect.width = value.w;
          control.synopticComponent.clientRect.height = value.h;
          this.updateBackgroundImageControl(control);
        }
      },
      get() {
        if (!this.imageControl) return null;
        return {
          w: this?.imageControl?.synopticComponent?.clientRect?.width || 0,
          h: this?.imageControl?.synopticComponent?.clientRect?.height || 0
        };
      }
    },
    imageHeight: {
      set(value) {
        if (this.imageSizeLocked || !this.imageSize) return;
        let size = this.imageSize;
        size.h = value;
        this.imageSize = size;
      },
      get() {
        return this?.imageSize?.h || 0;
      }
    },
    imageWidth: {
      set(value) {
        if (this.imageSizeLocked || !this.imageSize) return;
        let size = this.imageSize;
        size.w = value;
        this.imageSize = size;
      },
      get() {
        return this?.imageSize?.w || 0;
      }
    },
    verticalAlign: {
      set(value) {
        if (!this.ready || !this.panel) return;
        if (this?.panel?.options?.canvas) {
          let canvas = {...(this?.panel?.options?.canvas || {})};
          canvas["vertical-align"] = value;
          // this.panel.options.canvas = canvas;
          // this.panel = this.panel;
          this.$set(this.panel.options, "canvas", canvas);
        }
      },
      get() {
        return (this?.panel?.options?.canvas || {})["vertical-align"] ?? "top";
      }
    },
    layout() {
      return (
        (this.$store.getters["dashboard/draft"] || {})?.template?.layout || []
      );
    },
    layoutColumnWidth() {
      let perc = 100;
      for (var r in this.layout) {
        for (var c in this.layout[r]) {
          for (var p in this.layout[r][c].panels) {
            if (this.layout[r][c].panels[p] == this.panel.name) {
              perc = this.layout[r][c].width;
              break;
            }
          }
        }
      }
      return perc;
    },
    minPanelHeight() {
      return parseFloat(
        ((this?.panel?.style || {})["min-height"] || "").replace(/px/, "") ||
          "200"
      );
    },
    styleTitle() {
      if (this.isDarkTheme) {
        return {
          'font-size': '10pt',
          'color': '#b8c7ce',
          'padding': '0 5px'
        }
      }
      return {
        'font-size': '10pt',
        'color': '#666',
        'padding': '0 5px'
      }
    }

  },
  watch: {
    imageSizeLocked(n) {
      if (n) {
        this.imageSize = {w: this.w, h: this.h};
      } else {
        this.$nextTick(() => {
          if (this.$refs.imageWidth) {
            this.$refs.imageWidth.focus();
          }
        });
      }
    }
  },
  methods: {
    setColor(color) {
      if (!this.ready) return;
      this.panel.options.canvas.style["background-color"] = color;
      if (this.panel.style) {
        this.panel.style["background-color"] = color;
      }
      this.panel = this.panel;
    },
    setImage(src) {
      //console.log("setImage");
      if (!this.ready) return;
      if (!src && this.getImage) {
        // removing
        this.$store.dispatch("synoptic/delControl", this.imageControl.id);
      } else {
        if (src) {
          let control = null;
          if (this.getImage) {
            if (this.getImage != src) {
              // replacing
              control = this.imageControl;
              control.synopticComponent.src = src;
              control.synopticComponent.style.border = "0px solid #afafaf";
              control.synopticComponent.clientRect.width = this.panel.options.canvas.style.width;
              control.synopticComponent.clientRect.height = this.panel.options.canvas.style.height;
              // this.$store.dispatch("synoptic/updateControl", {
              //   id: control.id,
              //   control: control
              // });
              this.updateBackgroundImageControl(control);
            }
          } else {
            // inserting
            Controls.forEach((item) => {
              if (
                !control &&
                item.template.synopticComponent.componentName == "SynopticImage"
              ) {
                if (parseInt(this.panel.options.canvas.style.height) < 400) {
                  this.panel.options.canvas.style.height = "400";
                }
                control = JSON.parse(JSON.stringify(item.template));
                control.index = -1;
                control.name = "Background";
                control.synopticComponent.src = src;
                control.synopticComponent.style.border = "0px solid #afafaf";
                control.synopticComponent.clientRect.width = this.panel.options.canvas.style.width;
                control.synopticComponent.clientRect.height = this.panel.options.canvas.style.height;
                this.$store.dispatch(
                  "synoptic/addControl",
                  JSON.parse(JSON.stringify(control))
                );
                this.$nextTick(() => {
                  this.$store.dispatch("synoptic/clearSelectedControls");
                });
              }
            });
          }
        }
      }
      this.panel.options.canvas.src = src;
      this.panel = this.panel;
    },
    onSynopticEvent($event) {
      switch ($event.action) {
        case "panel:size": {
          let size = {
            w: $event.details.w,
            h: $event.details.h,
            a: ""
          };
          size.a = size.w && size.h ? size.h / size.w : 1;
          this.$set(this, "panelSize", size);
          break;
        }
        case "panel:setTab": {
          var setTab =
            this.$parent?.setTab || this.$parent.$parent?.setTab || null;
          if (setTab) {
            setTab($event.details.tabName);
          }
          break;
        }
      }
    },
    onSwitchToControlsButton() {
      this.$root.$emit("dashboard:editor", {
        action: "editPanel",
        data: {panelName: this?.panel?.name, showContentProperties: true}
      });
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$root.$emit("synoptic:event", {
            action: "panel:setTab",
            details: {tabName: "synoptic-components"}
          });
        });
      });
    }
  },
  created() {
    this.$root.$on("synoptic:event", this.onSynopticEvent);
    this.updateBackgroundImageControl = debounce((control) => {
      this.$store.dispatch("synoptic/updateControl", {
        id: control.id,
        control: control
      });
      this.$root.$emit("panel:resized");
      this.$root.$emit("synoptic-editor:canvas");
    }, 100);
  },
  beforeDestroy() {
    this.$root.$off("synoptic:event", this.onSynopticEvent);
  }
};
</script>

<style scoped>
.property-wrapper {
  padding: 0.4rem;
  position: relative;
}

.me {
  margin-bottom: 10px;
}

.nav-tabs > li > a {
  font-weight: 300;
  padding: 0 5px;
}
</style>
<style>
.canvas-property {
  margin-bottom: 5px;
}

.canvas-property label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.03em;
}

.skin-dark .canvas-property label.text-primary {
  color: #b8c7ce;
}

.canvas-property input {
  height: 26px !important;
  padding: 0px 4px 0 10px !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  margin-top: -2px;
}

.canvas-property:not(#canvas-image) input {
  border-radius: 3px !important;
}

.canvas-property button {
  height: 26px !important;
  padding: 0px 7px !important;
  line-height: 1.5 !important;
  margin-top: -2px;
}

.input-url-error {
  color: #a94442;
}
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
</style>
