<template>
  <section class="me">
    <div>
      <div class="box" :class="isEditing ? 'box-warning' : 'box-primary'">
        <DashboardPanelHeader
          ref="panelHeader"
          :panel="panel"
          :mode="mode"
          :isEditing="isEditing"
          customBoxHeaderClasses="with-border"
          :customBoxTitleClasses="customBoxTitleClasses"
          @title:click="$emit('panelProperties')"
        >
          <template #toolbar><slot name="toolbar"></slot></template>
        </DashboardPanelHeader>

        <div class="box-body">
          <iframe
            v-if="isValidURL"
            class="custom-widget"
            :src="src"
            :style="iframeStyle"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DashboardPanelHeader from "@/components/dashboard-panel-header.vue";

export default {
  name: "EquipmentCustomPanel",
  components: {
    DashboardPanelHeader
  },
  props: {
    panel: {
      type: Object,
      required: false,
      default: () => null
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    isValidURL() {
      return /^(http|https):\/\/.[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
        this.src
      );
    },
    src() {
      //return "http://localhost:8080/static/common/pages/mqtt-sim.html?connector_id=3201&app_static_dir=app.dev.telemetria.webhi.com.br";
      return this?.panel?.options.src || "";
    },
    iframeStyle() {
      let style = {
        "overflow-x": (this?.panel?.style || {})["overflow-x"],
        "overflow-y": (this?.panel?.style || {})["overflow-y"]
      };
      return style;
    },
    customBoxTitleClasses() {
      let classes = '';
      if (this.isEditing) classes += ' clicable-title';
      return classes;
    },
    panelTitle() {
      return this.panel?.title || "";
    },
    toolbarStyle() {
      return this.panel?.toolbarStyle || {}
    },
    toolbarStyleFontSize() {
      return this.toolbarStyle['font-size'] || "";
    },
    boxHeaderTitlePadding() {
      if (this.panelTitle && !this.toolbarStyleFontSize) return "5px";
      return "10px";
    }
  }
};
</script>

<style scoped>
.me {
  width: 100%;
  margin: 0;
  min-height: inherit;
  position: relative;
}

.me > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.box {
  height: 100%;
}
.box > .box-header {
  padding: 5px;
  min-height: 30px;
}
.box > .box-body {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0 0 30px 0;
}

iframe.custom-widget {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
}
</style>
